import AppService from "../AppService/AppService";

export const QuestionListService = {
    sendSurveyStatus(userSurveyId, userSurveyStatus, surveyScore, disqualificationReasons, validateScore) {
        let disqualificationReasonsObject = {
            ...disqualificationReasons,
            DISQUALIFICATION_ANSWER_SELECTED: new Map()
        };
        if (disqualificationReasons.DISQUALIFICATION_ANSWER_SELECTED.size) {
            disqualificationReasonsObject.DISQUALIFICATION_ANSWER_SELECTED = Object.fromEntries(
                disqualificationReasons.DISQUALIFICATION_ANSWER_SELECTED
            );
        } else {
            delete disqualificationReasonsObject.DISQUALIFICATION_ANSWER_SELECTED;
        }
        return AppService.getAxios().put("/api/user/survey/status", {
            userSurveyId,
            status: AppService.userSurveyStatus,
            surveyScore,
            disqualificationReasons: disqualificationReasonsObject,
            validateScore: validateScore || false
        });
    },

    getEDProfile(userId, id) {
        return AppService.getAxios().get(`/api/user-with-answers/${userId}?reservedUserId=${id}`);
    },

    getDynamicGroups(userId) {
        return AppService.getAxios().get(`/api/survey/${userId}/dynamic-groups`);
    },

    getQuestionsWithRelation(userId, surveyId) {
        return AppService.getAxios().get(`/api/user-with-answers/${userId}/get-relation/${surveyId}`);
    }
};
