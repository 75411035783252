import React from "react";
import { AuthContext } from "../../AuthContext";
import { Redirect } from "react-router-dom";

export default class RedirectAuthComponent extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);

        this.state = {
            readyForRedirect: false,
            autoLogin: false,
            accountType: ""
        };
    }
    getCookie = (cname) => {
        const name = cname + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    componentDidMount() {
        this.context.saveToken(this.getCookie("surrat"));
        this.context
            .checkAuth()
            .then((result) => {
                if (result) {
                    this.setState({
                        accountType: result.role.toLowerCase(),
                        readyForRedirect: true,
                        autoLogin: true
                    });
                } else {
                    this.setState({
                        readyForRedirect: true,
                        autoLogin: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    readyForRedirect: true,
                    autoLogin: false
                });
            });
    }

    render() {
        const accountRedirectUrl = ["egg_donor", "parent"].includes(this.state.accountType) ? "/start" : "/survey";
        return this.state.readyForRedirect ? this.state.autoLogin ? <Redirect to={accountRedirectUrl} /> : <Redirect to="/login" /> : "";
    }
}
