import React from "react";
import { Redirect, withRouter } from "react-router-dom";

import Video from "@components/video/VideoComponent";
import RegistrationForm from "@components/registrationForm/RegistrationFormComponent";
import FormTitle from "@components/registrationFormTitle/RegistrationFormTitleComponent";
import ConfirmationPopup from "@components/registrationConfirmationPopup/RegistrationConfirmationPopupComponent";

import "./RegistrationComponent.scss";
import logo from "@resources/images/conceiveAbilities-logo.svg";
import AppService from "@service/AppService/AppService";
import Flyout from "@components/flyout/FlyoutComponent";
import imgSurrogate from "@resources/images/GC_sign_in_image.png";
import i18n from "i18next";
import LanguagePicker from "@components/languagePicker/LanguagePicker";
import { AuthContext } from "../../AuthContext";

class RegistrationComponent extends React.Component {
    static contextType = AuthContext;
    registerErrorMessage = "An error occurred while try to process your request";
    constructor(props) {
        super(props);
        this.state = { showConfirmationPopup: false, showError: false, showRegistrationError: false, showToggle: false };
        this.saveProfile = this.saveProfile.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.setShowToggle = this.setShowToggle.bind(this);
    }

    TITLES = {
        egg_donor: "Register as an egg donor",
        parent: "Register as a parent",
        surrogate: "Start A Surrogacy Journey!"
    };

    componentDidMount() {
        if (this.props.page === "surrogate") {
            AppService.changeLanguageHandler(AppService.cookies.get("i18next"), this.props.history);
        } else {
            //Disables multilanguage for non surrogate register pages.
            AppService.changeLanguageHandler("en", this.props.history);
        }
    }

    getTitle() {
        const title = this.TITLES[this.props.page];
        return this.props.page ? title : "please select";
    }

    async saveProfile(profile) {
        if (AppService.accountType.length === 0) {
            this.setState({ showError: "Please select profile type" });
            return;
        }

        this.setState({ showProgress: true });

        const { firstName, lastName } = profile;

        const user = {
            firstName: firstName,
            lastName: lastName,
            email: profile.email,
            password: profile.password,
            address: "",
            primaryPhoneNumber: "",
            role: AppService.accountType.toUpperCase()
        };

        try {
            const result = await this.context.signup(user);
            if (result) {
                let fn = null;
                switch (user.role) {
                    case "PARENT":
                        fn = AppService.sendIPRegistrationToPardot;
                        break;
                    case "SURROGATE":
                        fn = AppService.sendGCRegistrationToPardot;
                        break;
                    case "EGG_DONOR":
                        fn = AppService.sendEDRegistrationToPardot;
                        break;
                }
                if (fn) {
                    fn = fn.bind(AppService);
                    fn(firstName, lastName, profile.email);
                }
                this.props.history.push("/start");
            }
        } catch (error) {
            if (error.response?.status === 409) {
                this.setState({
                    showProgress: false,
                    showError: "This email address already exists. Sign-in or use another email address."
                });
            } else {
                this.setState({
                    showProgress: false,
                    showError: "An error occurred while try to process your request"
                });
            }
        }
    }

    setShowToggle(internationalized) {
        this.setState({ showToggle: internationalized });
        if (!internationalized) {
            AppService.changeLanguageHandler("en", this.props.history);
        }
    }

    render() {
        const title = this.getTitle();
        const page = this.props.page;
        const { showConfirmationPopup, email, navigateTo, user, showRegistrationError } = this.state;

        if (navigateTo) {
            return <Redirect to={`/survey/`} push={true} />;
        }

        const errorMessage = page === "surrogate" ? i18n.t("basic_error") : this.state.showError;

        AppService.accountType = page;
        return (
            <div className="row security">
                {this.state.showProgress ? <div className={`animated-gradient-${AppService.accountType}`} /> : null}
                {/*{this.state.showError ? <Flyout type="error" message={errorMessage} onClose={() => {}} /> : null}*/}
                <div className="column left-column">
                    <header className="left-column-header">
                        <img src={logo} className="app-logo" alt="ConceiveAbilities" />
                        {/* <img src={menu} className="app-menu" alt="Menu" /> */}
                        {AppService.accountType === "surrogate" && this.state.showToggle && AppService.lang !== "en" && (
                            <LanguagePicker history={this.props.history} />
                        )}
                    </header>
                    {page === "surrogate" ? (
                        <div className="surrogate-image-mobile">
                            <img src={imgSurrogate} alt="" />
                        </div>
                    ) : null}
                    <div className="registration-form">
                        <FormTitle
                            title={title}
                            titles={this.TITLES}
                            page={page}
                            titleDropDownClosed={() => this.setState({ showError: false })}
                        />
                        <RegistrationForm
                            onSubmit={this.saveProfile}
                            page={page}
                            showError={AppService.getValidationErrorText() !== "" || showRegistrationError}
                            errorMessage={
                                AppService.getValidationErrorText() !== "" || showRegistrationError
                                    ? AppService.getValidationErrorText()
                                    : ""
                            }
                            getShowToggle={this.setShowToggle}
                            showProgress={this.state.showProgress}
                        />
                    </div>
                </div>
                <div className="column right-column video-column">{!AppService.isMobile() ? <Video page={page} /> : null}</div>
                {showConfirmationPopup && (
                    <ConfirmationPopup
                        onDialogClose={() =>
                            this.setState({
                                showConfirmationPopup: false,
                                navigateTo: "/login"
                            })
                        }
                        title="Email confirmation"
                        navigateTo="start"
                        email={email}
                        user={user}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(RegistrationComponent);
