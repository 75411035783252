import React from "react";
import AppService from "@service/AppService/AppService";
import Hint from "@components/hint/HintComponent";
import { AuthContext } from "../../../../AuthContext";
import { v4 as uuidv4 } from "uuid";
import { getTranslatedTextIfNeeded } from "../../../../shared/InternationalizationTextUtils";

class UploadComponent extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.onFileChange = this.onFileChange.bind(this);
        this.setSelectedValue = this.setSelectedValue.bind(this);
        this.showFileDialog = this.showFileDialog.bind(this);
        this.state = { showProgress: false };
    }

    showFileDialog(i) {
        this.refs[`imageUpload${i}`].click();
    }

    onFileChange(e, a) {
        const { question } = this.props;
        const data = e.target.files;
        if (e.target.files[0] != null) {
            this.setState({ showProgress: true });
            const filename = this.generateFilename(data[0].name.split(".").pop());
            localStorage.setItem("uploadedImageName", data[0].name);
            this.uploadSingle(data[0], filename, true, () => this.createSingleUploadAnswer(filename, a, question.id));
        }
    }

    uploadSingle(file, filename, original, onComplete) {
        const userId = AppService.accountType === "admin" ? AppService.edId : this.context.user.id;
        AppService.getSingleUploadURL(filename, original, userId).then((resp) => {
            fetch(resp.data.uploadURL, {
                method: "PUT",
                body: file
            }).then((uploadResponse) => {
                if (uploadResponse.status === 200) {
                    onComplete && onComplete();
                }
            });
        });
    }
    createSingleUploadAnswer(filename, answer, questionId) {
        const { selectedTopic, question, onAnswer } = this.props;
        const res = AppService.uploadSinglePhoto(filename, answer.id, questionId);
        Promise.resolve(res)
            .then((result) => {
                if (result) {
                    this.setSelectedValue(result.data, answer.id);
                    onAnswer(selectedTopic, question, answer);
                }
            })
            .catch(() => {})
            .finally(() => {
                this.setState({ showProgress: false });
            });
    }
    generateFilename(fileType) {
        return uuidv4() + "." + fileType;
    }

    setSelectedValue(answer, answerId) {
        const answerIndex = this.props.question.answers.findIndex((a) => a.id === answerId);
        this.props.question.answers[answerIndex].userAnswer = answer;
    }

    render() {
        const { question, showOverlay } = this.props;

        const answers = question.answers.map((a, i) => {
            const userAnswerText = localStorage.getItem("uploadedImageName") || "";
            const answer = (
                <div key={i}>
                    <input
                        id="upload"
                        ref={`imageUpload${i}`}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            this.onFileChange(e, a);
                        }}
                        className="fileUploader"
                    />
                    <input
                        type="text"
                        placeholder={getTranslatedTextIfNeeded(a.text)}
                        onClick={() => this.showFileDialog(i)}
                        readOnly={true}
                        value={userAnswerText}
                        onChange={(event) => {}}
                    />
                </div>
            );
            return answer;
        });
        return (
            <div className={`survey-question ${this.props.className}`}>
                {this.props.readonly ? <div className="answer-overlay"></div> : null}
                {this.state.showProgress ? <div className={`animated-gradient-surrogate`}></div> : null}
                {showOverlay ? <div className="survey-overlay"></div> : null}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <div className="content">{answers}</div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)}></Hint>
            </div>
        );
    }
}

export default UploadComponent;
