import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import SecureImageComponent from "@components/secureImage/SecureImageComponent";
import { AuthContext } from "../../AuthContext";
import AppService from "../../service/AppService/AppService";
import EggDonorMark from "../EggDonorMark/EggDonorMark";

function Persons({ eggDonors, changeEggDonors }) {
    const { user, setUser } = useContext(AuthContext);
    const history = useHistory();

    const redirectToDonor = (integrationId) => {
        const fromPage = history.location.pathname;
        const formattedFromPage = fromPage.startsWith("/") ? fromPage.substring(1) : fromPage;
        const params = formattedFromPage === "donors" ? "" : `?from=${encodeURIComponent(formattedFromPage)}`;

        history.push(`/donor/${integrationId}${params}`);
    };

    const likePerson = (event, personID) => {
        event.stopPropagation();
        const lickedPerson = eggDonors.map((item) => {
            if (item.id === personID) {
                return { ...item, liked: !item.liked };
            }
            return { ...item };
        });
        changeEggDonors(lickedPerson);

        eggDonors.forEach(async (item) => {
            const body = {
                userId: user?.id,
                favoriteUserId: personID
            };
            if (item.id === personID) {
                if (!item.liked) {
                    await AppService.getAxios().post("/api/user/favorite", body);
                    setUser((prevUser) => ({
                        ...prevUser,
                        favoriteCount: prevUser.favoriteCount + 1
                    }));
                } else {
                    await AppService.getAxios().delete(`/api/user/favorite?userId=${body.userId}&favoriteUserId=${body.favoriteUserId}`);
                    setUser((prevUser) => ({
                        ...prevUser,
                        favoriteCount: prevUser.favoriteCount - 1
                    }));
                }
            }
        });
    };
    const title = {
        Primary_Race__c: "Race",
        GCSF_Primary_Race__c: "Race",
        Natural_Hair_Color__c: "Hair",
        Eye_Color__c: "Eyes",
        ee_education_level__c: "Education",
        Highest_Level_of_Education__c: "Education",
        Birthdate: "Age",
        Number_of_Prior_Donations__c: "Prior Donations",
        Location: "Location",
        Travel_Out_of_State__c: "Willing to travel"
    };

    const getFormattedValue = (key, answers) => {
        switch (key) {
            case "Birthdate":
                return calculateAge(answers[key]);
            case "Primary_Race__c":
                return proceedRaceAnswer(key, "Secondary_Race__c", answers);
            default:
                return answers[key];
        }
    };
    const replaceLocationAndPrior = (data) => {
        if (
            "ao_donor_location__c" in data &&
            "ao_donor_state__c" in data &&
            data.ao_donor_location__c.length > 0 &&
            data.ao_donor_state__c.length > 0
        ) {
            data.Location = `${data.ao_donor_location__c}, ${data.ao_donor_state__c}`;
        }
        if ("ao_donorcycles__c" in data && data.ao_donorcycles__c.length > 0) {
            data["Number_of_Prior_Donations__c"] = data.ao_donorcycles__c;
        }
        return data;
    };

    const calculateAge = (value) => {
        const dob = new Date(value);
        const now = new Date();

        let age = now.getFullYear() - dob.getFullYear();

        const hasBirthdayPassed = now.getMonth() > dob.getMonth() || (now.getMonth() === dob.getMonth() && now.getDate() >= dob.getDate());
        if (!hasBirthdayPassed) {
            age--;
        }

        return age;
    };

    const proceedRaceAnswer = (primaryKey, secondaryKey, answers) => {
        return answers[secondaryKey] && answers[secondaryKey] !== "Unknown"
            ? answers[primaryKey] + "/" + answers[secondaryKey]
            : answers[primaryKey];
    };
    const insertSoftHyphens = (text) => {
        if (typeof text === "string") {
            return text.replace(/\//g, "/\u200B");
        }
        return text;
    };
    return eggDonors.map((person, idx) => {
        const lickStyle = person.liked ? "like-donor liked" : "like-donor";
        const questionAnswers = { ...person.questionAnswers };
        replaceLocationAndPrior(questionAnswers);

        const buildInformationList = () => {
            const info = [];
            Object.keys(title).forEach((key) => {
                if (questionAnswers[key]) {
                    info.push(
                        <div key={key}>
                            {title[key]}: <span>{insertSoftHyphens(getFormattedValue(key, questionAnswers))}</span>
                        </div>
                    );
                }
                if (key === "Number_of_Prior_Donations__c" && !questionAnswers[key]) {
                    info.push(
                        <div key={key}>
                            {title[key]}: <span>0</span>
                        </div>
                    );
                }
            });
            return info;
        };

        const buildDonorMark = () => {
            const userCreatedDate = new Date(person.timestamp).getTime();
            const aWeekAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;

            const isNewDonor = userCreatedDate >= aWeekAgo;
            return (isNewDonor || person.reserved) && <EggDonorMark reserved={person.reserved} newDonor={isNewDonor} />;
        };

        return (
            <div className="person" key={`${idx}_ed`} onClick={() => redirectToDonor(person.integrationId)}>
                {buildDonorMark()}
                <div className="person-image">
                    <SecureImageComponent name={person.photo} alt="Donors photo" />
                    <div className={lickStyle} onClick={(e) => likePerson(e, person.id)} />
                </div>
                <ul className="person-information">
                    <div className="personal-number">{person.integrationId}</div>
                    {buildInformationList()}
                </ul>
            </div>
        );
    });
}

export default Persons;
