import AppService from "../../service/AppService/AppService";

export async function getSurveyData(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/survey/get/data`);
}

export async function saveAdminUserAnswer(edId, body) {
    return await AppService.getAxios().post(`/api/admin/egg-donor/${edId}/survey/answer/save`, body);
}
export async function getSectionQuestion(edId, surveyId, subSequence, sectionSequence) {
    return await AppService.getAxios().get(
        `/api/admin/egg-donor/${edId}/survey/${surveyId}/get/sub-survey/${subSequence}/section/${sectionSequence}`
    );
}
export async function removeAdminUserAnswer(surveyId, questionId, salesForceId) {
    const params = salesForceId ? `?sfdcFieldId=${salesForceId}` : "";
    return await AppService.getAxios().delete(
        `/api/admin/egg-donor/${AppService.edId}/survey/${surveyId}/question/${questionId}/answer/remove/${params}`
    );
}

export async function getAdminHiddenQuestions(edId, body) {
    return await AppService.getAxios().put(`api/admin/egg-donor/${edId}/survey/get/hidden`, body);
}

export async function removeHiddenUserAnswer(edId, surveyId, questionId) {
    return await AppService.getAxios().delete(
        `/api/admin/egg-donor/${edId}/survey/${surveyId}/question/${questionId}/answer/remove/hidden`
    );
}

export async function getDynamicGroupByAdmin(edId, dynamicQuestionId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/survey/dynamic/${dynamicQuestionId}`);
}
export async function saveAdminSinglePhoto(edId, body) {
    return await AppService.getAxios().post(`/api/admin/egg-donor/${edId}/survey/photo/save`, body);
}
export async function saveAdminGalleryPhoto(edId, body) {
    return await AppService.getAxios().post(`/api/admin/egg-donor/${edId}/survey/gallery/save`, body);
}
export async function removeAdminSinglePhoto(edId, surveyId, questionId) {
    return await AppService.getAxios().delete(`/api/admin/egg-donor/${edId}/survey/${surveyId}/question/${questionId}/photo/remove`);
}
export async function removeAdminGalleryPhoto(edId, surveyId, questionId, filename) {
    return await AppService.getAxios().delete(
        `/api/admin/egg-donor/${edId}/survey/${surveyId}/question/${questionId}/gallery/remove?filename=${filename}`
    );
}
export async function finishSurveyByAdmin(edId, surveyId) {
    return await AppService.getAxios().get(`api/admin/egg-donor/${edId}/survey/${surveyId}/finish`);
}
