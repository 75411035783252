import React, { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import EggDonorListComponent from "../../components/EggDonorListComponent/EggDonorListComponent";
import { getFavoriteDonors } from "../../requests/EggDonorListRequests/EggDonorListRequests";

function FavouriteDonors() {
    const { user } = useContext(AuthContext);

    const oneLoadCount = 12;
    const loadItems = (number, body, count = oneLoadCount) => getFavoriteDonors(number, user?.id, count, body);

    return <EggDonorListComponent loadItems={loadItems} oneLoadCount={oneLoadCount} onlyFavouriteDonors />;
}

export default FavouriteDonors;
