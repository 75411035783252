import React from "react";
import AppHeader from "@components/appHeader/AppHeaderComponent";
import "./AppLayoutComponent.scss";
import AppService from "@service/AppService/AppService";
import { withRouter } from "react-router-dom";
import { Events, scrollSpy } from "react-scroll";

class AppLayoutComponent extends React.Component {
    constructor(props) {
        super(props);
        this.centerColumn = React.createRef();
        this.mobileCenterColumn = React.createRef();
    }

    componentDidMount() {
        this.addScrollListener();
        Events.scrollEvent.register("begin", function (to, element) {
            // add code for debugging when needed
        });

        Events.scrollEvent.register("end", function (to, element) {
            // add code for debugging when needed
        });

        scrollSpy.update();
    }

    componentWillUnmount() {
        this.removeScrollListener();
        if (!this.props.location.pathname.includes("/donor")) {
            sessionStorage.removeItem("oneLoadCount");
            sessionStorage.removeItem("pageNumber");
            sessionStorage.removeItem("scrollPosition");
        }
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
    }

    addScrollListener = () => {
        const scrollElement = this.mobileCenterColumn.current || this.centerColumn.current;
        if (scrollElement) {
            scrollElement.addEventListener("scroll", this.handleScroll);
        }
    };

    removeScrollListener = () => {
        const scrollElement = this.mobileCenterColumn.current || this.centerColumn.current;
        if (scrollElement) {
            scrollElement.removeEventListener("scroll", this.handleScroll);
        }
    };

    handleScroll = () => {
        const pagesToSaveScroll = ["/donors", "/reserved", "/favorite"].includes(this.props.location.pathname);
        if (!pagesToSaveScroll) return;

        const scrollElement = this.mobileCenterColumn.current || this.centerColumn.current;
        if (scrollElement) {
            sessionStorage.setItem("scrollPosition", scrollElement.scrollTop.toString());
        }
        const savedScrollPosition = sessionStorage.getItem("scrollPosition");
        if (parseInt(savedScrollPosition) <= 200) {
            sessionStorage.removeItem("oneLoadCount");
            sessionStorage.removeItem("pageNumber");
        }
    };

    render() {
        if (this.props.isContentLoaded && this.mobileCenterColumn.current) {
            const savedScrollPosition = sessionStorage.getItem("scrollPosition");
            if (savedScrollPosition && this.mobileCenterColumn.current) {
                this.mobileCenterColumn.current.scrollTop = parseInt(savedScrollPosition, 10);
            }
        }
        if (AppService.sectionChanged && (this.centerColumn.current || this.mobileCenterColumn.current)) {
            this.centerColumn.current.scrollTo(0, 0);
            this.mobileCenterColumn.current.scrollTo(0, 0);
            AppService.sectionChanged = false;
        }

        const { left, right, center, className, showRightColumn, infiniteScroll, fullPage, isScrollBlocked } = this.props;
        const columnsClassName = "columns " + className;
        let mainClassName = infiniteScroll ? "content-container infinite" : "content-container";
        mainClassName = fullPage ? `${mainClassName} full-page` : mainClassName;
        const scrollBlockStyle = isScrollBlocked ? { touchAction: "none", overflow: "hidden" } : {};
        return (
            <div className="wrapper">
                <AppHeader />
                <section ref={this.mobileCenterColumn} id="mobile-survey-container" style={scrollBlockStyle}>
                    <div className={columnsClassName}>
                        <main id="survey-container" name="survey-container" className="center-column" ref={this.centerColumn}>
                            <div className={mainClassName}>
                                {center}
                                {showRightColumn ? <aside className="right-column">{right}</aside> : null}
                            </div>
                        </main>
                        {left ? <aside className="left-column">{left}</aside> : null}
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(AppLayoutComponent);
