import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import "./LoginComponent.scss";

import PasswordTextBox from "@components/passwordTextBox/PasswordTextBoxComponent";
import AccountSecurityLayout from "@components/accountSecurityLayout/AccountSecurityLayoutComponent";

import Constants from "@helper/Constants";
import i18n from "i18next";
import AppService from "@service/AppService/AppService";
import { AuthContext } from "../../AuthContext";
import { UserSurveyStatus } from "../../helper/Constants";

class LoginComponent extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            email: "",
            isFormValid: false,
            redirectToProfile: false,
            redirectToSurvey: false,
            showLoginError: false,
            showProgress: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        AppService.changeLanguageHandler("en", this.props.history);
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    handlePasswordChange = (password) => {
        this.setState({ password });
    };

    validateForm = () => {
        return this.isEmailValid() && this.isPasswordValid();
    };

    isEmailValid = () => {
        const { email } = this.state;
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            email
        );
    };

    isPasswordValid = () => {
        const { password } = this.state;
        return password.length > 0;
    };

    doLogin = () => {
        const { email, password } = this.state;
        if (this.validateForm()) {
            this.setState({ showProgress: true });
            const result = this.context.login(email, password);
            result
                .then((value) => {
                    if (value) {
                        if (this.context.isSurveyFinished()) {
                            this.setState({
                                redirectToProfile: true,
                                showProgress: false
                            });
                        }
                        this.setState({
                            redirectToSurvey: true,
                            showProgress: false
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        showLoginError: true,
                        showProgress: false
                    });
                });
        } else {
            this.setState({ showLoginError: true, showProgress: false });
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.doLogin();
    };

    render() {
        const { email, redirectToProfile, redirectToSurvey, showLoginError } = this.state;
        const isFormValid = this.validateForm();

        const isEmailValid = email.length > 0 && !this.isEmailValid();
        const emailFieldClassName = isEmailValid ? "field-error" : "";

        if (redirectToProfile) {
            switch (AppService.userSurveyStatus) {
                case UserSurveyStatus.COMPLETED:
                case UserSurveyStatus.ACTIVE_LIVE:
                case UserSurveyStatus.MATCHED:
                    return <Redirect to="/accepted" push={true} />;
                case UserSurveyStatus.REJECTED:
                case UserSurveyStatus.INITIAL_REJECTED:
                    return <Redirect to="/home" push={true} />;
                default:
                    return <Redirect to={"/start"} push={true} />;
            }
        }

        if (redirectToSurvey) {
            return <Redirect to={"/survey"} push={true} />;
        }

        const path = this.props.history.location.pathname;
        const loginPath = "/login";

        return (
            <AccountSecurityLayout>
                {this.state.showProgress ? <div className="animated-gradient" /> : null}
                <h1>{i18n.t("Welcome back!")}</h1>
                <p className="security-form-description" />
                <form onSubmit={this.handleFormSubmit} className="form">
                    <div className="form-item">
                        <label htmlFor="lblEmail">{i18n.t("Email")}</label>
                        <input
                            type="text"
                            id="lblEmail"
                            name="email"
                            className={emailFieldClassName}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        {isEmailValid && <label className="form-error">Email address not found</label>}
                    </div>

                    <PasswordTextBox title={i18n.t("Password")} id="lblPassword" onPasswordChange={this.handlePasswordChange} />

                    {showLoginError && (
                        <div className="login-error">
                            {i18n.t("login_warning_text")} <a href="/app/reset">{i18n.t("login_warning_link")}</a>?
                        </div>
                    )}

                    {isFormValid ? (
                        <input type="submit" value={i18n.t("Login")} className="button login-button" />
                    ) : (
                        <input type="submit" value={i18n.t("Login")} className="button login-button disabled" />
                    )}

                    <div className="forgot-password">
                        <a className="link" href="/app/reset">
                            {i18n.t("Forgot your password?")}
                        </a>
                    </div>
                    <div className="registration-help">
                        <p>{i18n.t("Do you need a new account ?")}</p>
                    </div>
                    <div className="login-links">
                        {/* After transition to React links must be updated*/}
                        <a className="link" href={path === loginPath ? Constants.SIGN_UP_URLS.SURROGATE : "/app/surrogates/register"}>
                            {i18n.t("Surrogate")}
                        </a>
                        <a className="link" href={path === loginPath ? Constants.SIGN_UP_URLS.PARENT : "/app/parents/register"}>
                            {i18n.t("Parent")}
                        </a>
                        <a className="link" href={path === loginPath ? Constants.SIGN_UP_URLS.EGG_DONOR : "/app/egg-donors/register"}>
                            Egg Donor
                        </a>
                    </div>
                </form>
            </AccountSecurityLayout>
        );
    }
}

export default withRouter(LoginComponent);
