import React, { useEffect, useState } from "react";
import "./Tabs.scss";

function Tabs({ children, className }) {
    const [activeTab, setActiveTab] = useState("");
    const name = className ? className : "";

    useEffect(() => {
        const initialTabLabel = children[0].props.label.split("(")[0].trim();
        setActiveTab(initialTabLabel);
    }, []);

    const onClickTabItem = (tab) => {
        const tabLabel = tab.split("(")[0].trim();
        setActiveTab(tabLabel);
    };
    const validChildren = React.Children.toArray(children).filter(Boolean);

    return (
        <div className={`tabs ${name}`}>
            <ul className="tab-list">
                {validChildren.map((child) => {
                    const { label } = child.props;
                    const labelName = label.split("(")[0].trim();
                    return (
                        <li
                            className={activeTab === labelName ? "tab-list-item active" : "tab-list-item"}
                            key={label}
                            onClick={() => onClickTabItem(label)}
                        >
                            {label}
                        </li>
                    );
                })}
            </ul>
            <div className="tab-content">
                {validChildren.map((child) => {
                    if (child.props.label.split("(")[0].trim() !== activeTab) {
                        return undefined;
                    }
                    return child.props.children;
                })}
            </div>
        </div>
    );
}

export default Tabs;
