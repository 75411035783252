import React from "react";
import Layout from "@components/appLayout/AppLayoutComponent";
import FullProfileInformation from "./FullProfileInformation";

function FullProfile() {
    return (
        <Layout
            left={null}
            center={<FullProfileInformation />}
            right={null}
            showRightColumn={false}
            showLeftColumn={false}
            fullPage={true}
        />
    );
}

export default FullProfile;
