import React, { useContext } from "react";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { AuthContext } from "../../../../AuthContext";

function NoteComponent({ question, showOverlay }) {
    const { user } = useContext(AuthContext);
    const substituteVariables = (str) => {
        let content = str;
        for (let key in user) {
            const regex = new RegExp(`{${key}}`, "gi");
            content = content.replace(regex, user[key]);
        }
        return content;
    };
    return (
        <div className="survey-question">
            {showOverlay && <div className="survey-overlay" />}
            <div className="question-text">{getTranslatedTextIfNeeded(substituteVariables(question.text))}</div>
            <div className="question-answers note-text">
                <div className="content">{getTranslatedTextIfNeeded(substituteVariables(question.noteContent))}</div>
            </div>
        </div>
    );
}

export default NoteComponent;
