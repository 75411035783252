import React, { useState, useEffect } from "react";
import "./HintComponent.scss";
import i18n from "i18next";
import AppService from "../../service/AppService/AppService";
import ModalPopupComponent from "../modalPopup/ModalPopupComponent";

function HintComponent({ hint }) {
    const [showHint, setShowHint] = useState(false);

    const hideHintOnScroll = () => {
        setShowHint(false);
    };

    const wrapLinksInAnchorTags = (hint) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const newText = hint.replace(linkRegex, '<a class="hint-link" href="$1" target="_blank">$1</a>');
        return newText;
    };

    useEffect(() => {
        const containerId = AppService.isMobile() ? "mobile-survey-container" : "survey-container";
        const container = document.getElementById(containerId);

        if (container) {
            container.addEventListener("scroll", hideHintOnScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", hideHintOnScroll);
            }
        };
    }, []);

    const toggleHintVisibility = (event, isVisible) => {
        if (event) {
            event.stopPropagation();
        }
        setShowHint(isVisible);
    };

    const isMobile = AppService.isMobile();
    const shouldShowHint = showHint && hint && hint.length > 0;

    return (
        <div
            className="help-text"
            onMouseEnter={(e) => toggleHintVisibility(e, true)}
            onMouseLeave={(e) => toggleHintVisibility(e, isMobile)}
            onClick={(e) => toggleHintVisibility(e, true)}
        >
            {hint && hint.length > 0 && (
                <>
                    <span>{i18n.t("Why is this question here")}</span>
                    {shouldShowHint &&
                        (isMobile ? (
                            <ModalPopupComponent onDialogClose={(e) => toggleHintVisibility(e, false)}>
                                <p dangerouslySetInnerHTML={{ __html: wrapLinksInAnchorTags(hint) }} />
                            </ModalPopupComponent>
                        ) : (
                            <div className="hint" dangerouslySetInnerHTML={{ __html: wrapLinksInAnchorTags(hint) }} />
                        ))}
                </>
            )}
        </div>
    );
}

export default HintComponent;
