import React, { useEffect, useRef, useState } from "react";
import "./MultiSelectDropDownComponent.scss";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { Multiselect } from "multiselect-react-dropdown";

function MultiSelectDropDownComponent({
    onChange,
    items,
    label,
    selected,
    placeholder,
    showArrow,
    toggleSidebar,
    displayValue,
    selectionLimit
}) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [allOptions, setAllOptions] = useState(items);
    const ref = useRef(null);

    useEffect(() => {
        if (selected?.length <= 0) {
            ref.current.resetSelectedValues();
            onChange([]);
        }
    }, [toggleSidebar]);

    useEffect(() => {
        setAllOptions(items);
        if (selected) {
            const selectedItems = selected.map((item) => ({ [displayValue ? displayValue : "text"]: item }));
            setSelectedOptions(selectedItems);
        }
    }, [selected, items]);

    const onSelectItem = (selectedList, selectedItem) => {
        if (selectionLimit === 1) {
            setSelectedOptions([selectedItem]);
            onChange([selectedItem]);
        } else {
            setSelectedOptions(selectedList);
            onChange(selectedList);
        }
    };

    const onRemoveItem = (selectedList, removedItem) => {
        if (selectionLimit === 1) {
            setSelectedOptions([]);
            onChange([]);
        } else {
            setSelectedOptions(selectedList);
            onChange(selectedList);
        }
    };

    return (
        <>
            <div className="multiselect-dropdown-container">
                <div className="multiselect">
                    <label>{getTranslatedTextIfNeeded(label)}</label>
                    <Multiselect
                        ref={ref}
                        placeholder={placeholder}
                        showArrow={showArrow}
                        displayValue={displayValue || "text"}
                        options={allOptions}
                        selectedValues={selectedOptions}
                        onSelect={onSelectItem}
                        onRemove={onRemoveItem}
                        avoidHighlightFirstOption={true}
                        showCheckbox
                    />
                </div>
            </div>
        </>
    );
}

export default MultiSelectDropDownComponent;
