import React from "react";
import Hint from "@components/hint/HintComponent";
import NumberAnswerComponent from "./answer/NumberAnswerComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedback } from "@shared/Feedback";

const NumberQuestionComponent = ({ selectedTopic, question, onAnswer, className, readonly, showOverlay }) => {
    const buildAnswerInput = (answer, index) => {
        return (
            <NumberAnswerComponent
                key={`masked-${index}-${answer.text}`}
                answer={answer}
                index={index}
                selectedTopic={selectedTopic}
                question={question}
                onAnswer={onAnswer}
            />
        );
    };

    const feedbackItems = [];
    const answers = question.answers.map((a, i) => {
        const feedback = getFeedback(a);
        if (feedback) {
            feedback.forEach((f) => feedbackItems.push(f));
        }
        return buildAnswerInput(a, i);
    });

    return (
        <div>
            <div className={`survey-question ${className}`}>
                {readonly ? <div className="answer-overlay"></div> : null}
                {showOverlay ? <div className="survey-overlay"></div> : null}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <div className="content">{answers}</div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)}></Hint>
            </div>
            {feedbackItems}
        </div>
    );
};

export default NumberQuestionComponent;
