import React, { useContext, useEffect, useState } from "react";
import "./MultiSelectComponent.scss";
import AppService from "@service/AppService/AppService";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { Multiselect } from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../AuthContext";
import { surveyQuestionMethods } from "../../../shared/Questions/Questions";

function MultiSelectComponent({ required, label, answers, onChange, question, isDynamicGroup }) {
    const authContext = useContext(AuthContext);

    const [answerList, setAnswerList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const { t } = useTranslation();

    const answersField = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "lastAnswersSelected" : "lastAnswerIds";
    const answerIdOrText = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "text" : "id";

    useEffect(() => {
        const answerList = [];
        const selectedItems = [];

        question.answers.forEach((answer) => {
            if (question[answersField]?.includes(answer[answerIdOrText])) {
                selectedItems.push(answer);
            }
            answerList.push(answer);
        });
        setAnswerList(answerList);
        setSelectedItems(selectedItems);
    }, [answers]);

    const onSelectItem = (items, item) => {
        if (onChange) {
            const currentItem = typeof item === "number" ? selectedItems[selectedItems.length - 1] : item;
            ["EGG_DONOR", "PARENT", "ADMIN"].includes(authContext?.user.role) || isDynamicGroup ? onChange(currentItem) : onChange(items);
        }
        setSelectedItems(items);
    };

    const labelText = required ? (
        <label className="drop-label">
            {getTranslatedTextIfNeeded(label)}
            <span className="required">*</span>{" "}
        </label>
    ) : (
        <label className="drop-label">{getTranslatedTextIfNeeded(label)}</label>
    );

    let checkboxColor = "";
    if (AppService.accountType === "parent") {
        checkboxColor = "parent-checked-brown";
    } else if (AppService.accountType === "egg_donor" || AppService.accountType === "admin") {
        checkboxColor = "parent-checked-green";
    } else if (AppService.accountType === "surrogate") {
        checkboxColor = "parent-checked-red";
    }

    const translatedSelectedItems = structuredClone(selectedItems);
    translatedSelectedItems.forEach((s) => (s.text = getTranslatedTextIfNeeded(s.text)));

    const translatedAnswerList = structuredClone(answerList);
    translatedAnswerList.forEach((tA) => (tA.text = getTranslatedTextIfNeeded(tA.text)));
    return (
        <>
            {labelText}
            <Multiselect
                className={checkboxColor}
                placeholder={t("Select")}
                displayValue="text"
                onSelect={(items, item) => onSelectItem(items, item)}
                onRemove={(items, item) => onSelectItem(items, item)}
                showArrow={true}
                showCheckbox={true}
                options={translatedAnswerList}
                selectedValues={translatedSelectedItems}
            />
        </>
    );
}

export default MultiSelectComponent;
