import React, { useContext, useEffect, useState } from "react";
import { isArray, isEqual } from "lodash/lang";

import Layout from "@components/appLayout/AppLayoutComponent";
import SurveyNavigation from "@components/surveyNavigation/SurveyNavigationComponent";
import SurveyQuestions from "@components/surveyQuestions/SurveyQuestionsComponent";
import CareTeam from "@components/careTeam/CareTeamComponent";
import AppService from "@service/AppService/AppService";

import "./SurveyComponent.scss";
import { useHistory, withRouter } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import { cloneDeep } from "lodash";
import NewSurveyComponent from "../newSurvey/NewSurveyComponent";

function SurveyComponent() {
    const history = useHistory();

    const authContext = useContext(AuthContext);
    const questionContext = useContext(SurveyQuestionsContext);

    const [questions, setQuestions] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState({ questions: [] });
    const [topicIndex, setTopicIndex] = useState(0);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const { user } = authContext;
        const { questions: ctxQuestions, isSurveyLoad, setSurveyScroll } = questionContext;

        if (authContext.isSurveyFinished() && AppService.accountType === "surrogate" && questionContext.allSubSurveySections) {
            questionContext.updateQuestions(questionContext.allSubSurveySections[0]);
        }

        if (user) {
            initDefaultData(user);
        }
        isSurveyLoad(false);
        setSurveyScroll(false);

        if (user) {
            initLanguagesAndLocations(user);
        }

        if (isArray(ctxQuestions)) {
            setSurveyQuestions(questionContext);
        }
    }, []);

    useEffect(() => {
        const { questions: ctxQuestions } = questionContext;
        if (isArray(ctxQuestions) && !isEqual(ctxQuestions, questions)) {
            setSurveyQuestions(questionContext);
        }
    }, [questionContext, questions]);

    useEffect(() => {
        if (!authContext.isSurveyFinished() && selectedTopic && questionContext.subSurveys) {
            AppService.parsedQuestions = questionContext.subSurveys[questionContext.currentSubSurvey].sections;
        }
    }, [selectedTopic]);

    const saveSurveyLocation = async () => {
        const response = await questionContext.saveQuestionOrResetLocation();
        if (response?.data) {
            authContext.setUser(response.data);
        }
    };

    const initLanguagesAndLocations = (profile) => {
        if (profile.role === "SURROGATE") {
            AppService.changeLanguageHandler(AppService.cookies.get("i18next"), history);
        } else {
            //Disables multilanguage for non surrogate survey.
            AppService.changeLanguageHandler("en", history);
        }
    };

    const initDefaultData = (profile) => {
        let fn = null;
        switch (profile.role) {
            case "PARENT":
                fn = AppService.sendStartIPAppToPardot;
                break;
            case "SURROGATE":
                fn = AppService.sendStartGCAppToPardot;
                break;
            case "EGG_DONOR":
                fn = AppService.sendStartEGAppToPardot;
                break;
        }
        if (fn) {
            fn = fn.bind(AppService);
            fn(profile.firstName, profile.lastName, profile.email);
        }

        setProfile(profile);
    };

    const setSurveyQuestions = (context) => {
        setQuestions(context.questions);
        setSelectedTopic(context.questions[topicIndex || context.lastSectionPassed]);
        history.push("/survey");
    };

    const changeTopic = (topic, index) => {
        AppService.selectedTopic = topic;
        AppService.sectionChanged = true;
        setSelectedTopic(topic);
        if (authContext.isSurveyFinished()) {
            setTopicIndex(index);
        }
    };

    const goToNextSection = (topic) => {
        const topicIndex = getTopicIndex(topic);
        if (topicIndex < questions.length) {
            const nextSection = {
                ...questions[topicIndex + 1],
                questions: questionContext.subSurveys[questionContext.currentSubSurvey].sections[topicIndex + 1].questions
            };
            if (nextSection) {
                AppService.sectionChanged = true;
                setSelectedTopic(nextSection);
            }
            if (questionContext.lastSectionPassed === topicIndex) {
                questionContext.updateSurveyLocation(questionContext.currentSubSurvey, topicIndex + 1);
            }
        }
    };

    const getTopicIndex = (topic) => {
        let result = -1;
        questions.forEach((t, i) => {
            if (t.id === topic.id || topic.sequenceId === t.id) {
                result = i;
            }
        });
        return result;
    };

    const changeSurveyAndSection = (item, sectionIndex, surveyIndex) => {
        if (authContext.isSurveyFinished() || questionContext?.lastSectionPassed >= sectionIndex) {
            changeTopic(item, sectionIndex);
            questionContext.changeSubSurvey(surveyIndex);
        }
    };

    const careTeamInfo = {
        egg_donor: { email: "eggdonor@conceiveabilities.com", phone: "312.379.5798" },
        surrogate: { email: "surrogate@conceviebabilities.com", phone: "312.379.5796" },
        parent: { email: "intendedparent@conceiveabilities.com", phone: "312.379.5797" }
    };

    const careTeam = careTeamInfo[profile?.type || ""];

    const saveAnswer = async (topic, question, answer) => {
        const topicIndex = getTopicIndex(topic);
        surveyQuestionMethods.setLastAnswerId(question, answer);
        questionContext.subSurveys[questionContext.currentSubSurvey].sections[topicIndex].questions = selectedTopic.questions;
        AppService.parsedQuestions = questionContext.subSurveys[questionContext.currentSubSurvey].sections;
        setSelectedTopic(cloneDeep(selectedTopic));
        await surveyQuestionMethods.setAnswer(topic, question, answer, questionContext.lastSectionPassed);
        await saveSurveyLocation();
    };

    if (surveyQuestionMethods.isEdOrIpAccountType()) {
        return <NewSurveyComponent />;
    }
    const selected = selectedTopic || questions[questionContext.lastSectionPassed] || AppService.selectedTopic;

    return (
        <Layout
            left={
                <SurveyNavigation
                    selectedTopic={selected}
                    subSurveys={questionContext.subSurveys}
                    changeSurveyAndSection={changeSurveyAndSection}
                />
            }
            center={
                <SurveyQuestions
                    selectedTopic={selected}
                    onAnswer={saveAnswer}
                    onChangeSection={goToNextSection}
                    isLastTopic={getTopicIndex(selected) === questions.length - 1}
                />
            }
            showRightColumn={true}
            right={<CareTeam careTeam={careTeam} />}
            className="survey-progress"
        />
    );
}

export default withRouter(SurveyComponent);
