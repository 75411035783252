import React from "react";
import AvatarEditor from "react-avatar-editor";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";

function ImageCrop({ imageSrc, onCancel, setImageEditor, onSaveImage, showLoader }) {
    return (
        <div>
            <div className="single-preview">
                <div className="avatar-container">
                    {showLoader && (
                        <div className="loading-spinner">
                            <Spinner />
                        </div>
                    )}
                    <AvatarEditor
                        ref={(editor) => setImageEditor(editor)}
                        image={imageSrc}
                        width={333}
                        height={333}
                        className="avatar-editor"
                    />
                </div>
            </div>
            <div className="single-buttons">
                <button className="btn-cancel" onClick={onCancel}>
                    Cancel
                </button>
                {imageSrc && !imageSrc.includes("conab-app-data.s3") && (
                    <button className="btn-save" onClick={onSaveImage}>
                        Save
                    </button>
                )}
            </div>
        </div>
    );
}

export default ImageCrop;
