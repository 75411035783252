import React, { useState, useEffect, useCallback, useContext } from "react";
import "./NumberAnswerComponent.scss";
import AppService from "@service/AppService/AppService";
import { IMaskInput } from "react-imask";
import { NumberAnswerService } from "@service/NumberAnswerService/NumberAnswerService";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import i18n from "i18next";
import i18next from "i18next";
import { SurveyQuestionsContext } from "../../../../../SurveyQuestionsStore";

function NumberAnswerComponent({ answer, selectedTopic, question, onAnswer, index }) {
    const { dynamicQuestions } = useContext(SurveyQuestionsContext);
    const [showError, setShowError] = useState(false);
    const [inputValue, setInputValue] = useState(answer.userAnswer || "");
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (question?.dynamicGroupTarget && dynamicQuestions.length) {
            const targetDynamicGroup = dynamicQuestions.find((dQ) => dQ.sequenceId === question.dynamicGroupTarget);
            if (inputValue && targetDynamicGroup?.createdInstances.length > 1 && targetDynamicGroup.createdInstances.length !== 15) {
                setInputValue(String(targetDynamicGroup.createdInstances.length));
            }
        }
    }, [dynamicQuestions]);

    useEffect(() => {
        const isAnswerNotEmpty = answer.userAnswer && answer.userAnswer.length > 0;
        setShowError(isAnswerNotEmpty && !NumberAnswerService.isAnswerValid(answer));
    }, [answer]);

    useEffect(() => {
        updateErrorState();
    }, [answer.userAnswer]);

    useEffect(() => {
        const isAnswerNotEmpty = answer.userAnswer && answer.userAnswer.length > 0;
        const shouldShowError = isAnswerNotEmpty && !NumberAnswerService.isAnswerValid(answer);
        setShowError(shouldShowError);

        const timer = setTimeout(() => {
            if (isMounted && inputValue !== answer.userAnswer) {
                answer.userAnswer = inputValue;
                onAnswer(selectedTopic, question, answer);
                AppService.updateProgress = true;
            }
        }, 200);

        setIsMounted(true);
        return () => clearTimeout(timer);
    }, [inputValue]);

    const handleInputChange = (value) => {
        const trimmedValue = value.toString().trim();
        if (trimmedValue !== "+" && trimmedValue !== "-") {
            setInputValue(trimmedValue);
        }
    };

    const updateErrorState = useCallback(() => {
        const isAnswerNotEmpty = answer.userAnswer && answer.userAnswer.length > 0;
        setShowError(isAnswerNotEmpty && !NumberAnswerService.isAnswerValid(answer));
    }, [answer]);

    const getOutOfRangeMessage = () => {
        if (answer.range) {
            const { upperBoundary, lowerBoundary } = answer.range;
            const max = upperBoundary ? (upperBoundary.inclusive ? upperBoundary.value : parseInt(upperBoundary.value) - 1) : 0;
            const min = lowerBoundary ? (lowerBoundary.inclusive ? lowerBoundary.value : parseInt(lowerBoundary.value) + 1) : 0;

            let buildMessage = "";
            if (max && min) {
                buildMessage = `${i18next.t("from")} ${min} ${i18n.t("to")} ${max}`;
            } else if (max && !min) {
                buildMessage = `${i18n.t("to")} ${max}`;
            } else if (!max && min) {
                buildMessage = `${i18next.t("from")} ${min}`;
            }
            return `${i18n.t("Allowed values are")} ${buildMessage}`;
        }
    };
    return (
        <div className="number-answer">
            <IMaskInput
                className={showError ? "error-input" : undefined}
                id={`masked-${index}`}
                lazy={false}
                inputRef={(el) => el && (el.type = "text")}
                value={inputValue}
                mask={Number}
                signed={true}
                placeholder={getTranslatedTextIfNeeded(answer.text)}
                scale={0}
                thousandsSeparator={""}
                padFractionalZeros={false}
                onAccept={(value) => handleInputChange(value)}
            />
            {showError && <div className="error-message">{getOutOfRangeMessage()}</div>}
        </div>
    );
}

export default NumberAnswerComponent;
