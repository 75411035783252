import React, { useState } from "react";
import "./NoteItem.scss";

function NoteItem({ note, onDelete, toggleEdit, onUpdateNote }) {
    const [noteInput, setNoteInput] = useState(note.message || "");

    const onEdit = (noteId, boolean) => {
        setNoteInput(note.message || "");
        toggleEdit(noteId, boolean);
    };
    const handleSave = () => {
        onUpdateNote(note.id, noteInput);
        onEdit(note.id, false);
    };

    const time = note.timeValue === 0 ? note.timeUnit : `${note.timeValue} ${note.timeUnit} ago`;

    return (
        <div className="note-item">
            <div className="note-header">
                <div className="author-fullname">{note.authorFullName}</div>
                <div className="note-time">{time}</div>
            </div>
            {note.edit ? (
                <form className="create-note-container" onSubmit={handleSave}>
                    <textarea
                        className="note-input"
                        placeholder="Comment"
                        value={noteInput}
                        onChange={(e) => setNoteInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSave();
                            }
                        }}
                    />
                    <button className="cancel-note-edit" onClick={() => onEdit(note.id, false)}>
                        Cancel
                    </button>
                    <button type="submit" className="create-note-btn">
                        Save
                    </button>
                </form>
            ) : (
                <div className="note-message">{note.message}</div>
            )}
            {note.canBeEdited && !note.edit && (
                <div className="edit-note-container">
                    <span onClick={() => onEdit(note.id, true)}>Edit </span>
                    <span onClick={() => onDelete(note.id)}>Delete</span>
                </div>
            )}
        </div>
    );
}

export default NoteItem;
