import React from "react";
import "@resources/scss/App.scss";
import Routes from "./Routes";
import { withTranslation } from "react-i18next";

function App() {
    return (
        <>
            <Routes />
            <div id="notification-box" />
        </>
    );
}

export default withTranslation()(App);
