import AppService from "../AppService/AppService";
import { useHistory } from "react-router-dom";

export const SalesforceFieldService = {
    specialSfdcFieldNames: null,

    retrieveSpecialSfdcFieldNames() {
        return AppService.getAxios()
            .get("/api/sales-force-field/special-field-names")
            .then((response) => {
                this.specialSfdcFieldNames = response.data;
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.href = "/app/login";
                }
            });
    }
};
