import React from "react";
import "./UnansweredHintComponent.scss";
import { useTranslation } from "react-i18next";

function UnansweredHintComponent({ accountType }) {
    const { t } = useTranslation();
    return (
        <div className="unanswered-warning">
            <span className={`warning warning-${accountType}`} />
            <p className="unanswered-warning-text">{t("answer_warning_text")}</p>
        </div>
    );
}

export default UnansweredHintComponent;
