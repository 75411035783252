import React from "react";
import SecureImageComponent from "../../../../../components/secureImage/SecureImageComponent";

function PhotosModalContent({ log }) {
    const { type, newValue } = log;

    const renderPhotos = () => {
        if (!newValue) {
            return <p>All uploaded photos removed</p>;
        }
        switch (type) {
            case "single":
                return <SecureImageComponent name={newValue} />;
            case "gallery": {
                const parsedNewValue = newValue && JSON.parse(newValue);
                if (Object.keys(parsedNewValue).length <= 0) {
                    return <p>All uploaded photos removed</p>;
                }
                return (
                    <ul className="list-gallery">
                        {Object.entries(parsedNewValue).map(([url, name]) => (
                            <li>
                                <img src={url} alt={name} />
                                <p>{name}</p>
                            </li>
                        ))}
                    </ul>
                );
            }
        }
    };

    return <div className="modalContainer">{renderPhotos()}</div>;
}

export default PhotosModalContent;
