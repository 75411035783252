import React from "react";
import Back from "@resources/images/back.svg";
import { withRouter, useHistory } from "react-router-dom";
import "./BackButtonComponent.scss";
import { useTranslation } from "react-i18next";

function BackButtonComponent({ backUrl, onClick }) {
    const history = useHistory();
    const { t } = useTranslation();

    const redirect = () => {
        if (backUrl) {
            history.push("/start/");
            onClick();
        } else {
            history.goBack();
        }
    };

    return (
        <div className="back-button" onClick={redirect}>
            <img src={Back} alt="" /> {t("Back")}
        </div>
    );
}

export default withRouter(BackButtonComponent);
