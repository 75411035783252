import React, { useContext, useEffect } from "react";
import DropDown from "@components/dropDowns/dropDown/DropDownComponent";
import Hint from "@components/hint/HintComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { useTranslation } from "react-i18next";
import { getFeedbackWithoutIndex } from "@shared/Feedback";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";

function Select({ selectedTopic, question, onAnswer, showOverlay, className, readonly, isDynamicGroup }) {
    const { dynamicQuestions, isSurveyLoaded } = useContext(SurveyQuestionsContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (question?.dynamicGroupTarget && dynamicQuestions.length && isSurveyLoaded) {
            const targetDynamicGroup = dynamicQuestions.find((dQ) => dQ.sequenceId === question.dynamicGroupTarget);
            if (targetDynamicGroup?.createdInstances.length > 0 && targetDynamicGroup.createdInstances.length !== 15) {
                const answerWithRelatedValue = question.answers.find(
                    (a) => parseInt(a.text) === targetDynamicGroup.createdInstances.length
                );
                if (answerWithRelatedValue && parseInt(selected?.text) !== 0 && answerWithRelatedValue.text !== selected?.text) {
                    onChange(answerWithRelatedValue);
                }
            }
        }
    }, [dynamicQuestions]);

    const onChange = (answer) => {
        onAnswer(selectedTopic, question, answer);
    };

    const answersField = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "lastAnswersSelected" : "lastAnswerIds";
    const answerIdOrText = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "text" : "id";
    let selected = null;
    question[answersField]?.forEach((id) => {
        selected = question.answers.find((a) => a[answerIdOrText] === id);
    });

    let feedbackItems = [];

    if (question.answers) {
        question.answers.forEach((a) => {
            feedbackItems = getFeedbackWithoutIndex(question, a, feedbackItems);
        });
    }

    return (
        <div>
            <div className={`survey-question ${className}`}>
                {readonly ? <div className="answer-overlay" /> : null}
                {showOverlay ? <div className="survey-overlay" /> : null}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <div className="content">
                        <DropDown
                            items={question.answers}
                            selectedItem={selected}
                            placeholder={t("Select")}
                            onChange={(answer) => {
                                getFeedbackWithoutIndex(question, answer, feedbackItems);
                                onChange(answer);
                            }}
                        />
                    </div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
}

export default Select;
