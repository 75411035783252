import React from "react";
import { PopupButton } from "@typeform/embed-react";
import "./LiteSurveyFinishMessage.scss";

const LiteSurveyFinishMessage = () => {
    return (
        <div className="start-banner-lite">
            <div className="message-wrapper">
                <div className="text-wrapper">
                    <h1>
                        Thank you for contacting <br /> ConceiveAbilities.
                    </h1>
                    We know that surrogacy can be an exciting time, and you likely have a lot of questions as you begin your journey. At
                    ConceiveAbilities, we are committed to helping you know everything you want to know about surrogacy.
                    <br />
                    <PopupButton id="ZqG2G7db" className="popup-button" size={70} medium="snippet">
                        Learn More
                    </PopupButton>
                </div>
            </div>
        </div>
    );
};

export default LiteSurveyFinishMessage;
