import React from "react";
import { unansweredQuestions } from "../../../shared/AnswersTracker/AnswersTracker";
import SurveyHeaderComponent from "../../../components/surveyHeader/SurveyHeaderComponent";
import QuestionElements from "../../../components/questionsList/QuestionElements/QuestionElements";
import UnansweredNavigationComponent from "../../../components/surveyQuestions/unasnweredNavigation/UnansweredNavigationComponent";
import AppService from "../../../service/AppService/AppService";
import SectionButton from "../../../components/questionsList/SectionButton/SectionButton";
import { UserSurveyStatus } from "../../../helper/Constants";
import { surveyQuestionMethods } from "../../../shared/Questions/Questions";
import { useHistory } from "react-router-dom";
import { finishSurvey } from "../../../requests/surveyAPIRequests/surveyAPIRequests";

const NewQuestionList = ({
    selectedTopic,
    isGroup,
    surveyLocation,
    surveyData,
    changeSurveyAndSection,
    onSaveAnswer,
    setShowUnansweredWarning,
    showUnansweredWarning,
    onFinishSurvey,
    isLoading,
    setIsLoading
}) => {
    const history = useHistory();
    const groupClass = isGroup ? "group-question-container" : "";

    const isLastTopic =
        selectedTopic?.id ===
        surveyData?.subSurveys[surveyLocation?.subSurveyIndex]?.sections[
            surveyData?.subSurveys[surveyLocation?.subSurveyIndex]?.sections.length - 1
        ]?.id;

    const onNextButtonClick = async () => {
        setIsLoading(true);
        const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(selectedTopic.id);
        if (firstUnansweredQuestion) {
            surveyQuestionMethods.scrollSurveyToBottom();
            setShowUnansweredWarning(true);
            setIsLoading(false);
            return;
        }
        if (AppService.isAppDisqualifiedBySectionType()) {
            await onFinishSurvey();
            setIsLoading(false);
            return;
        }
        changeSurveyAndSection(null, surveyLocation.sectionIndex + 1, surveyLocation.subSurveyIndex);
        setShowUnansweredWarning(false);
        setIsLoading(false);
    };

    const onFinishButtonClick = async () => {
        if (unansweredQuestions.isAllQuestionsAnswered()) {
            setIsLoading(true);
            await changeSubSurveyOrFinishSurvey();
            setShowUnansweredWarning(false);
            setIsLoading(false);
        } else {
            surveyQuestionMethods.scrollSurveyToBottom();
            if (
                AppService.userSurveyStatus !== UserSurveyStatus.REJECTED ||
                AppService.userSurveyStatus !== UserSurveyStatus.INITIAL_REJECTED
            ) {
                setShowUnansweredWarning(true);
            }
        }
    };

    const changeSubSurveyOrFinishSurvey = async () => {
        const isLastSubSurvey = surveyLocation.subSurveyIndex === surveyData.subSurveys.length - 1;
        const isNextSubSurveyIfNotDisqualified = surveyData.subSurveys[surveyLocation.subSurveyIndex + 1]?.show === "If not disqualified";
        if (isLastSubSurvey || AppService.isAppDisqualifiedBySectionType()) {
            await onFinishSurvey();
        } else {
            if (isNextSubSurveyIfNotDisqualified) {
                const response = await finishSurvey(AppService.surveyId, false, surveyLocation.subSurveyIndex, surveyLocation.sectionIndex);
                if (response.data.status.includes(UserSurveyStatus.INITIAL_REJECTED, UserSurveyStatus.REJECTED)) {
                    onFinishSurvey(response);
                } else {
                    changeSurveyAndSection(null, 0, surveyLocation.subSurveyIndex + 1);
                }
            } else {
                changeSurveyAndSection(null, 0, surveyLocation.subSurveyIndex + 1);
            }
        }
    };

    const showUnansweredQuestionsString = showUnansweredWarning && selectedTopic && !isGroup;

    return (
        <div className="survey-questions">
            {selectedTopic && (
                <SurveyHeaderComponent
                    className="progress-survey-header"
                    selectedTopic={selectedTopic}
                    backRedirect={() => history.push("/start")}
                    isLoading={isLoading}
                />
            )}
            <div className={`${groupClass} survey-questions-container survey-questions-container-${AppService.accountType}`}>
                <QuestionElements
                    questions={selectedTopic ? selectedTopic.questions : []}
                    selectedTopic={selectedTopic}
                    onAnswer={onSaveAnswer}
                    showUnansweredWarning={showUnansweredWarning}
                    updateProgress={() => {}}
                    isDynamicGroup={false}
                    dynamicQuestion={null}
                    instanceId={null}
                />
                {showUnansweredQuestionsString && (
                    <UnansweredNavigationComponent
                        sectionId={selectedTopic.id}
                        accountType={AppService.accountType}
                        isLoading={isLoading}
                    />
                )}
                <SectionButton
                    isLastTopic={isLastTopic}
                    isGroup={isGroup}
                    selectedTopic={selectedTopic}
                    onNextButtonClick={onNextButtonClick}
                    onFinishButtonClick={onFinishButtonClick}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default NewQuestionList;
