import React from "react";
import { useTranslation } from "react-i18next";

function LiteButtons({
    allSubSurveySections,
    finishButtonDisable,
    selectedSection,
    selectedSectionIndex,
    onFinishButtonClick,
    onNextButtonClick
}) {
    const { t } = useTranslation();

    return (
        <div className="right-part">
            {allSubSurveySections.length === selectedSectionIndex + 1 ? (
                <>
                    <span
                        className={finishButtonDisable ? "finished disabled" : "finished"}
                        onClick={() => {
                            onFinishButtonClick();
                        }}
                    >
                        Get started
                    </span>
                </>
            ) : (
                <span
                    className="next"
                    onClick={() => {
                        onNextButtonClick(selectedSection);
                    }}
                >
                    {t("Next")}
                </span>
            )}
        </div>
    );
}

export default LiteButtons;
