import React from "react";
import "./SurveyProfileFilters.scss";
import closeParent from "@resources/images/close-parent.svg";

function SurveyProfileFilters({ filterParams, removeFilter, isFiltersApplied }) {
    return (
        filterParams &&
        isFiltersApplied && (
            <div className="filter-params">
                {Object.entries(filterParams)
                    .filter(([key, values]) => Array.isArray(values) && values.length > 0)
                    .map(([key, values]) =>
                        values.map((value, index) => (
                            <div className="filter-item" key={`${key}-${index}`}>
                                <div className="filter-name">{value}</div>
                                <img
                                    src={closeParent}
                                    alt={`remove ${value}`}
                                    className="close-parent"
                                    onClick={() => removeFilter(key, value)}
                                />
                            </div>
                        ))
                    )}
                {filterParams["ao_willing_travel__c"]?.length > 0 && (
                    <div className="filter-item">
                        <div className="filter-name">{`Willing to Travel: ${filterParams["ao_willing_travel__c"]}`}</div>
                        <img
                            src={closeParent}
                            alt=""
                            className="close-parent"
                            onClick={() => removeFilter("ao_willing_travel__c", filterParams["ao_willing_travel__c"])}
                        />
                    </div>
                )}
                {filterParams.city?.length > 0 && (
                    <div className="filter-item">
                        <div className="filter-name">{`City: ${filterParams.city}`}</div>
                        <img src={closeParent} alt="" className="close-parent" onClick={() => removeFilter("city", filterParams.city)} />
                    </div>
                )}
                {filterParams.donorId?.length > 0 && (
                    <div className="filter-item">
                        <div className="filter-name">{`ID: ${filterParams.donorId}`}</div>
                        <img
                            src={closeParent}
                            alt=""
                            className="close-parent"
                            onClick={() => removeFilter("donorId", filterParams.donorId)}
                        />
                    </div>
                )}
            </div>
        )
    );
}

export default SurveyProfileFilters;
