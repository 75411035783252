import React, { useContext, useEffect, useState } from "react";
import AppLayoutComponent from "../../components/appLayout/AppLayoutComponent";
import CareTeamComponent from "../../components/careTeam/CareTeamComponent";
import {
    getSurveyDataForUser,
    getUserSection,
    getSurveyLocation,
    loadHiddenQuestions,
    finishSurvey,
    resetSurveyLocation
} from "../../requests/surveyAPIRequests/surveyAPIRequests";
import { SalesforceFieldService } from "../../service/salesforceField/SalesforceFieldService";
import AppService from "../../service/AppService/AppService";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import NewQuestionList from "./newQuestionList/NewQuestionList";
import { unansweredQuestions } from "../../shared/AnswersTracker/AnswersTracker";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";
import SurveyNavigationComponent from "../../components/surveyNavigation/SurveyNavigationComponent";
import { cloneDeep } from "lodash";
import { AuthContext } from "../../AuthContext";
import { useHistory } from "react-router-dom";

function NewSurveyComponent() {
    const authContext = useContext(AuthContext);
    const questionContext = useContext(SurveyQuestionsContext);

    const [surveyLocation, setSurveyLocation] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [showUnansweredWarning, setShowUnansweredWarning] = useState(false);
    const [cachedQuestions, setCachedQuestions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [activeRequests, setActiveRequests] = useState(0);
    const [requestsQueue, setRequestsQueue] = useState([]);
    const [isRequestProcessing, setIsRequestProcessing] = useState(false);

    const history = useHistory();

    useEffect(() => {
        SalesforceFieldService.retrieveSpecialSfdcFieldNames().then(fetchSurveyLocation).then(fetchSurveyData);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        surveyData && surveyLocation && fetchQuestions();
        AppService.sectionChanged = true;
    }, [surveyData, surveyLocation?.sectionIndex, surveyLocation?.subSurveyIndex]);

    useEffect(() => {
        if (selectedTopic) {
            AppService.parsedQuestions[0] = selectedTopic;
            AppService.allQuestions = [selectedTopic];
        }
    }, [selectedTopic]);

    useEffect(() => {
        const { isSurveyLoad, setSurveyScroll } = questionContext;
        if (selectedTopic && surveyData && surveyLocation) {
            isSurveyLoad(false);
            setSurveyScroll(false);
        }
        return () => {
            isSurveyLoad(false);
            setSurveyScroll(false);
        };
    }, []);

    useEffect(() => {
        if (requestsQueue.length > 0 && !isRequestProcessing) {
            setIsRequestProcessing(true);
            const currentRequest = requestsQueue[0];
            currentRequest().finally(() => {
                setRequestsQueue((prevQueue) => prevQueue.slice(1));
                setIsRequestProcessing(false);
                setActiveRequests((prevCount) => prevCount - 1);
            });
        }
    }, [requestsQueue, isRequestProcessing]);

    useEffect(() => {
        if (selectedTopic && unansweredQuestions.questions[selectedTopic?.id]?.size > 0) {
            saveLocation(questionContext.lastSectionPassed);
        }
    }, [unansweredQuestions.questions[selectedTopic?.id]?.size]);

    useEffect(() => {
        if (questionContext?.lastSectionPassed > surveyLocation?.sectionIndex) {
            saveLocation(surveyLocation.sectionIndex);
        }
    }, [isRequestProcessing]);

    const saveLocation = async (sectionIndex) => {
        const response = await questionContext.saveQuestionOrResetLocation({
            ...surveyLocation,
            sectionIndex,
            selectedTopic
        });
        response?.data &&
            setSurveyLocation({
                ...surveyLocation,
                questionIndex: response.data.questionIndex
            });
    };

    const enqueueRequest = (requestFunction) => {
        setActiveRequests((prevCount) => prevCount + 1);
        setRequestsQueue((prevQueue) => [...prevQueue, requestFunction]);
    };

    const fetchSurveyLocation = async () => {
        const response = await getSurveyLocation();
        const locationResp = response.data;
        const startLocation = {
            sectionIndex: 0,
            subSurveyIndex: 0,
            questionIndex: 0
        };
        updateSurveyLocation(locationResp.indexesActive ? locationResp : startLocation);
    };

    const fetchSurveyData = async () => {
        const response = await getSurveyDataForUser();
        const surveyNavData = response.data;
        AppService.surveyId = surveyNavData.surveyId;
        setSurveyData(surveyNavData);
    };
    const fetchQuestions = async () => {
        const { subSurveyIndex, sectionIndex } = surveyLocation;
        const subSequence = surveyData.subSurveys.find((sub) => sub.index === subSurveyIndex).id;
        const sectionSequence = surveyData.subSurveys[subSurveyIndex].sections.find((section) => section.index === sectionIndex).id;

        const cacheKey = `${subSequence}-${sectionSequence}`;

        if (cachedQuestions[cacheKey]) {
            setSelectedTopic(cachedQuestions[cacheKey]);
        } else {
            setSelectedTopic(null);
            const selectedTopicResp = await getUserSection(surveyData.surveyId, subSequence, sectionSequence);
            setCachedQuestions((prev) => ({ ...prev, [cacheKey]: selectedTopicResp.data }));
            setSelectedTopic(selectedTopicResp.data);
        }
    };

    const updateSurveyLocation = ({ subSurveyIndex = 0, sectionIndex = 0, questionIndex = 0 }) => {
        setSurveyLocation({ questionIndex, subSurveyIndex, sectionIndex });
        const lastSectionPassed =
            sectionIndex <= questionContext.lastSectionPassed && subSurveyIndex === questionContext.currentSubSurvey
                ? questionContext.lastSectionPassed
                : sectionIndex;
        questionContext.updateSurveyLocation(subSurveyIndex, lastSectionPassed, questionIndex);
    };
    const changeSurveyAndSection = async (_, sectionIndex, subSurveyIndex, questionIndex) => {
        if (!selectedTopic) return;
        const firstUnansweredQuestion = selectedTopic && unansweredQuestions.getFirstUnansweredQuestion(selectedTopic.id);
        if ((!firstUnansweredQuestion && sectionIndex <= questionContext.lastSectionPassed) || authContext.isSurveyFinished()) {
            updateSurveyLocation({
                ...surveyLocation,
                sectionIndex,
                subSurveyIndex,
                questionIndex
            });
            return;
        }
        if (subSurveyIndex === surveyLocation.subSurveyIndex && firstUnansweredQuestion && sectionIndex > surveyLocation.sectionIndex) {
            setShowUnansweredWarning(true);
            return;
        }
        if (sectionIndex > surveyLocation.sectionIndex + 1) {
            return;
        }
        if (
            AppService.isAppDisqualifiedBySectionType() &&
            !authContext.isSurveyFinished() &&
            sectionIndex > questionContext.lastSectionPassed
        ) {
            await onFinishSurvey();
            return;
        }
        updateSurveyLocation({
            ...surveyLocation,
            sectionIndex,
            subSurveyIndex,
            questionIndex
        });
        setShowUnansweredWarning(false);
    };

    const onSaveAnswer = async (topic, question, answer) => {
        const originalSelectedTopic = cloneDeep(selectedTopic);
        try {
            surveyQuestionMethods.setLastAnswerId(question, answer);
            if (question.type === "DYNAMIC_GROUP" || question.type === "DATE") {
                enqueueRequest(() => surveyQuestionMethods.setAnswer(topic, question, answer));
                return;
            }
            let updatedSelectedTopic = { ...selectedTopic };
            const questionIndex = updatedSelectedTopic.questions.findIndex((q) => q.id === question.id);

            if (questionIndex !== -1) {
                updatedSelectedTopic.questions[questionIndex] = { ...question };
            }

            setSelectedTopic(updatedSelectedTopic);
            if (surveyQuestionMethods.shouldSkipAddressQuestion(question, answer)) {
                return;
            }
            if (answer.hasVisibility && surveyQuestionMethods.isQuestionAnswered(question)) {
                const subSurvey = surveyData.subSurveys.find((sub) => sub.index === surveyLocation.subSurveyIndex);
                const body = {
                    answerSequence: answer.sequenceId,
                    groupSequence: question?.groupId,
                    sectionSequence: selectedTopic.id,
                    subSequence: subSurvey?.id,
                    surveyId: AppService.surveyId
                };

                const response = await loadHiddenQuestions(body);
                surveyQuestionMethods.addHiddenQuestions(response.data, updatedSelectedTopic);
            }
            surveyQuestionMethods.filterQuestionsIfNeeded(question, updatedSelectedTopic);

            setSelectedTopic(cloneDeep(updatedSelectedTopic));

            if (questionIndex !== -1) {
                enqueueRequest(() => surveyQuestionMethods.setAnswer(topic, question, answer));
            } else if (question.groupId) {
                enqueueRequest(() => surveyQuestionMethods.setAnswer(topic, question, answer));
            } else {
                console.error("Question not found");
            }
            const subSurvey = surveyData.subSurveys.find((sub) => sub.index === surveyLocation.subSurveyIndex);
            const sectionSequence = surveyData.subSurveys[subSurvey.index].sections.find(
                (section) => section.index === surveyLocation.sectionIndex
            ).id;
            const cacheKey = `${subSurvey.id}-${sectionSequence}`;

            setCachedQuestions((prevCache) => ({
                ...prevCache,
                [cacheKey]: updatedSelectedTopic
            }));
        } catch (e) {
            console.log(e);
            surveyQuestionMethods.showNotificationWithError(e);
            setSelectedTopic(originalSelectedTopic);
        }
    };

    const onFinishSurvey = async (resp) => {
        const response = resp
            ? resp
            : await finishSurvey(AppService.surveyId, true, surveyLocation.subSurveyIndex, surveyLocation.sectionIndex);
        AppService.userSurveyStatus = response.data.status;
        const savedUser = await resetSurveyLocation();
        authContext.setUser(savedUser.data);
        authContext.setPortalStatus(response.data.portalStatus);
        history.push("/start");
    };

    const careTeamInfo = {
        egg_donor: { email: "eggdonor@conceiveabilities.com", phone: "312.379.5798" },
        parent: { email: "intendedparent@conceiveabilities.com", phone: "312.379.5797" }
    };
    const careTeam = careTeamInfo[AppService.accountType];

    const isSurveyLoading = activeRequests > 0 || isLoading;

    return (
        <AppLayoutComponent
            left={
                <SurveyNavigationComponent
                    subSurveys={surveyData?.subSurveys}
                    selectedTopic={selectedTopic}
                    changeSurveyAndSection={changeSurveyAndSection}
                    isLoading={isLoading}
                />
            }
            center={
                <NewQuestionList
                    selectedTopic={selectedTopic}
                    surveyData={surveyData}
                    surveyLocation={surveyLocation}
                    onSaveAnswer={onSaveAnswer}
                    changeSurveyAndSection={changeSurveyAndSection}
                    onFinishSurvey={onFinishSurvey}
                    setShowUnansweredWarning={setShowUnansweredWarning}
                    showUnansweredWarning={showUnansweredWarning}
                    isLoading={isSurveyLoading}
                    setIsLoading={setIsLoading}
                />
            }
            showRightColumn={true}
            right={<CareTeamComponent careTeam={careTeam} />}
            className="survey-progress"
        />
    );
}

export default NewSurveyComponent;
