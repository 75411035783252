import { SalesforceFieldService } from "../salesforceField/SalesforceFieldService";
import AppService from "../AppService/AppService";

export const AgeDisqualificationService = {
    isSurveyDisqualifiedByAge(questions, ageDisqualificationRule) {
        if (!ageDisqualificationRule) {
            return false;
        }
        const age = this.tryCalculateAge(questions);
        if (age === null) {
            return false;
        }

        return (
            (ageDisqualificationRule.from && age < ageDisqualificationRule.from) ||
            (ageDisqualificationRule.to && age > ageDisqualificationRule.to)
        );
    },

    tryCalculateAge(questions) {
        const userBirthdate = this.findUserAnswerBySalesforceField(questions, SalesforceFieldService.specialSfdcFieldNames.birthday);
        if (userBirthdate) {
            return this.calculateAge(userBirthdate);
        }
        return null;
    },

    findUserAnswerBySalesforceField(questions, salesforceFieldName) {
        let age = questions.filter((q) => q.salesForceField?.fieldName === salesforceFieldName).map((q) => q.answers[0].userAnswer)[0];
        if (!age) {
            age = questions
                .flatMap((q) => {
                    if (q.type === "GROUP") {
                        return q.questions.flatMap((gQ) => gQ.answers);
                    }
                    return q.answers;
                })
                .filter((a) => a.salesForceField?.fieldName === salesforceFieldName)
                .map((a) => a.userAnswer)[0];
        }
        return age;
    },

    calculateAge(userBirthdate) {
        const today = new Date();
        const birthDate = new Date(userBirthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },

    calculateAgeScore(parsedQuestions, ageScore) {
        if (!ageScore || ageScore.length === 0) {
            return 0;
        }
        let age = null;
        parsedQuestions.forEach((section) => {
            if (this.tryCalculateAge(section.questions) !== null) {
                age = this.tryCalculateAge(section.questions);
            }
        });
        return AppService.calculateScore(ageScore, age);
    }
};
