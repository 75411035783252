export const formatDate = (dateArray) => {
    const [year, month, day, hour, minute, second] = dateArray;

    const date = new Date(year, month - 1, day, hour, minute, second);

    const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
        timeZone: "America/Chicago"
    });

    return formattedDate;
};

export const formatDateFromString = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
    }).format(date);

    return formattedDate;
};
export const formatPhoneNumber = (number) => {
    let numStr = number.toString();

    if (numStr.length !== 11) {
        return number;
    }

    let countryCode = numStr.substring(0, 1);
    let areaCode = numStr.substring(1, 4);
    let firstPart = numStr.substring(4, 7);
    let secondPart = numStr.substring(7, 11);

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
};
export const formatAddress = (
    address = {
        country: "",
        state: "",
        city: "",
        street: null,
        building: null,
        postalCode: "",
        fullAddress: ""
    }
) => {
    if (typeof address === "string") {
        address = JSON.parse(address);
    }
    let formattedAddress = "";

    if (address.street !== null && address.street !== undefined) {
        formattedAddress += address.street;
        if (address.building !== null && address.building !== undefined) {
            formattedAddress += " " + address.building;
        }
        formattedAddress += ", ";
    }

    if (address.city) {
        formattedAddress += address.city + ", ";
    }
    if (address.state) {
        formattedAddress += address.state + " ";
    }
    if (address.postalCode) {
        formattedAddress += address.postalCode + ", ";
    }

    if (address.country) {
        formattedAddress += address.country;
    }

    return formattedAddress;
};
