import { useEffect, useState } from "react";
import { getRelatedQuestion } from "../../../requests/AdminAPIRequests/adminAPIRequests";
import "./QuestionToolTip.scss";
function QuestionToolTip({ id, position }) {
    const [questionData, setQuestionData] = useState(null);

    useEffect(() => {
        if (id) {
            getRelatedQuestion(id).then((resp) => {
                setQuestionData(resp.data);
            });
        }
    }, [id]);

    return (
        questionData && (
            <div data-testid="tooltip" className="question-tooltip" style={{ top: position.top, left: position.left }}>
                <p data-testid="question-text">
                    <span>Question Text in Survey: </span>
                    {questionData.content}
                </p>
                <p data-testid="question-type">
                    <span>Question Type in Survey: </span>
                    {questionData.type}
                </p>
                <p data-testid="answers-count">
                    <span>Global Answers Count: </span>
                    {questionData.answersCount}
                </p>
            </div>
        )
    );
}

export default QuestionToolTip;
