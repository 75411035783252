import Constants, { States } from "@helper/Constants";
import axios from "axios";
import { unansweredQuestions } from "@shared/AnswersTracker/AnswersTracker";
import { NumberAnswerService } from "@service/NumberAnswerService/NumberAnswerService";
import { BmiDisqualificationService } from "../disqualification/BmiDisqualificationService";
import { AgeDisqualificationService } from "../disqualification/AgeDisqualificationService";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import Cookies from "universal-cookie";
import i18n from "i18next";
import i18next from "i18next";
import { ConditionDisqualificationService } from "../disqualification/ConditionDisqualificationService/ConditionDisqualificationService";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import {
    removeGalleryMultiplePhoto,
    removeSinglePhoto,
    removeHiddenAnswer,
    saveMultiplePhoto,
    saveSinglePhoto
} from "../../requests/surveyAPIRequests/surveyAPIRequests";
import {
    removeAdminGalleryPhoto,
    removeAdminSinglePhoto,
    saveAdminGalleryPhoto,
    saveAdminSinglePhoto
} from "../../requests/AdminSurveyAPIRequests/AdminSurveyAPIRequests";

const AppService = {
    accountType: null,
    surveyType: null,
    isAppDisqualified: false,
    isAppMuted: null,
    disqType: "",
    hiddenQuestions: [],
    hiddenDynamicQuestions: [],
    parsedQuestions: [],
    allQuestions: [],
    updateProgress: false,
    allowedStates: [],
    bmiDisqualificationRule: undefined,
    ageDisqualificationRule: undefined,
    edId: null,
    bmiScore: [],
    ageScore: [],
    surveyScore: 0,
    disqualificationReasons: {
        DISQUALIFICATION_ANSWER_SELECTED: new Map()
    },
    cookies: new Cookies(),
    showLanguageToggle: false,
    errorCode: 0,
    errorMessage: "",

    init: function () {
        this.isAppStarted = localStorage.getItem("isAppStarted");
        this.isUserJoined = localStorage.getItem("isUserJoined");
        this.selectedTopic = localStorage.getItem("selectedTopic");
    },

    save: function () {
        localStorage.setItem("isAppStarted", this.isAppStarted);
        localStorage.setItem("isUserJoined", this.isUserJoined);
        localStorage.setItem("selectedTopic", this.selectedTopic);
    },

    isAppStarted: null,
    isUserJoined: false,
    selectedTopic: null,
    surveyId: null,
    lang: "en",

    isMobile: () => {
        return window.innerWidth <= 1250;
    },
    isMobileSafari: () => {
        const userAgent = window.navigator.userAgent;
        const isApple = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
        const isWebkit = !!userAgent.match(/WebKit/i);
        return isApple && isWebkit;
    },

    isSafari: () => {
        const userAgent = navigator.userAgent;
        return /^((?!chrome|android).)*safari/i.test(userAgent);
    },

    Guid: function () {
        return `f${(+new Date()).toString(16)}`;
    },

    getAllHideQuestionIds: function (questions) {
        const allHiddenQuestions = [].concat.apply(
            [],
            questions.filter((q) => q.visibility && q.visibility.length > 0).map((q) => this.hideQuestionByVisibility(questions, q))
        );
        questions
            .filter((q) => q.conditions && q.conditions.length > 0 && q.conditions.find((condition) => condition.visibilities.length > 0))
            .forEach((q) => {
                if (!q.answers || q.answers.length === 0) {
                    return q.visibilities;
                }
                q.conditions.forEach((c) => {
                    if (c.type === "ANSWER" && q.answers.find((answer) => answer.userAnswer !== c.value)) {
                        c.visibilities.forEach((v) => allHiddenQuestions.push(v));
                    } else if (c.type === "SUM") {
                        // TODO: logic for condition with type "SUM" should be here.
                    }
                });
            });

        return allHiddenQuestions;
    },

    pushHiddenQuestions: function (question, hiddenQuestionIds) {
        if (question.type === "GROUP") {
            question.questions.forEach((question) => {
                hiddenQuestionIds.push(question.sequenceId);
            });
        }
        hiddenQuestionIds.push(question.sequenceId);
    },

    handleSourceQuestion: function (sourceQuestion, answer, question, hiddenQuestionIds) {
        const answerIsEmpty =
            answer !== undefined && sourceQuestion.type === "TEXT" && (answer.userAnswer === undefined || answer.userAnswer.trim() === "");

        if (!answer || answerIsEmpty || !sourceQuestion.lastAnswerIds.includes(answer.id)) {
            this.pushHiddenQuestions(question, hiddenQuestionIds);
        }
    },

    hideQuestionByVisibility: function (questions, question) {
        if (!question.visibility || question.visibility.length === 0) {
            return [];
        }

        const hiddenQuestionIds = [];

        const sectionMap = {};
        this.allQuestions.forEach((section) => {
            const questionMap = {};
            section.questions.forEach((q) => {
                questionMap[q.sequenceId] = q;
            });
            sectionMap[section.id] = questionMap;
        });

        let conditionsMet = 0;
        question.visibility.forEach((v) => {
            let sourceQuestion;
            if (question?.groupId) {
                sourceQuestion = sectionMap[v.section][question.groupId]?.questions?.find((q) => q.sequenceId === v.sourceQuestion);
            } else {
                sourceQuestion = sectionMap[v.section][v.sourceQuestion];
            }

            if (sourceQuestion && sourceQuestion.lastAnswerIds) {
                const answer = sourceQuestion.answers.find((a) => a.sequenceId === v.sequenceId);
                if (answer && sourceQuestion.lastAnswerIds.includes(answer.id)) {
                    conditionsMet++;
                }
            }
        });

        if (conditionsMet < 1) {
            if (question.type === "GROUP") {
                question.questions.forEach((q) => {
                    hiddenQuestionIds.push(q.sequenceId);
                });
            }
            hiddenQuestionIds.push(question.sequenceId);
        }

        const questionsByVisibility = {};
        questions.forEach((q) => {
            if (q.visibility) {
                q.visibility.forEach((v) => {
                    if (!questionsByVisibility[v.sourceQuestion]) {
                        questionsByVisibility[v.sourceQuestion] = [];
                    }
                    questionsByVisibility[v.sourceQuestion].push(q.sequenceId);
                });
            }
        });

        let chainHiddenIds = [...hiddenQuestionIds];
        while (chainHiddenIds.length > 0) {
            const nextChainHiddenIds = chainHiddenIds.reduce((acc, hiddenId) => {
                return acc.concat(questionsByVisibility[hiddenId] || []);
            }, []);
            chainHiddenIds = nextChainHiddenIds;
            hiddenQuestionIds.push(...chainHiddenIds);
        }

        return hiddenQuestionIds;
    },

    async getSingleUploadURL(filename, original, userId) {
        const url = original ? `/api/s3/generate/single/original/${userId}` : `/api/s3/generate/single/resized/${userId}`;
        return this.getAxiosWithRawBody().post(url, filename);
    },

    async getGalleryUploadURLs(filenames, userId) {
        return this.getAxios().post(`/api/s3/generate/gallery/${userId}`, { filenames: filenames });
    },

    async uploadSinglePhoto(filename, answerId, questionId, orderValue) {
        let result = false;
        const body = {
            scoreValue: 0,
            surveyId: this.surveyId,
            questionId: questionId,
            filename: filename,
            orderValue
        };
        try {
            result = surveyQuestionMethods.isEdOrIpAccountType()
                ? AppService.accountType === "admin"
                    ? await saveAdminSinglePhoto(this.edId, body)
                    : await saveSinglePhoto(body)
                : await this.getAxiosWithRawBody().post(
                      `/api/user/answer/upload/single?surveyId=${this.surveyId}&answerId=${answerId}&questionId=${questionId}`,
                      filename
                  );
        } catch (err) {
            result = false;
        }
        return result;
    },

    async removeSinglePhoto(answerId, questionId) {
        let result = false;
        try {
            result = surveyQuestionMethods.isEdOrIpAccountType()
                ? AppService.accountType === "admin"
                    ? await removeAdminSinglePhoto(this.edId, this.surveyId, questionId)
                    : await removeSinglePhoto(this.surveyId, questionId, answerId)
                : await this.getAxios().post(
                      `/api/user/answer/upload/single/remove?surveyId=${this.surveyId}&answerId=${answerId}&questionId=${questionId}`
                  );
        } catch (err) {
            result = false;
        }
        return result;
    },

    convertBase64ToBlob(base64Image) {
        const parts = base64Image.split(";base64,");
        const decodedData = window.atob(parts[1]);
        const uInt8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        return new Blob([uInt8Array]);
    },

    async uploadMultiplePhoto(filenames, answerId, questionId, orderValue) {
        let result = false;
        const body = {
            surveyId: this.surveyId,
            answerId: answerId,
            questionId: questionId,
            files: filenames
        };
        const ipEdBody = {
            surveyId: this.surveyId,
            questionId: questionId,
            scoreValue: 0,
            photos: filenames,
            orderValue
        };
        try {
            result = surveyQuestionMethods.isEdOrIpAccountType()
                ? AppService.accountType === "admin"
                    ? saveAdminGalleryPhoto(this.edId, ipEdBody)
                    : saveMultiplePhoto(ipEdBody)
                : await this.getAxiosWithRawBody().post(`/api/user/answer/gallery/create`, body);
        } catch (err) {
            result = false;
        }
        return result;
    },

    async removeMultiplePhoto(answerId, questionId, filename) {
        return surveyQuestionMethods.isEdOrIpAccountType()
            ? AppService.accountType === "admin"
                ? await removeAdminGalleryPhoto(this.edId, this.surveyId, questionId, filename)
                : await removeGalleryMultiplePhoto(this.surveyId, questionId, filename)
            : await this.getAxios().delete(
                  `/api/user/answer/files/remove?surveyId=${this.surveyId}&answerId=${answerId}&questionId=${questionId}&filename=${filename}`
              );
    },

    parseQuestions: function (json, subSurveyIndex = 0) {
        if (!json || !json.jsonStructure) {
            return null;
        }
        this.surveyId = json.id;
        this.userSurveyId = json.userSurveyId;
        this.userSurveyStatus = json.userSurveyStatus;
        const surveyData = JSON.parse(json.jsonStructure);

        if (surveyData.disqualificationRules) {
            this.retrieveDisqualificationRules(surveyData.disqualificationRules);
        }
        if (surveyData.bmiScore) {
            this.bmiScore = surveyData.bmiScore;
        }

        if (surveyData.ageScore) {
            this.ageScore = surveyData.ageScore;
        }

        const allSubSurveySections = surveyData.subSurveys.map((item) => {
            return [...item.sections];
        });

        const updatedSubSurveysSections = [];

        allSubSurveySections.forEach((subSurvey) => {
            const questions = [];

            subSurvey.forEach((section) => {
                const container = {
                    title: section.name,
                    id: section.sequenceId,
                    estimate: "",
                    questions: []
                };

                section.questions.forEach((question) => {
                    const questionItem = {};
                    Object.keys(question).forEach((k) => {
                        questionItem[k] = question[k];
                    });
                    container.questions.push(questionItem);
                });

                questions.push(container);
            });
            updatedSubSurveysSections.push(questions);
        });

        const subSurveys = surveyData.subSurveys;

        this.allQuestions = updatedSubSurveysSections.flatMap((sub) => [...sub]);
        this.parsedQuestions = updatedSubSurveysSections[subSurveyIndex];
        return { updatedSubSurveysSections, subSurveys };
    },

    retrieveDisqualificationRules(disqualificationRules) {
        if (disqualificationRules.states) {
            this.allowedStates = this.normalizeStates(disqualificationRules.states);
        }
        if (disqualificationRules.allowedBmi) {
            this.bmiDisqualificationRule = disqualificationRules.allowedBmi;
        }
        if (disqualificationRules.allowedAge) {
            this.ageDisqualificationRule = disqualificationRules.allowedAge;
        }
    },

    normalizeStates(disqualificationStates) {
        return disqualificationStates.map(function (state) {
            return States.find((s) => s.name.toLowerCase() === state.toLowerCase() || s.title.toLowerCase() === state.toLowerCase()).title;
        });
    },

    retrieveScore(surveyScore, score) {
        if (surveyScore.low.value && surveyScore.low.score) {
            score.low = {
                value: surveyScore.low.value,
                score: surveyScore.low.score
            };
        }

        if (surveyScore.high.value && surveyScore.high.score) {
            score.high = {
                value: surveyScore.high.value,
                score: surveyScore.high.score
            };
        }
    },

    findItem(array, itemToFind) {
        const keys = Object.keys(array);
        let isFound = false;
        keys.forEach((k) => {
            if (array[k].includes(itemToFind)) {
                isFound = true;
            }
        });
        return isFound;
    },

    getHiddenQuestions(question) {
        const result = new Set();
        if (question.visibility?.length > 0) {
            question.visibility.forEach((item) => {
                if (this.findItem(this.hiddenQuestions, item.targetQuestion)) {
                    result.add(item.targetQuestion);
                }
            });
        }
        return result.size;
    },
    getValidationErrorText() {
        const code = AppService.errorCode;
        const message = AppService.errorMessage;
        const allErrors = [];
        switch (code) {
            case 400:
                if (message.includes("{ First Name is not valid }")) {
                    allErrors.push(i18next.t("First Name"));
                }
                if (message.includes("{ Last Name is not valid }")) {
                    allErrors.push(i18next.t("Last Name"));
                }
                if (message.includes("{ Email address is not valid }")) {
                    allErrors.push(i18next.t("Email"));
                }
                break;
            case 409:
                return i18next.t("email_already_exist");
            case 404:
                return i18next.t("Wrong email format");
            case 500:
                return i18next.t("basic_error");
        }
        switch (allErrors.length) {
            case 1:
                return `${i18next.t("Wrong")} ${allErrors[0]} ${i18next.t("format")}`;
            case 2:
                return `${i18next.t("Wrong")} ${allErrors[0]} ${i18next.t("and")} ${allErrors[1]} ${i18next.t("format")}`;
            case 3:
                return `${i18next.t("Wrong")} ${allErrors[0]}, ${allErrors[1]} ${i18next.t("and")} ${allErrors[2]} ${i18next.t("format")}`;
            default:
                return "";
        }
    },
    isAllQuestionsAnswered() {
        if (!this.parsedQuestions) {
            return;
        }
        let answeredQuestionsCount = 0;
        let totalQuestionsCount = 0;
        let hiddenQuestionsCount = 0;

        this.parsedQuestions?.forEach((section) => {
            section.questions?.forEach((q) => {
                if (q.type === "NOTE" || q.type === "DYNAMIC_GROUP") {
                    return;
                }
                if (q.type === "GROUP") {
                    if (q.visibility.length > 0 && this.getHiddenQuestions(q) === 1) {
                        hiddenQuestionsCount += q.questions.length;
                    }
                    q.questions.forEach((q) => {
                        if (q.type === "NOTE") {
                            return;
                        }
                        totalQuestionsCount++;
                        hiddenQuestionsCount += this.getHiddenQuestions(q);
                        if (q.answers.length === 0) {
                            answeredQuestionsCount++;
                        }

                        if (q.type === "UPLOAD" || q.type === "MULTI_UPLOAD") {
                            answeredQuestionsCount++;
                        } else if (q.type === "TEXT") {
                            const answered = q.answers.find((a) => a.userAnswer && a.userAnswer.trim().length > 0);
                            if (answered) {
                                answeredQuestionsCount++;
                            }
                        } else if (q.type === "DATE" || q.type === "PHONE") {
                            if (this.isDateQuestionAnswered(q)) {
                                answeredQuestionsCount++;
                            }
                        } else {
                            if (q.lastAnswerIds && q.lastAnswerIds.length > 0) {
                                answeredQuestionsCount++;
                            }
                        }
                    });
                } else {
                    totalQuestionsCount++;
                    hiddenQuestionsCount += this.getHiddenQuestions(q);
                    if (q.answers?.length === 0) {
                        answeredQuestionsCount++;
                    }

                    if (q.optional) {
                        answeredQuestionsCount++;
                    }

                    if (q.type === "TEXT" || q.type === "UPLOAD" || q.type === "MULTI_UPLOAD") {
                        const answered = this.hasQuestionAnswer(q);
                        if (answered) {
                            answeredQuestionsCount++;
                        }
                    } else if (q.type === "PHONE") {
                        if (this.hasQuestionAnswer(q)) {
                            answeredQuestionsCount++;
                        }
                    } else if (q.type === "DATE") {
                        if (this.isDateQuestionAnswered(q)) {
                            answeredQuestionsCount++;
                        }
                    } else if (q.type === "NUMBER") {
                        if (NumberAnswerService.isAnswerValid(q.answers[0])) {
                            answeredQuestionsCount++;
                        }
                    } else {
                        if (q.lastAnswerIds && q.lastAnswerIds.length > 0) {
                            answeredQuestionsCount++;
                        }
                    }
                }
            });
        });
        return totalQuestionsCount - hiddenQuestionsCount <= answeredQuestionsCount;
    },

    hasQuestionAnswer(question) {
        return question.answers.find((answer) => answer.userAnswer && answer.userAnswer.length > 0 && answer.userAnswer.trim().length > 0);
    },

    isDateQuestionAnswered(question) {
        return !question.answers.find((a) => !a.userAnswer || a.userAnswer.trim().length === 0);
    },

    calculateZipScore(answerAccountableZipCode, answerPostalCode) {
        const zipCodes = answerAccountableZipCode.zipCodes;
        if (answerAccountableZipCode && answerPostalCode && zipCodes && zipCodes[0]["zipCode"]) {
            const zipCodeWithScoring = zipCodes.find((item) => item["zipCode"] === answerPostalCode);
            if (zipCodeWithScoring) {
                return parseInt(zipCodeWithScoring["score"]);
            }
        }
        return 0;
    },

    calculateSurveyScore(lastSectionPassedIndex = 0) {
        let surveyScore = 0;
        const sectionsWithoutDemo = this.accountType === "surrogate" ? this.parsedQuestions : this.allQuestions;
        sectionsWithoutDemo.slice(0, lastSectionPassedIndex + 1).forEach((section) => {
            section.questions.forEach((q) => {
                if (q.type === "GROUP") {
                    q.questions.forEach((gQ) => {
                        surveyScore += this.processQuestionScore(gQ);
                    });
                } else {
                    surveyScore += this.processQuestionScore(q);
                }
            });
        });

        surveyScore = surveyScore + BmiDisqualificationService.calculateBmiScore(this.parsedQuestions, this.bmiScore);
        surveyScore = surveyScore + AgeDisqualificationService.calculateAgeScore(this.parsedQuestions, this.ageScore);
        return surveyScore;
    },

    processQuestionScore(q) {
        let surveyScore = 0;
        if (q.answers.length === 0) {
            return surveyScore;
        }
        if (q.type === "TEXT") {
            if (q.answers[0].userAnswer && q.answers[0].userAnswer?.trim().length > 0) {
                const answerWeight = parseInt(q.answers[0].weight);
                if (Number.isInteger(answerWeight)) {
                    surveyScore += answerWeight;
                }
            } else if (q.noAnswerScore) {
                surveyScore += parseInt(q.noAnswerScore);
            }
        } else {
            if (q.lastAnswerIds.length > 0) {
                q.lastAnswerIds.forEach((answerId) => {
                    q.answers.forEach((a) => {
                        if (a.id === answerId) {
                            const answerWeight = Number.isInteger(a.weight) ? a.weight : parseInt(a.weight);
                            if (Number.isInteger(answerWeight)) {
                                surveyScore += answerWeight;
                            }
                            if (q.type === "ADDRESS" && q.salesForceField?.fieldName.toLowerCase().includes("mailingaddress")) {
                                surveyScore = surveyScore + this.calculateZipScore(a.accountableZipCode, a.addressDetails?.postalCode);
                            }
                        }
                    });
                });
            } else if (q.noAnswerScore) {
                surveyScore += parseInt(q.noAnswerScore);
            }
        }
        return surveyScore;
    },

    calculateScore(scoreItems, calculatedValue) {
        let scoreValue = 0;
        scoreItems.forEach((item) => {
            if (calculatedValue && item.valueFrom) {
                switch (item.operator) {
                    case "Between":
                        scoreValue = scoreValue + this.calculateBetween(item.valueTo, item.valueFrom, item.score, calculatedValue);
                        break;
                    case "\u003c":
                        scoreValue = scoreValue + this.calculateLessThan(item.valueFrom, item.score, calculatedValue);
                        break;
                    case "\u2264":
                        scoreValue = scoreValue + this.calculateLessThanOrEqualTo(item.valueFrom, item.score, calculatedValue);
                        break;
                    case "\u003e":
                        scoreValue = scoreValue + this.calculateGreaterThan(item.valueFrom, item.score, calculatedValue);
                        break;
                    case "\u2265":
                        scoreValue = scoreValue + this.calculateGreaterThanOrEqualTo(item.valueFrom, item.score, calculatedValue);
                        break;
                    case "\u003d":
                        scoreValue = scoreValue + this.calculateEqualTo(item.valueFrom, item.score, calculatedValue);
                        break;
                }
            }
        });
        return scoreValue;
    },

    calculateBetween(valueTo, valueFrom, score, calculatedValue) {
        if (valueTo && calculatedValue >= valueFrom && calculatedValue <= valueTo) {
            return Number.parseInt(score);
        }
        return 0;
    },

    calculateLessThan(valueFrom, score, calculatedValue) {
        if (parseInt(calculatedValue) < parseInt(valueFrom)) {
            return Number.parseInt(score);
        }
        return 0;
    },

    calculateLessThanOrEqualTo(valueFrom, score, calculatedValue) {
        if (parseInt(calculatedValue) <= parseInt(valueFrom)) {
            return Number.parseInt(score);
        }
        return 0;
    },

    calculateGreaterThan(valueFrom, score, calculatedValue) {
        if (parseInt(calculatedValue) > parseInt(valueFrom)) {
            return Number.parseInt(score);
        }
        return 0;
    },

    calculateGreaterThanOrEqualTo(valueFrom, score, calculatedValue) {
        if (parseInt(calculatedValue) >= parseInt(valueFrom)) {
            return Number.parseInt(score);
        }
        return 0;
    },

    calculateEqualTo(valueFrom, score, calculatedValue) {
        if (parseInt(calculatedValue) === parseFloat(valueFrom)) {
            return Number.parseInt(score);
        }
        return 0;
    },

    checkDisqualification(type) {
        let isSectionDisq = false;
        this.parsedQuestions.forEach((section) => {
            section.questions.forEach((q) => {
                if (q.type === "NOTE") {
                    return;
                }
                if (q.type === "GROUP") {
                    q.questions.forEach((question) => {
                        if (question.type !== "NOTE" && this.isQuestionAnswerDisq(question, type)) {
                            isSectionDisq = true;
                        }
                    });
                }
                if (this.isQuestionAnswerDisq(q, type)) {
                    isSectionDisq = true;
                }
            });
        });

        return isSectionDisq;
    },

    isAppDisqualifiedBySectionType() {
        return this.checkDisqualification("section");
    },

    isSurveyDisqualified() {
        let isSectionDisq = this.checkDisqualification("app");

        this.parsedQuestions.forEach((section) => {
            if (BmiDisqualificationService.isSurveyDisqualifiedByBmi(section.questions, this.bmiDisqualificationRule)) {
                this.disqualificationReasons.BMI_NOT_MET_CRITERIA = BmiDisqualificationService.tryCalculateBmi(section.questions);
                isSectionDisq = true;
            }

            if (AgeDisqualificationService.isSurveyDisqualifiedByAge(section.questions, this.ageDisqualificationRule)) {
                this.disqualificationReasons.AGE_NOT_MET_CRITERIA = AgeDisqualificationService.tryCalculateAge(section.questions);
                isSectionDisq = true;
            }

            if (ConditionDisqualificationService.isSurveyDisqualifiedByCondition(section.questions)) {
                isSectionDisq = true;
            }
        });

        return isSectionDisq;
    },

    isQuestionAnswerDisq(q, disqType) {
        if (q.type === "UPLOAD" || q.type === "TEXT" || q.type === "MULTI_UPLOAD") {
            const answered = q.answers.find((a) => a.userAnswer && a.userAnswer.trim().length > 0);
            return answered && this.isAnswersDisq(q, answered.id, disqType);
        } else if (q.type === "ADDRESS" && disqType === "app") {
            return this.isUserDisqualifiedByAddress(q);
        } else if (q.type === "NUMBER") {
            return q.answers.some((answer) => {
                if (answer?.isDisqualify && answer?.disqType === disqType) {
                    const validationResult = NumberAnswerService.isAnswerDisq(q, answer);
                    if (validationResult.disqualification) {
                        this.disqualificationReasons = {
                            ...this.disqualificationReasons,
                            DISQUALIFICATION_ANSWER_SELECTED: this.disqualificationReasons.DISQUALIFICATION_ANSWER_SELECTED.set(
                                validationResult.key,
                                validationResult.value
                            )
                        };
                    }
                    return validationResult.disqualification;
                }
            });
        } else if (["SELECT_MANY", "MULTI_SELECT", "DROPDOWN_MULTISELECT"].includes(q.type)) {
            if (q[surveyQuestionMethods.lastAnswerListName()] && q[surveyQuestionMethods.lastAnswerListName()].length > 0) {
                if (q[surveyQuestionMethods.lastAnswerListName()].some((a) => this.isAnswersDisq(q, a, disqType))) {
                    const disqualificationAnswers = q.answers
                        .filter((answer) => {
                            const isDisqualified = answer && answer.isDisqualify && answer.disqType === disqType;
                            return !!(q[surveyQuestionMethods.lastAnswerListName()].includes(answer.id || answer.text) && isDisqualified);
                        })
                        .map((a) => a.text);
                    if (disqualificationAnswers.length > 0) {
                        this.disqualificationReasons = {
                            ...this.disqualificationReasons,
                            DISQUALIFICATION_ANSWER_SELECTED: this.disqualificationReasons.DISQUALIFICATION_ANSWER_SELECTED.set(
                                q.text,
                                disqualificationAnswers.join(", ")
                            )
                        };
                    }
                    return true;
                }
            }
            return false;
        } else {
            if (q[surveyQuestionMethods.lastAnswerListName()] && q[surveyQuestionMethods.lastAnswerListName()].length > 0) {
                return q[surveyQuestionMethods.lastAnswerListName()].some((a) => this.isAnswersDisq(q, a, disqType));
            }
            return false;
        }
    },

    /**
     * Validates whether a user is disqualified by country or by state.
     *
     * @param question - question with ADDRESS type.
     * @returns {boolean}
     */
    isUserDisqualifiedByAddress(question) {
        const answer = question.answers[0];
        return this.isUserDisqualifiedByState(answer) || this.isUserDisqualifiedByCountry(answer);
    },

    /**
     * Validates whether a given question's answer is disqualified by country.
     * Currently non-US citizens are automatically disqualified.
     *
     * @param answer - answer of question with ADDRESS type.
     * @returns {boolean}
     */
    isUserDisqualifiedByCountry(answer) {
        const country = answer.addressDetails?.country;
        return country && country !== "United States";
    },

    isUserDisqualifiedByState(answer) {
        if (this.allowedStates.length > 0 && answer.addressDetails && answer.addressDetails.state) {
            if (answer.addressDetails.state.length > 2) {
                const isDisqualified = !this.allowedStates.some((s) => s.toLowerCase() === answer.addressDetails.state.toLowerCase());
                if (isDisqualified) {
                    this.disqualificationReasons.STATE_NOT_MET_CRITERIA = answer.addressDetails.state;
                }
                return isDisqualified;
            } else {
                const normalizedState = States.find((s) => s.name.toLowerCase() === answer.addressDetails.state.toLowerCase())?.title;
                const isDisqualified = !this.allowedStates.some((s) => s.toLowerCase() === normalizedState?.toLowerCase());
                if (isDisqualified) {
                    this.disqualificationReasons.STATE_NOT_MET_CRITERIA = normalizedState;
                }
                return isDisqualified;
            }
        }
        return false;
    },

    isAnswersDisq(question, answerId, disqType) {
        const answer = question?.answers?.find((a) => a.id === answerId || a.text === answerId);
        const isDisqualified = answer && answer.isDisqualify && answer.disqType === disqType;
        if (isDisqualified) {
            const disqualificationQuestion = getTranslatedTextIfNeeded(question.text, "EU");
            const disqualificationAnswer = getTranslatedTextIfNeeded(answer.text, "EU");
            if (!["SELECT_MANY", "MULTI_SELECT", "DROPDOWN_MULTISELECT"].includes(question.type)) {
                this.disqualificationReasons = {
                    ...this.disqualificationReasons,
                    DISQUALIFICATION_ANSWER_SELECTED: this.disqualificationReasons.DISQUALIFICATION_ANSWER_SELECTED.set(
                        disqualificationQuestion,
                        disqualificationAnswer
                    )
                };
            }
        }
        return isDisqualified;
    },

    isSectionAnswered(currentSection) {
        let answeredQuestionsCount = 0;
        let totalQuestionsCount = 0;
        let hiddenQuestionsCount = 0;

        this.parsedQuestions.forEach((section) => {
            if (currentSection !== section.id) {
                return;
            }
            section.questions.forEach((q) => {
                if (q.type === "NOTE") {
                    return;
                }
                if (q.type === "GROUP") {
                    if (q.visibility.length > 0 && this.getHiddenQuestions(q) === 1) {
                        hiddenQuestionsCount += q.questions.length;
                    }
                    q.questions.forEach((q) => {
                        if (q.type === "NOTE") {
                            return;
                        }
                        totalQuestionsCount++;
                        hiddenQuestionsCount += this.getHiddenQuestions(q);
                        if (q.answers.length === 0) {
                            answeredQuestionsCount++;
                        }

                        if (q.type === "UPLOAD" || q.type === "MULTI_UPLOAD") {
                            answeredQuestionsCount++;
                        } else if (q.type === "TEXT" || q.type === "PHONE") {
                            if (this.hasQuestionAnswer(q)) {
                                answeredQuestionsCount++;
                            }
                        } else {
                            if (q.lastAnswerIds && q.lastAnswerIds.length > 0) {
                                answeredQuestionsCount++;
                            }
                        }
                    });
                } else {
                    totalQuestionsCount++;
                    hiddenQuestionsCount += this.getHiddenQuestions(q);
                    if (q.answers.length === 0) {
                        answeredQuestionsCount++;
                    }

                    if (q.optional) {
                        answeredQuestionsCount++;
                    }

                    if (q.type === "DATE") {
                        if (this.isDateQuestionAnswered(q)) {
                            answeredQuestionsCount++;
                        }
                    } else if (q.type === "TEXT" || q.type === "PHONE") {
                        if (this.hasQuestionAnswer(q)) {
                            answeredQuestionsCount++;
                        }
                    } else {
                        if (q.lastAnswerIds && q.lastAnswerIds.length > 0) {
                            answeredQuestionsCount++;
                        }
                    }
                }
            });
        });

        return totalQuestionsCount - hiddenQuestionsCount <= answeredQuestionsCount;
    },

    async getQuestions(history, subSurveyIndex) {
        let result = [];
        try {
            result = this.getAxios()
                .get("/api/user/survey")
                .then((response) => {
                    if (response.data) {
                        this.surveyId = response.data.id;
                        this.showLanguageToggle = response.data.internationalizationSupport;
                        if (!this.showLanguageToggle && history) {
                            this.changeToDefaultLanguage(history);
                        }
                        this.userSurveyId = response.data.userSurveyId;
                        this.userSurveyStatus = response.data.userSurveyStatus;
                        unansweredQuestions.clear();
                        result = this.parseQuestions(response.data, subSurveyIndex);
                        return result;
                    }
                });
        } catch (err) {
            return [];
        }
        return result;
    },

    removeChainsItem(questions, hiddenQuestions, item) {
        const relatedQuestions = questions?.filter((q) => q?.visibility?.find((v) => v?.sourceQuestion === item?.sequenceId) !== undefined);
        relatedQuestions.forEach((q) => {
            if (q.type === "GROUP" && hiddenQuestions.indexOf(q.sequenceId) !== -1) {
                q.questions.forEach((gQ) => {
                    if (surveyQuestionMethods.isQuestionAnswered(gQ)) {
                        removeHiddenAnswer(gQ.id);
                        gQ.lastAnswerIds = [];
                        this.removeChainsItem(questions, hiddenQuestions, gQ);
                    }
                });
            }
            if (q.type !== "GROUP" && hiddenQuestions.indexOf(q.sequenceId) !== -1) {
                if (surveyQuestionMethods.isQuestionAnswered(q)) {
                    removeHiddenAnswer(q.id);
                    q.lastAnswerIds = [];
                    this.removeChainsItem(questions, hiddenQuestions, q);
                }
            }
        });
    },
    async initAccountType() {
        try {
            await this.getAxios()
                .get("/api/new-survey/get/user-data")
                .then((response) => {
                    this.userSurveyStatus = response.data.userSurveyStatus;
                    this.surveyId = response.data.userSurveyId;
                    if (!this.accountType) {
                        switch (response.data.type) {
                            case "GC":
                                this.accountType = "surrogate";
                                break;
                            case "IP":
                                this.accountType = "parent";
                                break;
                            case "ED":
                                this.accountType = "egg_donor";
                                break;
                        }
                    }
                });
        } catch (err) {
            this.accountType = undefined;
        }
    },

    sendIPRegistrationToPardot(firstName, lastName, email) {
        this.getPardotAxios().post(Constants.PARDOT_URLS.REGISTRATION.PARENT, this.getPardotParams(firstName, lastName, email));
    },

    sendGCRegistrationToPardot(firstName, lastName, email) {
        this.getPardotAxios().post(Constants.PARDOT_URLS.REGISTRATION.SURROGATE, this.getPardotParams(firstName, lastName, email));
    },

    sendEDRegistrationToPardot(firstName, lastName, email) {
        this.getPardotAxios().post(Constants.PARDOT_URLS.REGISTRATION.EGG_DONOR, this.getPardotParams(firstName, lastName, email));
    },

    sendStartIPAppToPardot(firstName, lastName, email) {
        this.getPardotAxios().post(Constants.PARDOT_URLS.PARENT, this.getPardotParams(firstName, lastName, email));
    },

    sendStartGCAppToPardot(firstName, lastName, email) {
        this.getPardotAxios().post(Constants.PARDOT_URLS.SURROGATE, this.getPardotParams(firstName, lastName, email));
    },

    sendStartEGAppToPardot(firstName, lastName, email) {
        this.getPardotAxios().post(Constants.PARDOT_URLS.EGG_DONOR, this.getPardotParams(firstName, lastName, email));
    },

    getPardotParams(firstName, lastName, email) {
        const params = new URLSearchParams();
        params.append("firstName", firstName);
        params.append("lastName", lastName);
        params.append("email", email);
        return params;
    },

    changeToDefaultLanguage(history) {
        i18n.changeLanguage("en");
        this.cookies.set("i18next", "en", {
            path: "/"
        });
        if (history.location) {
            const queryParams = new URLSearchParams(history.location.search);
            queryParams.delete("lang");
            history.replace({
                search: queryParams.toString()
            });
        }
        this.lang = "en";
    },

    changeLanguageHandler(lang, history) {
        i18n.changeLanguage(lang);
        this.cookies.set("i18next", lang, {
            path: "/"
        });
        if (history) {
            if (lang !== "en") {
                history.push(`?lang=${lang}`);
            } else {
                if (history.location) {
                    const queryParams = new URLSearchParams(history.location.search);
                    queryParams.delete("lang");
                    history.replace({
                        search: queryParams.toString()
                    });
                }
            }
        }
        this.lang = lang;
    },

    getPardotAxios() {
        return axios.create({
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    },

    getAxiosWithRawBody() {
        return axios.create({
            baseURL: Constants.REST_API_URL,
            headers: {
                Authorization: `Bearer ${this.cookies.get("surat")}`,
                "Content-Type": "application/json"
            }
        });
    },

    getAxios: function () {
        return axios.create({
            baseURL: Constants.REST_API_URL,
            headers: {
                Authorization: `Bearer ${this.cookies.get("surat")}`
            }
        });
    }
};

export default AppService;
