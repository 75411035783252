import React from "react";
import Feedback from "@components/feedback/FeedbackComponent";
import { getTranslatedTextIfNeeded } from "./InternationalizationTextUtils";
import AppService from "@service/AppService/AppService";
import { surveyQuestionMethods } from "./Questions/Questions";

export const getFeedback = (answer) => {
    const feedbackItems = [];
    if (answer.isReview || answer.isDisqualify || (answer.visibility && answer.visibility.length > 0)) {
        return feedbackItems;
    }
    if (answer.userAnswer && answer.userAnswer.length > 0 && answer.feedback && answer.feedback.text.length > 0) {
        const feedback = {
            title: getTranslatedTextIfNeeded(answer.feedback.title),
            text: getTranslatedTextIfNeeded(answer.feedback.text)
        };
        feedbackItems.push(<Feedback key={answer.sequenceId} feedback={feedback}></Feedback>);
    }
    return feedbackItems;
};

export const getFeedbackWithQuestion = (question, answer, index, feedbackItems) => {
    if (answer.isReview || answer.isDisqualify || (answer.visibility && answer.visibility.length > 0)) {
        return;
    }
    if (
        question[surveyQuestionMethods.lastAnswerListName()] &&
        question[surveyQuestionMethods.lastAnswerListName()].includes(answer.id || answer.text) &&
        answer.feedback &&
        answer.feedback.text.length > 0
    ) {
        const feedback = {
            title: getTranslatedTextIfNeeded(answer.feedback.title),
            text: getTranslatedTextIfNeeded(answer.feedback.text)
        };
        feedbackItems && feedbackItems.push(<Feedback key={AppService.Guid() + "-" + index} feedback={feedback} />);
    }
    return feedbackItems;
};

export const getFeedbackWithoutIndex = (question, answer, feedbackItems) => {
    if (answer.isReview || answer.isDisqualify || (answer.visibility && answer.visibility.length > 0)) {
        return;
    }
    return getItems(question, answer, feedbackItems);
};

export const getFeedbackForRadio = (question, answer, feedbackItems) => {
    if (
        !question[surveyQuestionMethods.lastAnswerListName()] ||
        question[surveyQuestionMethods.lastAnswerListName()].length === 0 ||
        answer.isReview ||
        answer.isDisqualify ||
        (answer.visibility && answer.visibility.length > 0)
    ) {
        return;
    }
    return getItems(question, answer, feedbackItems);
};

const getItems = (question, answer, feedbackItems) => {
    if (
        question[surveyQuestionMethods.lastAnswerListName()] &&
        question[surveyQuestionMethods.lastAnswerListName()].includes(answer.id || answer.text) &&
        answer.feedback &&
        answer.feedback.text.length > 0
    ) {
        const feedback = {
            title: getTranslatedTextIfNeeded(answer.feedback.title),
            text: getTranslatedTextIfNeeded(answer.feedback.text)
        };
        feedbackItems.push(<Feedback key={AppService.Guid()} feedback={feedback} />);
    }
    return feedbackItems;
};
