import React from "react";
import "./PasswordRecoveryComponent.scss";

import AccountSecurityLayout from "@components/accountSecurityLayout/AccountSecurityLayoutComponent";
import PasswordTextBoxComponent from "@components/passwordTextBox/PasswordTextBoxComponent";
import LiteCheckbox from "@components/liteCheckBox/LiteCheckBoxComponent";
import Flyout from "@components/flyout/FlyoutComponent";
import AppService from "@service/AppService/AppService";
import Login from "../login/LoginComponent";
import { Redirect } from "react-router-dom";

class PasswordRecoveryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirmation: "",
            passwordError: false,
            isFormValid: false,
            showError: false,
            showSuccess: false
        };
        this.validateForm = this.validateForm.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.isPasswordValid = this.isPasswordValid.bind(this);
        this.savePassword = this.savePassword.bind(this);
    }

    savePassword() {
        const isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({ showProgress: true });
            AppService.getAxios()
                .post("/auth/password/refresh", {
                    newPassword: this.state.password,
                    hash: this.props.match.params.hash
                })
                .then(() => {
                    this.setState({
                        showProgress: false,
                        showError: false,
                        showSuccess: "Password successfully changed.",
                        navigateToLogin: true
                    });
                })
                .catch(() => {
                    this.setState({
                        showProgress: false,
                        showSuccess: false,
                        showError: "This link is expired. Please use a reset password form again."
                    });
                });
        }
    }

    getPasswordInfo() {
        const password = this.state.password;
        return {
            isLengthValid: password.length > 7,
            isContainUpperLetter: /[A-Z]/.test(password),
            isContainLowerLetter: /[a-z]/.test(password),
            isContainNumers: /[0-9]/.test(password)
        };
    }

    handlePasswordChange(password) {
        this.setState({ password });
    }

    handleConfirmPasswordChange(passwordConfirmation) {
        this.setState({ passwordConfirmation });
    }

    validateForm = () => {
        return this.state.password === this.state.passwordConfirmation && this.isPasswordValid();
    };

    isPasswordValid() {
        const passwordInfo = this.getPasswordInfo();
        return (
            passwordInfo.isContainLowerLetter &&
            passwordInfo.isContainNumers &&
            passwordInfo.isContainUpperLetter &&
            passwordInfo.isLengthValid
        );
    }

    render() {
        const isFormValid = this.validateForm();
        const passwordInfo = this.getPasswordInfo();

        if (this.state.navigateToLogin) {
            return <Redirect to="/login" push={true} />;
        }

        const componentContent = this.state.navigateToLogin ? (
            <Login></Login>
        ) : (
            <AccountSecurityLayout>
                <h1>Setup new password</h1>
                <div className="form password-recovery">
                    <PasswordTextBoxComponent
                        error={this.state.passwordError}
                        errorText="Password seems to be wrong"
                        title="Enter your password"
                        id="txtPassword"
                        onPasswordChange={this.handlePasswordChange}
                    ></PasswordTextBoxComponent>
                    <PasswordTextBoxComponent
                        title="Confirm you new password"
                        id="txtConfirmPassword"
                        onPasswordChange={this.handleConfirmPasswordChange}
                    ></PasswordTextBoxComponent>
                    <div className="password-description">
                        Your password must be <LiteCheckbox isChecked={passwordInfo.isLengthValid} />
                        &nbsp;<b>at least 8 characters long</b>, contain &nbsp;
                        <LiteCheckbox isChecked={passwordInfo.isContainUpperLetter} /> <b>upper</b> and &nbsp;
                        <LiteCheckbox isChecked={passwordInfo.isContainLowerLetter} /> <b>lowercase</b> letters and &nbsp;
                        <LiteCheckbox isChecked={passwordInfo.isContainNumers} /> <b>numbers</b>.
                    </div>
                    {isFormValid ? (
                        <input type="button" value="Change" className="button" onClick={this.savePassword} />
                    ) : (
                        <input type="button" value="Change" className="button disabled" />
                    )}
                </div>
            </AccountSecurityLayout>
        );

        return (
            <div>
                {this.state.showProgress ? <div className={`animated-gradient`}></div> : null}
                {this.state.showError ? <Flyout type="error" message={this.state.showError} onClose={() => {}}></Flyout> : null}
                {this.state.showSuccess ? <Flyout type="success" message={this.state.showSuccess} onClose={() => {}}></Flyout> : null}
                {componentContent}
            </div>
        );
    }
}
export default PasswordRecoveryComponent;
