import React, { useContext, useEffect, useState } from "react";
import "./SurveyNavigationComponent.scss";
import AppService from "@service/AppService/AppService";
import { AuthContext } from "../../AuthContext";
import BackButtonComponent from "../backButton/BackButtonComponent";
import DropDownComponent from "../dropDowns/dropDown/DropDownComponent";
import { t } from "i18next";
import TimelineComponent from "../timeline/TimelineComponent";
import { getTranslatedTextIfNeeded } from "../../shared/InternationalizationTextUtils";
import classNames from "classnames";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";
import sidebarOpen from "@resources/images/sidebar-open.svg";
import sidebarClose from "@resources/images/sidebar-close.svg";
import { useHistory } from "react-router-dom";

function SurveyNavigationComponent({ changeSurveyAndSection, subSurveys, selectedTopic }) {
    const authContext = useContext(AuthContext);
    const questionContext = useContext(SurveyQuestionsContext);

    const history = useHistory();
    const [showSections, setShowSections] = useState(0);
    const [profileType, setProfileType] = useState("");

    useEffect(() => {
        setProfileType(authContext.user?.role.toLowerCase());
    }, [authContext.user?.role]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (!event.target.classList.contains("show-mobile-sections") && !event.target.classList.contains("show-sections")) {
                setShowSections(-1);
            }
        }
        if (showSections === 1) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => document.removeEventListener("click", handleClickOutside);
    }, [showSections]);

    const changeLanguage = (lang) => {
        if (lang === "English") {
            AppService.changeLanguageHandler("en", history);
        }
        if (lang === "Espanol") {
            AppService.changeLanguageHandler("es", history);
        }
    };

    const showLanguageToggle = () => {
        return profileType === "surrogate" && AppService.lang !== "en" && AppService.showLanguageToggle;
    };

    let items = [{ text: "English" }, { text: "Espanol" }];
    let selectedLanguage = AppService.lang === "en" ? { text: "English" } : { text: "Espanol" };

    const showNavigation = () => {
        setShowSections(1);
    };

    const hideNavigation = () => {
        setShowSections(-1);
    };

    const mobileSurveyClassNames = classNames("mobile-survey-process", {
        "hide-mobile-sections": showSections === -1,
        "show-mobile-sections": showSections === 1
    });

    const profileColorsClassNames = classNames({
        "ip-colors": profileType === "parent",
        "ed-colors": profileType === "egg_donor",
        "gc-colors": profileType === "surrogate"
    });

    const isMobile = AppService.isMobile();

    const showBackButton = (AppService.accountType && AppService.accountType !== "surrogate") || authContext.isSurveyFinished();

    return (
        <>
            <div className={isMobile ? mobileSurveyClassNames : "survey-process"}>
                <>
                    {!isMobile && showBackButton && <BackButtonComponent backUrl="/start" onClick={questionContext.toggleShowSurveys} />}
                    {showLanguageToggle() && (
                        <div className="survey-language-selector app-survey-timeline">
                            <DropDownComponent
                                label={"Language"}
                                isLanguageDropdown={true}
                                items={items}
                                selectedItem={selectedLanguage}
                                placeholder={t("Select")}
                                onChange={(lang) => changeLanguage(lang.text)}
                            />
                        </div>
                    )}
                    {isMobile && <h2 className={profileColorsClassNames}>Navigation</h2>}
                    <div className="sections-and-subsurveys">
                        {subSurveys?.map((item, index) => (
                            <TimelineComponent
                                key={`${index}_time_line`}
                                disabled={true}
                                questions={item.sections}
                                selectedTopic={selectedTopic}
                                className="survey-process"
                                title={getTranslatedTextIfNeeded(item.name)}
                                surveyIndex={index}
                                changeSurveyAndSection={changeSurveyAndSection}
                                showAllTimelines={true}
                            />
                        ))}
                    </div>
                    {isMobile && (
                        <button className={`${profileColorsClassNames} hide-sections`} onClick={() => hideNavigation()}>
                            <img src={sidebarClose} />
                        </button>
                    )}
                </>
            </div>
            {isMobile && (
                <button className={`${profileColorsClassNames} show-sections`} onClick={() => showNavigation()}>
                    <img src={sidebarOpen} />
                </button>
            )}
        </>
    );
}

export default SurveyNavigationComponent;
