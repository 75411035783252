import React, { useContext } from "react";
import i18n from "i18next";
import Constants from "@helper/Constants";
import AppService from "../../../service/AppService/AppService";
import { AuthContext } from "../../../AuthContext";
import Feedback from "@components/feedback/FeedbackComponent";
import { SurveyQuestionsContext } from "../../../SurveyQuestionsStore";
import { UserSurveyStatus } from "../../../helper/Constants";

function SurveyFinishedFeedback({ isLastTopic, isGroup }) {
    const authContext = useContext(AuthContext);
    const { questions } = useContext(SurveyQuestionsContext);

    let surveyFinishedFeedback = null;

    if (!isGroup && AppService.isAllQuestionsAnswered() && isLastTopic) {
        const feedback = {
            title: i18n.t("surrogate_banner_title"),
            text: i18n.t("survey_finished_surrogate_feedback_text")
        };

        const surveyFinishedNotSubmittedSurrogateFeedback1 =
            authContext.user?.role === "SURROGATE" ? feedback : Constants.SURVEY_FINISHED_NOT_SUBMITTED_FEEDBACK_1;
        let text = surveyFinishedNotSubmittedSurrogateFeedback1.text;
        if (AppService.userSurveyStatus === UserSurveyStatus.STARTED) {
            text = `${text} ${Constants.SURVEY_FINISHED_NOT_SUBMITTED_FEEDBACK_2.text}`;
        }
        const feedbackData = {
            title: surveyFinishedNotSubmittedSurrogateFeedback1.title,
            text: text
        };
        surveyFinishedFeedback = <Feedback feedback={feedbackData} />;
    }

    if (authContext.isSurveyFinished() && questions?.length > 0) {
        return surveyFinishedFeedback;
    }

    return "";
}

export default SurveyFinishedFeedback;
