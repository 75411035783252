import React, { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import EggDonorListComponent from "../../components/EggDonorListComponent/EggDonorListComponent";
import { getReservedDonors } from "../../requests/EggDonorListRequests/EggDonorListRequests";

function ReservedDonors() {
    const { user } = useContext(AuthContext);
    const oneLoadCount = 12;

    const loadItems = (number, body, count = oneLoadCount) => getReservedDonors(number, user?.id, count, body);

    return <EggDonorListComponent loadItems={loadItems} oneLoadCount={oneLoadCount} showReservedBanner />;
}

export default ReservedDonors;
