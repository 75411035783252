import AppService from "../../service/AppService/AppService";

export async function getAllSections() {
    return await AppService.getAxios().get(`/api/admin/editor/questions/get-sections`);
}
export async function getAllSalesforceFields(sectionName, searchText) {
    const encodedSectionName = encodeURIComponent(sectionName);
    return await AppService.getAxios().get(
        `/api/admin/editor/questions/get-all?sectionName=${encodedSectionName}&searchText=${searchText}`
    );
}

export async function getAdminLogs(edId) {
    return await AppService.getAxios().get(`/api/admin/logs/egg-donor/${edId}/get/all`);
}

export async function getAdminShortDonorInfo(userId) {
    return await AppService.getAxios().get(`/api/user-with-answers/admin/${userId}/registration-info`);
}

export async function saveSalesforceField(data) {
    return await AppService.getAxios().post(`/api/admin/editor/questions/add-display-content`, data);
}
export async function getRelatedQuestion(id) {
    return await AppService.getAxios().get(`/api/admin/editor/questions/${id}`);
}

export async function getAdminNotes(id) {
    return await AppService.getAxios().get(`/api/admin/note/get/all/${id}`);
}
export async function createAdminNote(body) {
    return await AppService.getAxios().post("/api/admin/note/create", body);
}
export async function updateAdminNote(noteId, body) {
    return await AppService.getAxiosWithRawBody().put(`/api/admin/note/update/${noteId}`, body);
}
export async function deleteAdminNote(noteId) {
    return await AppService.getAxios().delete(`/api/admin/note/delete/${noteId}`);
}
export async function saveDonorCompensation(eggDonorId, body) {
    return await AppService.getAxiosWithRawBody().post(`/api/compensation/save/${eggDonorId}`, body);
}
export async function getDonorCompensation(eggDonorId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${eggDonorId}/get/compensation`);
}

export async function getFullProfileSections() {
    return await AppService.getAxios().get(`/api/get-profile/sections`);
}

export async function getFullProfileQuestions(edId, section) {
    return await AppService.getAxios().get(`/api/get-profile/${edId}/section/${section}`);
}

export async function getEDShortData(edId) {
    return await AppService.getAxios().get(`/api/get-profile/short-data/${edId}`);
}

export async function getBasicDonorInfo(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/get/basic-info`);
}

export async function getDonorAddresses(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/get/address-info`);
}

export async function getDonorPriorInfo(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/get/prior-info`);
}

export async function getDonorCyclesInfo(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/get/cycles-info`);
}
export async function getDonorFeeInfo(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/get/fee`);
}

export async function updateDonorSummary(edId, body) {
    return await AppService.getAxios().put(`/api/admin/egg-donor/${edId}/update/summary`, body);
}
export async function getDonorPhoto(edId) {
    return await AppService.getAxios().get(`/api/admin/egg-donor/${edId}/get/photo`);
}

export async function getPdfForAdmin(edId) {
    return await AppService.getAxios().get(`/api/pdf/generate/${edId}/admin`);
}
export async function getPdfForClinics(edId) {
    return await AppService.getAxios().get(`/api/pdf/generate/${edId}/clinical`);
}
export async function getPdfForParent(edId) {
    return await AppService.getAxios().get(`/api/pdf/generate/${edId}/parent`);
}

export async function getDonorIdByIntegrationId(integrationId) {
    return await AppService.getAxios().get(`/api/user/get/app-id/${integrationId}`);
}

export async function getActiveLiveDonorIds() {
    return await AppService.getAxios().get(`/api/user-with-answers/active-live/ids`);
}

export async function getFavoriteDonorIds() {
    return await AppService.getAxios().get(`/api/user-with-answers/favorite/ids`);
}
export async function getReservedDonorIds() {
    return await AppService.getAxios().get(`/api/user-with-answers/reserved/ids`);
}
