import React from "react";
import "./DropDownComponent.scss";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import arrow from "@resources/images/arrow_down.svg";
import i18n from "i18next";
import classNames from "classnames";

class DropDownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropDown: false
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.showDropDown = this.showDropDown.bind(this);
        this.selectItem = this.selectItem.bind(this);
    }

    keyDown(event) {
        if (event.keyCode === 27 && this.state.showDropDown) {
            this.showDropDown();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
        document.addEventListener("mousedown", this.handleDocumentClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDown, false);
        document.removeEventListener("mousedown", this.handleDocumentClick, false);
    }

    handleDocumentClick(e) {
        if (this.node && !this.node.contains(e.target)) {
            this.setState({ showDropDown: false });
        }
    }

    showDropDown() {
        this.setState({ showDropDown: !this.state.showDropDown });
    }

    selectItem(item) {
        this.setState({ showDropDown: false });
        if (this.props.onChange) {
            this.props.onChange(item);
        }
    }

    isReadOnly = () => {
        const { readOnly } = this.props;

        if (readOnly !== undefined && readOnly != null && readOnly !== "") {
            return !!this.props.readOnly;
        }
        return true;
    };

    onChangeInputText = ({ target }) => {
        if (this.props.handleChangeInputText) {
            this.props.handleChangeInputText(target.value);
        }
    };

    render() {
        const selectedItemIndex = this.props.items.findIndex((item) => item.text === this.props.selectedItem?.text);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        const itemsList = this.props.items.map((item, index) => {
            const className = selectedItemIndex === index ? "item-selected" : "";
            let dropdownText = !this.props.datepicker ? getTranslatedTextIfNeeded(item.text) : item.text;

            if (monthNames.indexOf(dropdownText) !== -1) {
                dropdownText = i18n.t(dropdownText);
            }

            return (
                <li
                    key={index}
                    className={className}
                    onClick={() => {
                        this.selectItem(item);
                    }}
                >
                    {dropdownText}
                </li>
            );
        });

        let selectedValue = "";
        if (this.props.selectedItem?.text) {
            selectedValue = !this.props.datepicker ? getTranslatedTextIfNeeded(this.props.selectedItem.text) : this.props.selectedItem.text;
        }

        const getDropDownClassNames = () => {
            return classNames("drop-down", this.props.customClass, {
                "language-dropdown": this.props.isLanguageDropdown,
                "language-finished": this.props.isSurveyFinished
            });
        };

        return (
            <div className="drop-down-container" ref={(node) => (this.node = node)}>
                <div className={getDropDownClassNames()}>
                    <label>{this.props.label}</label>
                    <input
                        type="text"
                        disabled={this.props.isDisabled}
                        value={selectedValue}
                        placeholder={this.props.placeholder}
                        readOnly={this.isReadOnly()}
                        onChange={this.onChangeInputText}
                        onClick={this.showDropDown}
                    />
                    {this.props.isArrowHidden ? null : (
                        <img className="dropdown-arrow" src={arrow} alt="dropdown-arrow" onClick={this.showDropDown} />
                    )}
                </div>
                {this.props.items.length > 0 && this.state.showDropDown && !this.props.isDisabled ? (
                    <div className="drop-down-items">
                        <ul>{itemsList}</ul>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default DropDownComponent;
