import React, { useContext, useEffect, useState } from "react";
import { getTranslatedTextIfNeeded } from "../../shared/InternationalizationTextUtils";
import QuestionsList from "../questionsList/QuestionsListComponent";
import "./DynamicGroup.scss";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";
import removeGroup from "../../resources/images/delete_basket.svg";
import addGroup from "../../resources/images/user_add.svg";
import classNames from "classnames";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import { getDynamicGroupById } from "../../requests/surveyAPIRequests/surveyAPIRequests";
import AppService from "../../service/AppService/AppService";
import { getDynamicGroupByAdmin } from "../../requests/AdminSurveyAPIRequests/AdminSurveyAPIRequests";

function DynamicGroup({ selectedTopic, question, onAnswer, updateProgress, readonly, className }) {
    const questionContext = useContext(SurveyQuestionsContext);

    const [createdInstances, setCreatedInstances] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => await getInstances())();
    }, []);

    const relatedQuestion = selectedTopic.questions.find((q) => q.sequenceId === question.dynamicGroupSource);
    const relatedAnswerValue = Number(surveyQuestionMethods.getAnswerValue(relatedQuestion));
    const requiredInstances = relatedAnswerValue > 15 ? 15 : relatedAnswerValue;
    const instanceDiff = requiredInstances - createdInstances.length - 1;

    useEffect(() => {
        if (!loading && relatedQuestion && createdInstances.length > 0) {
            if (instanceDiff >= 0) {
                addInstance(instanceDiff);
            } else if (instanceDiff < 0 && requiredInstances >= 0) {
                setCreatedInstances((currentInstances) => currentInstances.slice(0, requiredInstances || 1));
            }
        }
    }, [selectedTopic, loading]);

    useEffect(() => {
        const updatedDynamicQuestion = { id: question.id, sequenceId: question.sequenceId, createdInstances: createdInstances };
        questionContext.setDynamicQuestions(updatedDynamicQuestion);
    }, [createdInstances]);

    const getInstances = async () => {
        try {
            setLoading(true);
            questionContext.isSurveyLoad(false);
            const response =
                AppService.accountType === "admin"
                    ? await getDynamicGroupByAdmin(AppService.edId, question.id)
                    : await getDynamicGroupById(question.id);
            if (response.data === "No dynamic group answer") {
                addInstance(instanceDiff);
            } else {
                setCreatedInstances(response.data);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            questionContext.isSurveyLoad(true);
        }
    };

    const addInstance = (instanceCount) => {
        if (readonly) return;
        const newQuestions = buildNewQuestionList(question);
        const newInstance = createInstance(newQuestions);
        let newInstances = [];
        if (instanceCount > 0) {
            for (let i = 0; i < instanceCount; i++) {
                const createdInstance = createInstance(buildNewQuestionList(question));
                applyVisibilityForNewInstance(createdInstance);
                newInstances.push(createdInstance);
            }
        }
        applyVisibilityForNewInstance(newInstance);
        setCreatedInstances([...createdInstances, newInstance, ...newInstances]);
        if (relatedQuestion && instanceCount > 0 && createdInstances.length > 0 && !loading) {
            onAnswer(selectedTopic, question, [...createdInstances, newInstance, ...newInstances]);
        }
    };

    const buildNewQuestionList = (question) => {
        return question.questions.map((question) => {
            let newQuestion = cloneDeep(cleanQuestion(question));
            newQuestion.sequenceId = v4();

            newQuestion.answers = question.answers.map((answer) => {
                let newAnswer = cloneDeep(cleanAnswer(answer));
                newAnswer.sequenceId = v4();
                if (newAnswer.userAnswer) {
                    newAnswer.userAnswer = "";
                }
                return newAnswer;
            });

            return newQuestion;
        });
    };
    const applyVisibilityForNewInstance = (newInstance) => {
        const questionIdMap = new Map();
        question.questions.forEach((originalQuestion, index) => {
            questionIdMap.set(originalQuestion.sequenceId, newInstance.groupWithAnswers[index].sequenceId);
        });

        const answerIdMap = new Map();
        question.questions.forEach((originalQuestion, index) => {
            originalQuestion.answers.forEach((originalAnswer, answerIndex) => {
                answerIdMap.set(originalAnswer.sequenceId, newInstance.groupWithAnswers[index].answers[answerIndex].sequenceId);
            });
        });

        newInstance.groupWithAnswers.forEach((q) => {
            q.visibility = [];
        });

        question.questions.forEach((originalQuestion, qIndex) => {
            originalQuestion.visibility.forEach((visCondition) => {
                const newSourceQuestion = questionIdMap.get(visCondition.sourceQuestion);
                const newTargetQuestion = questionIdMap.get(visCondition.targetQuestion);
                const newSequenceId = answerIdMap.get(visCondition.sequenceId);

                const newVisibilityCondition = {
                    sequenceId: newSequenceId,
                    sourceQuestion: newSourceQuestion,
                    targetQuestion: newTargetQuestion
                };

                newInstance.groupWithAnswers[qIndex].visibility.push(newVisibilityCondition);
            });
        });
    };

    const createInstance = (instance) => {
        return {
            instanceId: v4(),
            instanceGroupName: question.instanceGroupName,
            groupWithAnswers: instance.map((question) => ({ ...cleanQuestion(question), lastAnswersSelected: [] }))
        };
    };

    const cleanQuestion = (question) => {
        const { conditions, noteContent, optional, hint, ...cleanQuestion } = question;
        cleanQuestion.answers = cleanQuestion.answers.map(cleanAnswer);
        return cleanQuestion;
    };

    const cleanAnswer = (answer) => {
        const { feedback, isDisqualify, isReview, ...cleanAnswer } = answer;
        return cleanAnswer;
    };

    const handleChangeAnswer = (selectedTopic, groupQuestion, answer) => {
        const updatedInstances = createdInstances.map((instance) => {
            instance.groupWithAnswers = instance.groupWithAnswers.map((q) => {
                if (q.sequenceId === groupQuestion.sequenceId) {
                    q.answers = q.answers.map((a) => {
                        if (answer.sequenceId === a.sequenceId) {
                            return answer;
                        }
                        return a;
                    });

                    if (q.lastAnswersSelected.includes(answer.text)) {
                        q.lastAnswersSelected = q.lastAnswersSelected.filter((answerText) => answerText !== answer.text);
                    } else {
                        if (isQuestionHaveOnlyOneAnswer(q.type)) {
                            q.lastAnswersSelected = [answer.text];
                        } else if (surveyQuestionMethods.isMultiSelectQuestionType(q.type)) {
                            q.lastAnswersSelected = [answer.text, ...q.lastAnswersSelected];
                        }
                    }
                }
                return q;
            });
            return instance;
        });

        let instancesToStore;

        if (updatedInstances.some(hasAtLeastOneCompletedAnswer)) {
            instancesToStore = updatedInstances.filter(hasAtLeastOneCompletedAnswer);
        } else {
            instancesToStore = updatedInstances.slice(0, 1);
        }

        setCreatedInstances(updatedInstances);
        onAnswer(selectedTopic, question, instancesToStore);
    };

    const hasAtLeastOneCompletedAnswer = (instance) => {
        return instance.groupWithAnswers.some(
            (q) =>
                q.answers.some((a) => a.userAnswer && a.userAnswer.trim() !== "") ||
                (q.lastAnswersSelected && q.lastAnswersSelected.length > 0)
        );
    };
    const isQuestionHaveOnlyOneAnswer = (type) => {
        return type === "YES_NO" || type === "SINGLE_ONE" || type === "DROPDOWN";
    };

    const removeInstance = (index) => {
        if (createdInstances.length === 1) return;
        const updatedCreatedInstances = createdInstances.filter((_, i) => i !== index);
        setCreatedInstances(updatedCreatedInstances);
        onAnswer(selectedTopic, question, updatedCreatedInstances);
    };

    if (loading) {
        return (
            <div className="question-container">
                <div className="survey-question dynamic-group-section">
                    <SpinnerLoader />
                </div>
            </div>
        );
    }
    return (
        <div className="question-container">
            <div className={classNames("survey-question dynamic-group-section", className)}>
                <div className="question-title">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="content">
                    <>
                        {createdInstances.map((instance, index) => (
                            <div id={question.sequenceId} key={index} className="dynamic-instance">
                                <div className="instance-title">
                                    <div>
                                        <span>
                                            {question.instanceGroupName}
                                            {question.showInstanceCount ? ` ${index + 1} ` : ""}
                                        </span>
                                    </div>
                                    {index > 0 && (
                                        <img
                                            src={removeGroup}
                                            alt="remove group"
                                            className="remove-icon"
                                            onClick={() => removeInstance(index)}
                                        />
                                    )}
                                </div>
                                <QuestionsList
                                    dynamicQuestion={question}
                                    instanceId={instance.instanceId}
                                    questions={instance.groupWithAnswers}
                                    selectedTopic={selectedTopic}
                                    onAnswer={handleChangeAnswer}
                                    isDynamicGroup={true}
                                    isGroup={true}
                                    updateProgress={updateProgress}
                                />
                            </div>
                        ))}
                    </>
                    <div className="add-more" onClick={addInstance}>
                        <img src={addGroup} />
                        <span className="add-more-text">{`Add ${question?.instanceGroupName}`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DynamicGroup;
