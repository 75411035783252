import React from "react";
import "./SectionDropdownComponent.scss";
const SectionDropdownComponent = ({ sections, onSelect }) => {
    const handleOnChange = (event) => {
        const selectedValue = event.target.value;
        onSelect(selectedValue);
    };
    return (
        <>
            <div className="sections-dropdown">
                <label htmlFor="sectionDropdown" className="sections-dropdown-label">
                    Select section
                </label>
                <select id="sectionDropdown" className="sections-dropdown-select" onChange={handleOnChange}>
                    <option className="section-option" key="" value=""></option>
                    {sections.map((section) => (
                        <option className="section-option" key={section.key} value={section.name}>
                            {section.name}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default SectionDropdownComponent;
