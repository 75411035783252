import React from "react";

import "./CalendlyComponent.scss";

class CalendlyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.calendlyBoxRef = React.createRef();
    }

    handleClickOutside = (event) => {
        if (this.calendlyBoxRef.current && !this.calendlyBoxRef.current.contains(event.target)) {
            this.props.hideCalendly();
        }
    };

    render() {
        return (
            <div className="calendly-wrapper">
                {this.props.showCalendly ? (
                    <div className="overlay" onClick={this.handleClickOutside}>
                        <div className="close-overlay" onClick={() => this.props.hideCalendly()}>
                            <div>X</div>
                        </div>
                        <div className="calendly-box" ref={this.calendlyBoxRef}>
                            <iframe src={this.props.link} width="100%" height="100%" frameBorder="0" />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default CalendlyComponent;
