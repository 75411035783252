import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import "./Notification.scss";

export const Notification = ({ title, message, status, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [onClose]);

    return (
        <div className={`notification ${status}`}>
            <div className="notification-title">{title}</div>
            <div className="notification-message">{message}</div>
        </div>
    );
};

const triggerNotification = (title, message, status) => {
    const handleClose = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("notification-box"));
    };

    ReactDOM.render(
        <Notification title={title} message={message} status={status} onClose={handleClose} />,
        document.getElementById("notification-box")
    );
};

export const showErrorNotification = (title, message) => {
    triggerNotification(title, message, "error");
};
export const showSuccessNotification = (title, message) => {
    triggerNotification(title, message, "success");
};
export const showWarningNotification = (title, message) => {
    triggerNotification(title, message, "warning");
};
export const showInfoNotification = (title, message) => {
    triggerNotification(title, message, "info");
};
