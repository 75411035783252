import React, { useEffect, useRef, useState } from "react";
import "./PhotoList.scss";
import SecureImageComponent from "@components/secureImage/SecureImageComponent";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";
import cancelIcon from "@resources/images/cancel.svg";
import saveIcon from "@resources/images/save-icon.svg";
import editIcon from "@resources/images/edit-icon.svg";
import addPhoto from "@resources/images/add-photo-icon.svg";
import removePhoto from "@resources/images/remove-photo.svg";
import swapPhoto from "@resources/images/swap-photo.svg";
import ModalPopupComponent from "@components/modalPopup/ModalPopupComponent";
import FlyoutComponent from "../../../components/flyout/FlyoutComponent";
import PhotoListService from "../../../service/PhotoListService/PhotoListService";
import { generateFilename } from "../../../components/PhotoUpload/PhotoUtils";
import AppService from "../../../service/AppService/AppService";

function PhotoList({ donor, className, setDonor, isAdmin }) {
    const { galleryId, photoId } = donor;
    const [loadingImage, setLoadingImage] = useState(true);
    const [loadedImage, setLoadedImage] = useState([]);
    const [removedPhotoIndex, setRemovedPhotoIndex] = useState(null);
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [showSwapPopup, setShowSwapPopup] = useState(false);
    const [photoForSwap, setPhotoForSwap] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [galleryImageCount, setGalleryImageCount] = useState(donor?.userWithAnswerGallery?.galleryItems.length || 0);
    const [galleryAvailable, setGalleryAvailable] = useState(false);
    const [galleryList, setGalleryList] = useState([]);
    const imageCounter = useRef(0);
    const photoUpload = useRef(null);

    useEffect(() => {
        AppService.edId = donor.id;
        PhotoListService.getGalleryAvailability(donor.id).then((resp) => {
            setGalleryAvailable(resp.data);
            if (resp.data) {
                setGalleryImageCount(resp.data.length);
                setGalleryList(resp.data);
            }
        });
    }, []);

    const onEditDescription = (index) => {
        galleryList[index].edit = true;
        setDonor({ ...donor });
    };

    const onCancelEdit = (index) => {
        galleryList[index].edit = false;
        setDonor({ ...donor });
    };

    const onSaveDescription = (index) => {
        if (galleryList[index].editedDescription) {
            galleryList[index].description = galleryList[index].editedDescription;
            delete galleryList[index].editedDescription;

            const body = JSON.stringify({
                filename: galleryList[index].name,
                description: galleryList[index].description
            });

            PhotoListService.updateGalleryItemDescription(galleryId, body).catch((e) => console.log(e));
        }
        galleryList[index].edit = false;
        setDonor({ ...donor });
    };

    const handleChangeDescription = (event, index) => {
        const editedValue = event.target.value;
        galleryList[index].editedDescription = editedValue;
        setDonor({ ...donor });
    };

    const uploadMultiplePhoto = (event) => {
        event.preventDefault();
        setLoadingImage(true);
        let uploadedFiles = [];
        const allowedSize = 15 * 1024 * 1024;
        const preparedData = [...event.target.files].filter((file) => file.size <= allowedSize);

        if (preparedData.length === 0) {
            setErrorMessage("One or more images exceed the file size limit");
            setShowError(true);
            setLoadingImage(false);
            return;
        }

        if (galleryImageCount + preparedData.length > 20) {
            setErrorMessage("Sorry, but you can't upload more than 20 files to gallery");
            setShowError(true);
            setLoadingImage(false);
            return;
        }

        const fileObjects = preparedData.map((f) => {
            return { originalName: f.name, filename: generateFilename(f.name.split(".").pop()) };
        });
        const filenames = fileObjects.map((f) => f.filename);

        AppService.getGalleryUploadURLs(filenames, AppService.edId).then((resp) => {
            const items = resp.data;
            preparedData.forEach((f) => {
                const generatedName = fileObjects.find((fO) => fO.originalName === f.name).filename;
                const item = items.find((i) => i.filename === generatedName);
                uploadGalleryItem(f, item.filename, item.uploadURL, uploadedFiles, preparedData.length);
            });
        });

        const formData = new FormData();

        for (const file of preparedData) {
            formData.append("files", file);
        }
    };

    const uploadGalleryItem = (file, filename, url, uploadedFiles, count) => {
        fetch(url, {
            method: "PUT",
            body: file
        }).then((resp) => {
            if (resp.status === 200) {
                uploadedFiles.push(filename);
                if (count && uploadedFiles.length === count) {
                    addGalleryImages(uploadedFiles);
                }
            }
        });
    };

    const addGalleryImages = (filenames) => {
        if (galleryId) {
            updateGallery(filenames);
        } else {
            createGallery(filenames);
        }
    };

    const updateGallery = (filenames) => {
        PhotoListService.addGalleryItem(galleryId, filenames).then((resp) => {
            setTimeout(() => {
                setGalleryList(resp.data);
                setLoadingImage(false);
                setDonor({ ...donor });
                setGalleryImageCount(galleryList.length);
            }, 6000);
        });
    };

    const createGallery = (filenames) => {
        PhotoListService.createGallery(donor.id, filenames).then((resp) => {
            setTimeout(() => {
                donor.userWithAnswerGallery = {
                    galleryItems: resp.data.galleryItems
                };
                donor.galleryId = resp.data.userAnswerId;
                setLoadingImage(false);
                setDonor({ ...donor });
                setGalleryImageCount(galleryList.length);
            }, 6000);
        });
    };

    const showConfirmPopup = (index) => {
        setShowModalPopup(true);
        setRemovedPhotoIndex(index);
    };

    const closeConfirmPopup = () => {
        setShowModalPopup(false);
        setShowSwapPopup(false);
        setRemovedPhotoIndex(null);
        setPhotoForSwap(null);
    };

    const removeGalleryPhoto = () => {
        PhotoListService.deleteGalleryItem(galleryId, galleryList[removedPhotoIndex].name).then(() => {
            delete galleryList[removedPhotoIndex];
            setDonor({ ...donor });
            setGalleryImageCount((prev) => prev - 1);
            setRemovedPhotoIndex(null);
            setShowModalPopup(false);
        });
    };

    const imageLoaded = (index) => {
        setLoadedImage((prev) => [...prev, index]);
        imageCounter.current += 1;
        if (imageCounter.current >= galleryList.length) {
            setLoadingImage(false);
        }
    };

    const name = className ? className : "";

    const onSwapPhoto = (item) => {
        setShowSwapPopup(true);
        setPhotoForSwap(item);
    };

    const swapGalleryPhoto = () => {
        PhotoListService.swapGalleryPhoto(donor.id, photoForSwap.name)
            .then((resp) => {
                onGalleryPhotoSwap(resp.data);
                setShowSwapPopup(false);
            })
            .catch((err) => {
                console.log(err);
                setShowSwapPopup(false);
            });
    };

    const onGalleryPhotoSwap = (newPhoto) => {
        setDonor({
            ...donor,
            photo: newPhoto
        });
    };

    const prepareImageDescription = (description) => {
        return description === "Image Description" && !isAdmin ? "" : description;
    };

    return (
        <div className={`photo-list-wrapper ${name}`}>
            {galleryList &&
                galleryList.map((item, index) => (
                    <div
                        className="photo-item"
                        key={index}
                        style={!loadedImage.includes(index) ? { display: "none" } : { display: "block" }}
                    >
                        <div className="image-wrapper">
                            <SecureImageComponent
                                name={item.url}
                                alt="Donors photo"
                                imageLoaded={imageLoaded}
                                imageIndex={index}
                                setLoadingImage={setLoadingImage}
                            />
                            {isAdmin && (
                                <div className="photo-actions">
                                    <img
                                        src={removePhoto}
                                        alt="remove"
                                        className="remove-gallery-photo"
                                        onClick={() => showConfirmPopup(index)}
                                    />
                                    <img src={swapPhoto} alt="swap" className="swap-gallery-photo" onClick={() => onSwapPhoto(item)} />
                                </div>
                            )}
                        </div>
                        <div className="photo-description">
                            {isAdmin && item.edit ? (
                                <input
                                    type="text"
                                    data-testid="edit-input"
                                    value={item.editedDescription?.length >= 0 ? item.editedDescription : item.description}
                                    onChange={(e) => handleChangeDescription(e, index)}
                                />
                            ) : (
                                <span>{prepareImageDescription(item.description)}</span>
                            )}
                            {isAdmin && (
                                <div className="admin-icons">
                                    {item.edit ? (
                                        <>
                                            <img src={cancelIcon} alt="edit" onClick={() => onCancelEdit(index)} />

                                            <img src={saveIcon} alt="edit" onClick={() => onSaveDescription(index)} />
                                        </>
                                    ) : (
                                        <img src={editIcon} alt="edit" onClick={() => onEditDescription(index)} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            {isAdmin && galleryAvailable && (
                <>
                    {showError && <FlyoutComponent message={errorMessage} type="error" onClose={() => setShowError(false)} />}
                    <img
                        src={addPhoto}
                        className={`add-photo ${loadingImage && galleryList?.length > 0 ? "add-photo-hidden" : ""} `}
                        alt="add-image"
                        onClick={() => photoUpload.current.click()}
                    />
                    <input
                        type="file"
                        className={"photo-admin-upload"}
                        accept="image/png, image/jpg, image/jpeg"
                        ref={photoUpload}
                        multiple={true}
                        onChange={(e) => uploadMultiplePhoto(e)}
                    />
                </>
            )}
            {loadingImage && galleryList?.length > 0 && <Spinner />}
            {showModalPopup && (
                <ModalPopupComponent onDialogClose={closeConfirmPopup}>
                    Are you sure to remove this photo?
                    <div className="modal-buttons">
                        <button className="cancel-remove" onClick={() => closeConfirmPopup()}>
                            No
                        </button>
                        <button className="confirm-remove" onClick={() => removeGalleryPhoto()}>
                            Yes
                        </button>
                    </div>
                </ModalPopupComponent>
            )}
            {showSwapPopup && (
                <ModalPopupComponent onDialogClose={closeConfirmPopup}>
                    Are you sure want to set this gallery photo as main photo?
                    <div className="modal-buttons">
                        <button className="cancel-remove" onClick={() => closeConfirmPopup()}>
                            No
                        </button>
                        <button className="confirm-remove" onClick={() => swapGalleryPhoto()}>
                            Yes
                        </button>
                    </div>
                </ModalPopupComponent>
            )}
        </div>
    );
}

export default PhotoList;
