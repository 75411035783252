import "./PreviousCyclesModal.scss";

function PreviousCyclesModal({ oldValue, newValue, compareCycles }) {
    const oldCycles = oldValue ? JSON.parse(oldValue) : [];
    const newCycles = newValue ? JSON.parse(newValue) : [];

    const cycleDifferences = compareCycles(oldCycles, newCycles);

    return (
        <div className="modalContainer">
            <h3 className="title">Changes in Previous Cycles</h3>
            {cycleDifferences.length === 0 ? (
                <p>No changes detected.</p>
            ) : (
                cycleDifferences.map((cycleDiff) => (
                    <div key={cycleDiff.cycle} className="cycleChangesContainer">
                        <h4 className="cycleTitle">Cycle {cycleDiff.cycle} Changes:</h4>
                        {cycleDiff.changes.map((change, index) => (
                            <p key={index} className="changeDetail">
                                <b>{change.key}:</b> From <span className="oldValue">{change.oldValue}</span> to{" "}
                                <span className="newValue">{change.newValue}</span>
                            </p>
                        ))}
                    </div>
                ))
            )}
        </div>
    );
}

export default PreviousCyclesModal;
