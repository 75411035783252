import React, { useContext } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthContext } from "../AuthContext";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function TransferSurveyRoute() {
    const authContext = useContext(AuthContext);
    let cookies = new Cookies();
    authContext.cleanToken();
    const query = useQuery();
    authContext.saveToken(query.get("j"));

    const currentLangParam = query.get("lang");
    if (currentLangParam && (currentLangParam === "es" || currentLangParam === "en")) {
        cookies.set("i18next", currentLangParam, {
            path: "/"
        });
    }

    return (
        <Route>
            <Redirect
                to={{
                    pathname: "/survey",
                    state: { from: query.get("from") }
                }}
            />
        </Route>
    );
}

export default TransferSurveyRoute;
