import React, { useContext, useEffect, useMemo, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import SurveyQuestionsStore from "@src/SurveyQuestionsStore";
import { AuthContext } from "../../AuthContext";

function PortalRoute({ children, ...rest }) {
    const authContext = useContext(AuthContext);
    const currentLocation = useLocation();
    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem("previousLocation");
        !authContext.user &&
            authContext.getUser().catch((error) => {
                if (error.response?.status === 401) {
                    localStorage.setItem("previousLocation", currentLocation.pathname);
                    history.push("/login");
                }
            });
    }, []);

    const check = (profile) => {
        return (
            (authContext.getToken() &&
                ((profile.role === "PARENT" && profile.portalStatus === "BASIC_ACCESS") || profile.portalStatus === "FULL_ACCESS")) ||
            profile.role === "ADMIN"
        );
    };

    const mainRoute = () => (
        <Route
            {...rest}
            render={({ location }) =>
                check(authContext.user) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
    return authContext.user && authContext.user.role === "ADMIN"
        ? mainRoute()
        : authContext.user && <SurveyQuestionsStore>{mainRoute()}</SurveyQuestionsStore>;
}

export default PortalRoute;
