import React from "react";
import "./VideoComponent.scss";
import play from "@resources/images/play.svg";
import close from "@resources/images/close-fullscreen.svg";

import videoEggDonnor from "@resources/video/conab_video_egg_donnor.mp4";
import videoParent from "@resources/video/conab_video_parent.mp4";
import videoSurrogate from "@resources/video/conab_video_surrogate.mp4";
import imgSurrogate from "@resources/images/GC_sign_in_image.png";
import imgParent from "@resources/images/IP_sign_in_image.jpeg";
import imgEggDonor from "@resources/images/ED_sign_in_image-new.jpg";
import imgSurrogateES from "@resources/images/GC_sign_in_image_ES.png";
import i18n from "i18next";

class VideoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullScreen: false,
            page: this.props.page
        };
        this.switchToFullScreen = this.switchToFullScreen.bind(this);
    }

    switchToFullScreen() {
        this.setState((state) => ({
            isFullScreen: !state.isFullScreen
        }));
    }

    componentWillReceiveProps(newProps) {
        this.setState(newProps);
    }

    selectImage() {
        switch (this.props.page) {
            case "surrogate":
                return i18n.language === "en" ? imgSurrogate : imgSurrogateES;
            case "parent":
                return imgParent;
            case "egg_donor":
                return imgEggDonor;
        }
        return null;
    }

    render() {
        let popup = null;
        let video = null;
        switch (this.state.page) {
            case "egg_donor":
                video = videoEggDonnor;
                break;
            case "parent":
                video = videoParent;
                break;
            case "surrogate":
                video = videoSurrogate;
                break;
            default:
                video = videoEggDonnor;
                break;
        }

        if (this.state.page) {
            popup = (
                <div className="video">
                    <div className="video-overlay">
                        <img src={this.selectImage()} alt="" className="video-element" />
                    </div>
                </div>
            );
        } else if (this.state.isFullScreen) {
            popup = (
                <div className="video-full-screen">
                    <img src={close} alt="" className="close-full-screen" onClick={this.switchToFullScreen} />
                    <div className="video-placeholder">
                        <video autoPlay={true} key={this.state.page} controls={true} muted={false} loop={true}>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </div>
            );
        } else {
            popup = (
                <div className="video">
                    <div className="video-overlay">
                        <div className="watch-video" onClick={this.switchToFullScreen}>
                            <img src={play} alt="" />
                            Watch Video
                        </div>
                    </div>
                    <video className="video-element" key={this.state.page} autoPlay={true} muted={true} loop={true}>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            );
        }
        return popup;
    }
}

export default VideoComponent;
