import React from "react";
import "./SpinnerLoader.scss";
import classNames from "classnames";

function SpinnerLoader({ destination }) {
    const getClassNamesForLoader = () => {
        return classNames("simple-spinner", destination);
    };
    return (
        <div className={getClassNamesForLoader()}>
            <span />
        </div>
    );
}

export default SpinnerLoader;
