import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import en from "@resources/locales/en/translation.json";
import es from "@resources/locales/es/translation.json";
import AppService from "./service/AppService/AppService";
import Cookies from "universal-cookie";

if (process.env.REACT_APP_ENV === "production") {
    disableReactDevTools();
}
const cookies = new Cookies();
i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        resources: {
            en: { translation: en },
            es: { translation: es }
        },
        lang: "en",
        fallbackLng: "en",
        interpolation: { escapeValue: false },
        supportedLngs: ["en", "es"],
        detection: {
            order: ["path", "querystring", "cookie", "path"],
            caches: ["cookie"]
        },
        debug: process.env.NODE_ENV === "development"
    });

const langFromPath = window.location.search.slice(window.location.search.length - 2) || cookies.get("i18next") || "en";
if (langFromPath === "en" || langFromPath === "es") {
    AppService.changeLanguageHandler(langFromPath);
}

ReactDOM.render(
    <Suspense fallback="Loading...">
        <App />
    </Suspense>,
    document.getElementById("root")
);
