import React from "react";
import "./MaintenancePage.scss";
import logo from "@resources/images/conceiveAbilities-logo.svg";

function MaintenancePage({ page }) {
    return (
        <div className="maintenance-container">
            <header className="left-column-header">
                <img src={logo} className="app-logo" alt="ConceiveAbilities" />
            </header>
            <div className={`maintenance-page ${page}`}>
                <h1>Sorry, we are under maintenance at the moment.</h1>
                <p>
                    We’re upgrading our application to become better for you and it will be unavailable for the next 1 hour. Please come
                    back later in order to submit the application.
                </p>
                <p> &mdash; The ConceiveAbilities Team</p>
            </div>
        </div>
    );
}

export default MaintenancePage;
