import React, { useState } from "react";
import "./AdminNotesComponent.scss";
import NoteItem from "./NoteItem";
import { createAdminNote, deleteAdminNote, updateAdminNote } from "../../../requests/AdminAPIRequests/adminAPIRequests";

function AdminNotesComponent({ adminNotes, setAdminNotes, donor }) {
    const [noteInput, setNoteInput] = useState("");

    const handleAddNote = async (e) => {
        e.preventDefault();
        if (noteInput.trim().length === 0) return;
        const body = {
            eggDonorId: donor.id,
            message: noteInput
        };
        const response = await createAdminNote(body);
        if (response.status === 200) {
            setAdminNotes([response.data, ...adminNotes]);
            setNoteInput("");
        }
    };

    const onDelete = (noteId) => {
        const updatedNotes = adminNotes.filter((note) => note.id !== noteId);
        setAdminNotes(updatedNotes);
        (async () => await deleteAdminNote(noteId))();
    };
    const toggleEdit = (noteId, boolean) => {
        const index = adminNotes.findIndex((note) => note.id === noteId);
        adminNotes[index].edit = boolean;
        setAdminNotes([...adminNotes]);
    };
    const onUpdateNote = async (noteId, noteInput) => {
        const body = String(noteInput);
        const response = await updateAdminNote(noteId, body);
        if (response.status === 200) {
            const index = adminNotes.findIndex((note) => note.id === noteId);
            adminNotes[index].message = noteInput;
            adminNotes[index].edit = false;
            setAdminNotes([...adminNotes]);
        }
    };

    return (
        <div className="notes-container">
            <form className="create-note-container" onSubmit={handleAddNote}>
                <textarea
                    className="note-input"
                    placeholder="Write a comment…"
                    value={noteInput}
                    onChange={(e) => setNoteInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            handleAddNote(e);
                        }
                    }}
                />
                <button type="submit" className="create-note-btn">
                    Send
                </button>
            </form>
            <div className="notes-list">
                {adminNotes.map((note, index) => (
                    <NoteItem key={index} note={note} onDelete={onDelete} onUpdateNote={onUpdateNote} toggleEdit={toggleEdit} />
                ))}
            </div>
        </div>
    );
}

export default AdminNotesComponent;
