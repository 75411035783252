import React from "react";
import SpinnerLoader from "../../../../../components/SpinnerLoader/SpinnerLoader";
import CalendarComponent from "../../../../../components/calendar/CalendarComponent";
import classNames from "classnames";

function CompensationEditor({ feeInfo, setFeeInfo, compensationValue, setCompensation }) {
    const handleChangeFee = ({ target: { name, value } }) => {
        const inputValue = prepareInputValue(value);

        setFeeInfo({
            ...feeInfo,
            [name]: inputValue ? inputValue : 0
        });
    };

    const prepareInputValue = (value) => {
        return value !== "0" ? value.replace(/^0+/, "") : value;
    };

    const handleChangeCompensation = ({ target: { value } }) => {
        const inputValue = prepareInputValue(value);
        setCompensation({ ...compensationValue, compensation: inputValue ? inputValue : 0 });
    };

    const handleChangeDate = (value) => {
        setCompensation({ ...compensationValue, compensationDate: value });
    };
    const handleChangeLockFee = (value) => {
        setCompensation({ ...compensationValue, lockFee: value });
    };

    if (!feeInfo && !compensationValue) {
        return <SpinnerLoader />;
    }
    const { compensationDate, compensation, lockFee } = compensationValue;

    const estimatedTotal =
        Object.entries(feeInfo)
            .filter(([key, _]) => key !== "id" && key !== "donorExpenseTravel")
            .reduce((total, [_, value]) => total + (value ? parseInt(value) : 0), 0) + parseInt(compensation);

    return (
        <div className="main-info compensation-block">
            <div className="main-info">
                <div className="edit-box">
                    <p>Compensation Amount</p>
                    <input
                        type="number"
                        name="amount"
                        data-testid="compensation"
                        value={compensation}
                        onChange={handleChangeCompensation}
                    />
                </div>
                <div className="edit-box">
                    <p>Date</p>
                    <CalendarComponent
                        answer={{ userAnswer: compensationDate || "", value: compensationDate || "" }}
                        onChange={handleChangeDate}
                    />
                </div>
                <div className="edit-box disabled-block">
                    <p>Estimated Total</p>
                    <input type="number" disabled value={estimatedTotal} />
                </div>
            </div>
            <div className="edit-box">
                <p>Lock Fee</p>
                <button className={classNames("yes_no", { selected: lockFee === "Yes" })} onClick={() => handleChangeLockFee("Yes")}>
                    Yes
                </button>
                <button className={classNames("yes_no", { selected: lockFee === "No" })} onClick={() => handleChangeLockFee("No")}>
                    No
                </button>
            </div>
            <p className="full-width sub-title">
                The next settings will apply to all egg donors - <span className="red">*</span>
            </p>
            <div className="edit-box">
                <p>
                    <span className="red">*</span>Professional Fee
                </p>
                <input type="number" name="professionalFee" value={feeInfo.professionalFee} onChange={handleChangeFee} />
            </div>
            <div className="edit-box">
                <p>
                    <span className="red">*</span>Escrow Fee
                </p>
                <input type="number" name="escrowFee" value={feeInfo.escrowFee} onChange={handleChangeFee} />
            </div>
            <div className="edit-box">
                <p>
                    <span className="red">*</span>Legal Fee
                </p>
                <input type="number" name="legalFee" data-testid="legalFee" value={feeInfo.legalFee} onChange={handleChangeFee} />
            </div>
            <div className="edit-box">
                <p>
                    <span className="red">*</span>Donor Expense Account for Local Donor Match
                </p>
                <input type="number" name="donorExpense" value={feeInfo.donorExpense} onChange={handleChangeFee} />
            </div>
            <div className="edit-box">
                <p>
                    <span className="red">*</span>Donor Expense Account for Remote Donor (If Travel Required)
                </p>
                <input type="number" name="donorExpenseTravel" value={feeInfo.donorExpenseTravel} onChange={handleChangeFee} />
            </div>
        </div>
    );
}

export default CompensationEditor;
