import React from "react";
import PasswordTextBoxComponent from "@components/passwordTextBox/PasswordTextBoxComponent";
import LiteCheckbox from "@components/liteCheckBox/LiteCheckBoxComponent";
import Overlay from "@components/overlay/OverlayComponent";
import ModalPopup from "@components/modalPopup/ModalPopupComponent";

import "./ChangePasswordComponent.scss";
import { AuthContext } from "../../AuthContext";

class ChangePasswordComponent extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            isFormValid: false,
            passwordError: false
        };
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.savePassword = this.savePassword.bind(this);
    }

    getPasswordInfo() {
        const password = this.state.newPassword;
        return {
            isLengthValid: password.length > 7,
            isContainUpperLetter: /[A-Z]/.test(password),
            isContainLowerLetter: /[a-z]/.test(password),
            isContainNumers: /[0-9]/.test(password)
        };
    }

    handleOldPasswordChange(oldPassword) {
        this.setState({ oldPassword });
    }

    handleNewPasswordChange(newPassword) {
        this.setState({ newPassword });
    }

    savePassword() {
        this.context.changePassword(this.state.oldPassword, this.state.newPassword).then((val) => {
            if (!val) {
                this.setState({ passwordError: true });
            } else {
                this.props.onSave();
            }
        });
    }

    isPasswordValid() {
        const passwordInfo = this.getPasswordInfo();
        return (
            passwordInfo.isContainLowerLetter &&
            passwordInfo.isContainNumers &&
            passwordInfo.isContainUpperLetter &&
            passwordInfo.isLengthValid
        );
    }

    validateForm = () => {
        return this.state.oldPassword.length > 0 && this.isPasswordValid();
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.savePassword();
    };
    render() {
        const isFormValid = this.validateForm();
        const passwordInfo = this.getPasswordInfo();

        return (
            <Overlay>
                <ModalPopup title="Change my password" onDialogClose={this.props.onDialogClose}>
                    <form onSubmit={this.handleFormSubmit} className="form">
                        <PasswordTextBoxComponent
                            error={this.state.passwordError}
                            errorText="Old password not found"
                            title="Enter your old password"
                            id="txtOldPassword"
                            onPasswordChange={this.handleOldPasswordChange}
                        />
                        <PasswordTextBoxComponent
                            title="Type your new password"
                            id="txtNewPassword"
                            onPasswordChange={this.handleNewPasswordChange}
                        />
                        <div className="password-description">
                            <span>Your password must:</span>
                            <span>
                                <LiteCheckbox isChecked={passwordInfo.isLengthValid} /> be at least <b>8 characters</b> long
                            </span>
                            <span>
                                <LiteCheckbox isChecked={passwordInfo.isContainUpperLetter} /> contain at least one <b>uppercase</b> letter
                            </span>
                            <span>
                                <LiteCheckbox isChecked={passwordInfo.isContainLowerLetter} /> contain at least one <b>lowercase</b> letter
                            </span>
                            <span>
                                <LiteCheckbox isChecked={passwordInfo.isContainNumers} /> contain at least one <b>number</b>.
                            </span>
                        </div>
                        {isFormValid ? (
                            <input type="submit" value="Change" className="button" />
                        ) : (
                            <input type="submit" value="Change" className="button disabled" />
                        )}
                    </form>
                </ModalPopup>
            </Overlay>
        );
    }
}

export default ChangePasswordComponent;
