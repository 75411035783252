import React from "react";
import AppService from "@service/AppService/AppService";
import { UserSurveyStatus } from "../../helper/Constants";
import {
    removeHiddenAnswerBySfdc,
    removeUserAnswerBySfdc,
    saveAnswerBySfdc,
    saveUserAnswer
} from "../../requests/surveyAPIRequests/surveyAPIRequests";
import {
    removeAdminUserAnswer,
    removeHiddenUserAnswer,
    saveAdminUserAnswer
} from "../../requests/AdminSurveyAPIRequests/AdminSurveyAPIRequests";
import { NumberAnswerService } from "../../service/NumberAnswerService/NumberAnswerService";
import { animateScroll as scroll } from "react-scroll";
import { showErrorNotification } from "../../helper/Notification/NotificationHelper";

export const surveyQuestionMethods = {
    isEdOrIpAccountType() {
        return ["egg_donor", "parent", "admin"].includes(AppService.accountType);
    },
    lastAnswerListName() {
        return this.isEdOrIpAccountType() ? "lastAnswersSelected" : "lastAnswerIds";
    },
    async setAnswer(topic, question, answer, sectionIndex) {
        if (question.type === "DYNAMIC_GROUP") {
            await this.setDynamicGroupAnswer(question, answer, sectionIndex);
            return;
        }

        if (this.shouldSkipAddressQuestion(question, answer)) {
            return;
        }

        const values = this.constructAnswerValues(question, answer);

        if (this.isEdOrIpAccountType()) {
            if (!this.isUploadQuestionType(question.type)) {
                const sfdcId = answer?.salesForceField?.id || null;
                values && values.length > 0
                    ? await this.saveAnswerWithSalesForceField(question, question.type, answer, values.join(", "))
                    : AppService.accountType === "admin"
                    ? await removeAdminUserAnswer(AppService.surveyId, question.id, sfdcId)
                    : await removeUserAnswerBySfdc(AppService.surveyId, question.id, sfdcId);
            }

            return question;
        }

        if (!this.isUploadQuestionType(question.type)) {
            await this.saveAnswer(question.id, values, question.type, sectionIndex);
        }
    },
    async saveAnswerWithSalesForceField(question, questionType, answer, answerValue) {
        let groupOrder = null;
        let groupName = null;
        let orderValue = question.index;
        if (question.groupId) {
            const groupQuestion = AppService.allQuestions[0].questions.find((q) => q.sequenceId === question.groupId);

            if (groupQuestion) {
                groupOrder = groupQuestion.index;
                groupName = groupQuestion.text;
                if (!orderValue) {
                    orderValue = groupQuestion.questions.findIndex((q) => q.sequenceId === question.sequenceId);
                }
            }
        }
        let scoreValue = answer.weight || 0;
        if (question.type === "DROPDOWN_MULTISELECT" || question.type === "MULTI_SELECT") {
            let sumScoring = 0;
            question.answers.forEach((a) => {
                if (question.lastAnswersSelected.includes(a.text)) {
                    sumScoring += a.weight || 0;
                }
            });
            scoreValue = sumScoring;
        }
        const sfdcFieldId = answer?.salesForceField?.id;
        const postObject = {
            surveyId: AppService.surveyId,
            questionId: question.id,
            sfdcFieldId: sfdcFieldId || null,
            value: answerValue,
            orderValue,
            groupId: question.groupId,
            groupOrder: groupOrder,
            groupName: groupName,
            scoreValue
        };
        AppService.accountType === "admin" ? await saveAdminUserAnswer(AppService.edId, postObject) : await saveAnswerBySfdc(postObject);
    },
    shouldSkipAddressQuestion(question, answer) {
        return !question.optional && answer.userAnswer === "" && question.type === "ADDRESS";
    },

    getSelectedValue(question, answer) {
        if (question.type === "YES_NO" && ["yes", "no"].includes(answer.text.toLowerCase())) {
            return answer.text.toLowerCase() === "yes" ? "Yes" : "No";
        }
        if (this.isMultiSelectQuestionType(question.type) || question.type === "DROPDOWN" || question.type === "YES_NO") {
            return question.lastAnswerIds?.includes(answer.id);
        }
        if (this.isTextBasedQuestionType(question.type)) {
            return answer.userAnswer ?? "";
        }
        return answer.text !== undefined && answer.sequenceId !== undefined;
    },
    getTextSelectedValue(question, answer) {
        if (question.type === "YES_NO" && ["yes", "no"].includes(answer.text.toLowerCase())) {
            return answer.text.toLowerCase() === "yes" ? "Yes" : "No";
        }
        if (this.isMultiSelectQuestionType(question.type) || question.type === "DROPDOWN" || question.type === "YES_NO") {
            return question[this.lastAnswerListName()]?.includes(answer.text) ? answer.text : "";
        }
        if (this.isTextBasedQuestionType(question.type)) {
            return answer.userAnswer ?? "";
        }
        return answer.text;
    },

    buildAnswerTextValue(question, answer, a, selectedValue) {
        if (this.isYesNoQuestionType(question.type)) {
            return answer.text === a.text ? answer.text : null;
        }
        if (
            this.isDropdownQuestionType(question.type) ||
            this.isSingleOneQuestionType(question.type) ||
            question.type === "SELECT_MANY" ||
            question.type === "NUMBER"
        ) {
            return answer.text === a.text ? selectedValue : null;
        }
        if (this.isAddressQuestionType(question.type) && answer.id === a.id) {
            return a.userAnswer;
        }
        return selectedValue || "";
    },

    constructAnswerValues(question, answer) {
        return question.answers
            .map((a) => {
                const selectedValue = this.isEdOrIpAccountType()
                    ? this.getTextSelectedValue(question, a)
                    : this.getSelectedValue(question, a);

                return this.isEdOrIpAccountType()
                    ? this.buildAnswerTextValue(question, answer, a, selectedValue)
                    : this.constructAnswerValue(question, answer, a, selectedValue);
            })
            .filter((value) => !!value);
    },

    constructAnswerValue(question, answer, a, selectedValue) {
        if (question.type === "SELECT_MANY" || question.type === "MULTI_SELECT") {
            return { answerId: a.id, value: selectedValue };
        }
        if (this.isYesNoQuestionType(question.type) && answer.id === a.id) {
            const answerText = typeof answer.text === "object" ? answer.text.EU : answer.text;
            return { answerId: a.id, value: answerText };
        }
        if ((this.isDropdownQuestionType(question.type) || this.isSingleOneQuestionType(question.type)) && answer.id === a.id) {
            return { answerId: a.id, value: selectedValue };
        }
        if (this.isAddressQuestionType(question.type) && answer.id === a.id) {
            return { answerId: a.id, value: a.userAnswer };
        }
        return answer.id === a.id && { answerId: a.id, value: selectedValue };
    },

    setLastAnswerId(question, answer) {
        if (["PHONE", "DATE"].includes(question.type)) {
            const answerIndex = question.answers.findIndex((a) => a.text === answer.text);
            if (answerIndex !== -1) {
                question.answers[answerIndex].userAnswer = answer.value;
            }
            delete answer.value;
        } else if (question.type === "NUMBER") {
            question[this.lastAnswerListName()] =
                answer.userAnswer?.length > 0 ? [this.isEdOrIpAccountType() ? answer.text : answer.id] : [];
        } else if (!["SELECT_MANY", "MULTI_SELECT", "DROPDOWN_MULTISELECT"].includes(question.type)) {
            question[this.lastAnswerListName()] = [this.isEdOrIpAccountType() ? answer.text : answer.id];
        } else {
            if (!question[this.lastAnswerListName()]) {
                question[this.lastAnswerListName()] = [this.isEdOrIpAccountType() ? answer.text : answer.id];
            } else if (question[this.lastAnswerListName()].includes(this.isEdOrIpAccountType() ? answer.text : answer.id)) {
                question[this.lastAnswerListName()] = question[this.lastAnswerListName()].filter(
                    (a) => a !== (this.isEdOrIpAccountType() ? answer.text : answer.id)
                );
            } else {
                question[this.lastAnswerListName()].push(this.isEdOrIpAccountType() ? answer.text : answer.id);
            }
        }
    },

    async setDynamicGroupAnswer(question, answer, sectionIndex) {
        const values = [{ value: JSON.stringify(answer) }];

        this.isEdOrIpAccountType()
            ? await this.saveAnswerWithSalesForceField(question, question.type, answer, JSON.stringify(answer))
            : await this.saveAnswer(question.id, values, question.type, sectionIndex);
    },

    async newSaveAnswer(questionId, questionType, answer, answerValue) {
        const postObject = {
            surveyId: AppService.surveyId,
            questionId: questionId,
            questionType: questionType,
            suggestedAnswerId: answer.id,
            scoreValue: answer.weight || 0,
            value: answerValue
        };
        await saveUserAnswer(postObject);
    },

    async saveAnswer(questionId, answerValues, questionType, lastSectionPassed) {
        let result = false;

        if (
            answerValues.some((a) => typeof a.value !== "string") &&
            answerValues.filter((a) => a.value.toString().trim().length === 0).length > 0
        ) {
            return result;
        }
        const postObject = {
            surveyId: AppService.surveyId,
            questionId: questionId,
            values: answerValues,
            questionType: questionType || null
        };
        if (questionType !== "DYNAMIC_GROUP") {
            const status = {
                userSurveyId: AppService.userSurveyId,
                status: AppService.userSurveyStatus,
                surveyScore: AppService.calculateSurveyScore(lastSectionPassed)
            };
            AppService.getAxios().put("/api/user/survey/status", status);
        }
        try {
            result = AppService.getAxios()
                .post("/api/user/answer", postObject)
                .catch((error) => {
                    this.showNotificationWithError(error);
                });
            const questions = AppService.allQuestions.flatMap((s) => s.questions);
            const groupQuestions = questions.filter((q) => q.type === "GROUP").flatMap((q) => q.questions);
            const allQuestions = [...questions, ...groupQuestions];
            const sourceQuestion = allQuestions.find((q) => q.id === questionId);
            const updatedHiddenQuestions = AppService.getAllHideQuestionIds(allQuestions);
            AppService.removeChainsItem(allQuestions, updatedHiddenQuestions, sourceQuestion);
        } catch (err) {
            result = false;
        }
        return result;
    },

    showNotificationWithError(error) {
        let message = typeof error === "string" ? error : error.message;
        if (error?.message?.includes("Network Error") || error?.message?.includes("timeout")) {
            message = "You are currently offline.  Please check your Internet connection and try again.";
        }
        showErrorNotification("Failed to Save", message);
    },

    isQuestionAnswered(question) {
        if (question.answers && question.answers.length === 0) {
            return true;
        }

        switch (question.type) {
            case "TEXT":
            case "UPLOAD":
            case "MULTI_UPLOAD": {
                const filtered = question.answers.filter((a) => {
                    if (a.userAnswer !== undefined) {
                        if (a.inputMask && a.inputMask.mask) {
                            return this.isInputMaskValid(a);
                        } else {
                            if (question?.salesForceField?.fieldName === "Email") {
                                const emailPattern =
                                    /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
                                return emailPattern.test(a.userAnswer);
                            } else {
                                const textPattern = /^[^.\s]/;
                                return a.userAnswer !== "{}" && a.userAnswer.length > 1 && textPattern.test(a.userAnswer);
                            }
                        }
                    }
                });
                return filtered.length > 0;
            }
            case "DATE":
            case "PHONE":
            case "ADDRESS": {
                return this.checkIfEmpty(question);
            }
            case "NUMBER": {
                return question.answers.every(NumberAnswerService.isAnswerValid);
            }
            default: {
                return question[this.lastAnswerListName()] && question[this.lastAnswerListName()].length > 0;
            }
        }
    },

    filterQuestions(questions) {
        let filteredQuestions = questions.filter((question) => this.isQuestionVisible(question, questions));
        let iterationNeeded;
        do {
            iterationNeeded = false;
            const newFilteredQuestions = [];

            filteredQuestions.forEach((question) => {
                if (this.isQuestionVisible(question, filteredQuestions)) {
                    newFilteredQuestions.push(question);
                } else {
                    iterationNeeded = true;
                }
            });

            filteredQuestions = newFilteredQuestions;
        } while (iterationNeeded);

        return filteredQuestions;
    },

    filterQuestionsIfNeeded(question, selectedTopic) {
        if (question.groupId) {
            selectedTopic.questions = selectedTopic.questions.map((group) => {
                return group.sequenceId === question.groupId ? { ...group, questions: this.filterQuestions(group.questions) } : group;
            });
        } else {
            selectedTopic.questions = this.filterQuestions(selectedTopic.questions);
        }
        selectedTopic.questions.forEach((question) => {
            if (
                (question.type === "GROUP" || question.type === "DYNAMIC_GROUP") &&
                question.questions &&
                Array.isArray(question.questions)
            ) {
                question.questions.sort((a, b) => a.index - b.index);
            }
        });

        selectedTopic.questions.sort((a, b) => a.index - b.index);
    },

    addHiddenQuestions(data, selectedTopic) {
        return data.forEach((newQuestion) => {
            const groupIndex = selectedTopic.questions.findIndex((q) => q.sequenceId === newQuestion.groupId);
            if (groupIndex !== -1) {
                const existingQuestionIndex = selectedTopic.questions[groupIndex].questions.findIndex(
                    (q) => q.sequenceId === newQuestion.sequenceId
                );
                if (existingQuestionIndex === -1) {
                    selectedTopic.questions[groupIndex].questions.splice(newQuestion.index, 0, newQuestion);
                }
            } else {
                const existingQuestionIndex = selectedTopic.questions.findIndex((q) => q.sequenceId === newQuestion.sequenceId);
                if (existingQuestionIndex === -1) {
                    selectedTopic.questions.splice(newQuestion.index, 0, newQuestion);
                }
            }
        });
    },

    isQuestionVisible(question, allQuestions) {
        if (!question.visibility || question.visibility.length === 0) {
            return true;
        }

        const visibilityResults = question.visibility.map((v) => {
            const parentQuestion = allQuestions.find((q) => q.sequenceId === v.sourceQuestion);
            if (!parentQuestion) {
                return false;
            }
            const visibilityAnswer = parentQuestion.answers.find((a) => a.sequenceId === v.sequenceId);
            return surveyQuestionMethods.isSpecificAnswerPresent(parentQuestion, visibilityAnswer);
        });

        const isVisible = visibilityResults.some((result) => result === true);
        if (!isVisible) {
            if (question.type === "GROUP") {
                question.questions.forEach((q) =>
                    AppService.accountType === "admin"
                        ? removeHiddenUserAnswer(AppService.edId, AppService.surveyId, q.id)
                        : removeHiddenAnswerBySfdc(q.id)
                );
            } else {
                AppService.accountType === "admin"
                    ? removeHiddenUserAnswer(AppService.edId, AppService.surveyId, question.id)
                    : removeHiddenAnswerBySfdc(question.id);
            }
        }

        return isVisible;
    },

    isSpecificAnswerPresent(question, specificAnswer) {
        if (!question.answers || question.answers.length === 0) {
            return false;
        }

        if (["TEXT", "UPLOAD", "MULTI_UPLOAD", "DATE", "PHONE", "ADDRESS", "NUMBER"].includes(question.type)) {
            return question.answers.some(
                (answer) => String(answer.userAnswer) && String(answer.userAnswer) === String(specificAnswer.userAnswer)
            );
        }

        return (
            question[this.lastAnswerListName()] && question[this.lastAnswerListName()].includes(specificAnswer.text || specificAnswer.id)
        );
    },
    getAnswerValue(question) {
        if (!question?.answers || question?.answers.length === 0) {
            return null;
        }

        switch (question.type) {
            case "TEXT":
            case "PHONE":
            case "ADDRESS":
            case "NUMBER":
                return question.answers[0].userAnswer ? String(question.answers[0].userAnswer) : null;

            case "UPLOAD":
            case "MULTI_UPLOAD":
                return question.answers[0].userAnswer ? "File Uploaded" : null;

            case "DATE":
                return question.answers[0].userAnswer ? String(question.answers[0].userAnswer) : null;
            default:
                if (!question[this.lastAnswerListName()] || question[this.lastAnswerListName()].length === 0) {
                    return null;
                }
                return question.answers
                    .filter((answer) => question[this.lastAnswerListName()].includes(answer.text))
                    .map((answer) => answer.text)
                    .join(", ");
        }
    },

    checkIfEmpty(question) {
        const emptyAnswers = question.answers.filter((answer) => !answer.userAnswer || answer.userAnswer === "");
        return emptyAnswers.length === 0;
    },

    isInputMaskValid(answer) {
        if (answer.userAnswer === undefined || answer.userAnswer.length === 0) {
            return false;
        }
        return this.isValueValidByMask(answer.userAnswer, answer.inputMask.mask);
    },

    isValueValidByMask(value, mask) {
        const maskRegexp = mask
            .replace(/\\0/gi, "null@v@lue")
            .replace(/\(/gi, "\\(")
            .replace(/\)/gi, "\\)")
            .replace(/\\a/gi, "@@Letter")
            .replace(/\\\*/gi, "St@rrCh@r@cter")
            .replace(/0/gi, "\\d")
            .replace(/a/gi, "\\w")
            .replace(/\*/gi, ".")
            .replace(/St@rrCh@r@cter/gi, "\\*")
            .replace(/@@Letter/gi, "a")
            .replace(/null@v@lue/gi, "0");

        const regexp = new RegExp("^" + maskRegexp + "$");
        return value.match(regexp) !== null;
    },

    scrollSurveyToBottom() {
        scroll.scrollToBottom({
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            containerId: "survey-container"
        });
    },

    isMultiSelectQuestionType(type) {
        return ["SELECT_MANY", "MULTI_SELECT", "DROPDOWN_MULTISELECT"].includes(type);
    },

    isTextBasedQuestionType(type) {
        return ["UPLOAD", "MULTI_UPLOAD", "ADDRESS", "TEXT", "NUMBER", "DATE", "PHONE"].includes(type) || this.isUploadQuestionType();
    },

    isUploadQuestionType(type) {
        return ["UPLOAD", "MULTI_UPLOAD"].includes(type);
    },

    isYesNoQuestionType(type) {
        return type === "YES_NO";
    },

    isDropdownQuestionType(type) {
        return type === "DROPDOWN";
    },

    isSingleOneQuestionType(type) {
        return type === "SINGLE_ONE";
    },

    isAddressQuestionType(type) {
        return type === "ADDRESS";
    }
};

export const fillFriendBuyData = (authContext) => {
    const user = authContext.user;
    const friendBuy = (window.friendbuy = window.friendbuy || []);
    friendBuy.push([
        "track",
        "order",
        {
            id: user.id,
            email: user.email
        }
    ]);
};

export const fillExtoleData = (authContext) => {
    const user = authContext.user;
    const extole = window.extole;
    extole.createZone({
        name: "application_submitted",
        data: {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            partner_user_id: user.id,
            application_id: AppService.surveyId
        }
    });
};

export const fillGtmAnalyticsData = (accountType, surveyFinishedStatus) => {
    const formStatus = surveyFinishedStatus === UserSurveyStatus.COMPLETED ? "Accepted" : "Rejected";
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    if (accountType === "surrogate") {
        const isSubmitEventAlreadyPushed = dataLayer.some((value) => value.event === "surrogate_initial_app_form_submit");
        if (!isSubmitEventAlreadyPushed) {
            dataLayer.push({
                event: "surrogate_initial_app_form_submit",
                attributes: {
                    form_status: formStatus
                }
            });
        }
    } else if (accountType === "parent") {
        dataLayer.push({
            event: prepareEventName(AppService.parsedQuestions),
            attributes: {
                form_status: formStatus
            }
        });
    }
};

export const prepareEventName = (sections) => {
    let name = "";
    sections.forEach((section) => {
        section.questions.forEach((question) => {
            if (question.type === "SINGLE_ONE") {
                const answer = question.answers.find((a) => a.id === question.lastAnswerIds[0]);
                switch (answer.text) {
                    case "An egg donor":
                    case "Egg donor":
                    case "Egg donors":
                        name = "intended_parent_egg_donor_initial_app_form_submit";
                        break;
                    case "A surrogate":
                        name = "intended_parent_surrogate_initial_app_form_submit";
                        break;
                    case "Both":
                        name = "intended_parent_both_initial_app_form_submit";
                        break;
                }
            }
        });
    });
    return name;
};
