import React, { useContext, useEffect, useState } from "react";
import SurveyProfileAside from "../../surveyProfile/SurveyProfileAside/SurveyProfileAside";
import Layout from "@components/appLayout/AppLayoutComponent";
import SectionDropdownComponent from "../../../components/admin/sectionDropdown/SectionDropdownComponent";
import "./QuestionEditor.scss";
import SalesforceFieldsList from "../salesforceFields/SalesforceFieldsList";
import SpinnerLoader from "../../../components/SpinnerLoader/SpinnerLoader";
import { AuthContext } from "../../../AuthContext";
import { useHistory } from "react-router-dom";
import { getAllSections as getAllSectionsAPI, getAllSalesforceFields } from "../../../requests/AdminAPIRequests/adminAPIRequests";

function QuestionEditor() {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [sections, setSections] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedSection, setSelectedSection] = useState("");
    const [selectedSalesforceFields, setSelectedSalesforceFields] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (!user || user?.role !== "ADMIN") {
            history.push("/donors");
        }
    }, [user]);

    useEffect(() => {
        getAllSections();
    }, []);

    const getAllSections = () => {
        getAllSectionsAPI().then((resp) => {
            setSections([...resp.data]);
        });
    };

    const getSalesforceFieldsBySection = (sectionName) => {
        setShowLoader(true);
        setSelectedSalesforceFields([]);
        if (sectionName === "") {
            resetDefaults();
        } else {
            setSelectedSection(sectionName);
            setSearchText("");
            getSalesforceFields(sectionName, "");
        }
    };

    const getSalesforceFieldsBySearchValue = (searchValue) => {
        setSelectedSalesforceFields([]);
        setShowLoader(true);
        getSalesforceFields(selectedSection, searchValue);
    };

    const getSalesforceFields = (sectionName, searchValue) => {
        getAllSalesforceFields(sectionName, searchValue).then((resp) => {
            setSelectedSalesforceFields(resp.data);
            setShowLoader(false);
        });
    };

    const resetDefaults = () => {
        setSelectedSalesforceFields([]);
        setSelectedSection("");
        setSearchText("");
        setShowLoader(false);
    };

    const applySearchFilter = (event) => {
        const searchValue = event.target.value;
        setSearchText(searchValue);
        if (searchValue.length >= 3) {
            getSalesforceFieldsBySearchValue(searchValue);
        }
        if (searchValue.length === 0) {
            getSalesforceFieldsBySection(selectedSection);
        }
    };

    const noDataContent = () => {
        return (
            <div className="salesforce-loader">
                <div className="salesforce-no-data">{showLoader ? <SpinnerLoader /> : noDataText()}</div>
            </div>
        );
    };

    const noDataText = () => {
        return selectedSection !== ""
            ? "Looks like there no results, related to current search value."
            : "Please, select the section to load salesforce fields for it";
    };

    return (
        <Layout
            infiniteScroll={true}
            left={<SurveyProfileAside />}
            center={
                <div className="questions-editor">
                    <h2 className="questions-editor-title">Questions Editor</h2>
                    <div className="questions-editor-section-dropdown">
                        <SectionDropdownComponent sections={sections} onSelect={getSalesforceFieldsBySection} />
                    </div>
                    {selectedSection !== "" && (
                        <div className="questions-editor-search">
                            <div className="questions-search-field">
                                <label className="search-label">Search Salesforce Field</label>
                                <input
                                    value={searchText}
                                    className="search"
                                    onChange={applySearchFilter}
                                    placeholder="Start write a content of salesforce field to filter the list"
                                />
                            </div>
                        </div>
                    )}
                    {selectedSection !== "" && selectedSalesforceFields?.length > 0 ? (
                        <SalesforceFieldsList sectionName={selectedSection} fields={selectedSalesforceFields} />
                    ) : (
                        noDataContent()
                    )}
                </div>
            }
            right={null}
            showRightColumn={false}
        />
    );
}

export default QuestionEditor;
