export const unansweredQuestions = {
    questions: {},

    addQuestion: function (sectionId, questionId, optional = false) {
        if (sectionId && questionId) {
            this.questions[sectionId] = this.questions[sectionId] || new Set();
            if (optional) {
                this.questions[sectionId].add(`${questionId}_optional`);
            } else {
                this.questions[sectionId].add(questionId);
            }
        }
    },
    isAllQuestionsAnswered: function () {
        for (const sectionId in this.questions) {
            if (Object.prototype.hasOwnProperty.call(this.questions, sectionId)) {
                const unansweredInSection = this.questions[sectionId];
                for (const questionId of unansweredInSection) {
                    if (!questionId.endsWith("_optional")) {
                        return false;
                    }
                }
            }
        }
        return true;
    },
    isAllQuestionsAnsweredExceptSection: function (ignoredSectionId) {
        for (const sectionId in this.questions) {
            if (Object.prototype.hasOwnProperty.call(this.questions, sectionId)) {
                if (sectionId === ignoredSectionId) {
                    continue;
                }

                const unansweredInSection = this.questions[sectionId];
                for (const questionId of unansweredInSection) {
                    if (!questionId.endsWith("_optional")) {
                        return false;
                    }
                }
            }
        }
        return true;
    },

    containsQuestion: function (sectionId, questionId) {
        const unansweredInSection = this.questions[sectionId];
        if (unansweredInSection && questionId) {
            return unansweredInSection.has(questionId);
        } else {
            return false;
        }
    },

    removeQuestion: function (sectionId, questionId) {
        if (sectionId && questionId && this.questions[sectionId]) {
            this.questions[sectionId].delete(questionId);
            this.questions[sectionId].delete(`${questionId}_optional`);
        }
    },

    numberOfUnansweredQuestions: function (sectionId) {
        const unansweredInSection = this.questions[sectionId];
        let count = unansweredInSection ? unansweredInSection.size : 0;
        if (unansweredInSection) {
            unansweredInSection.forEach((questionId) => {
                if (questionId.endsWith("_optional")) {
                    count--;
                }
            });
        }
        return count;
    },
    getFirstUnansweredQuestion: function (sectionId, withOptional = false) {
        if (this.questions[sectionId]) {
            const unansweredInSection = this.questions[sectionId];
            let firstUnansweredQuestion = unansweredInSection[Symbol.iterator]().next().value;
            if (!withOptional && firstUnansweredQuestion && firstUnansweredQuestion.endsWith("_optional")) {
                unansweredInSection.forEach((questionId) => {
                    if (!questionId.endsWith("_optional")) {
                        firstUnansweredQuestion = questionId;
                        return;
                    }
                });
                if (firstUnansweredQuestion.endsWith("_optional")) {
                    return null;
                }
            }
            return firstUnansweredQuestion && firstUnansweredQuestion.replace(/_optional$/, "");
        }
    },
    getSectionsWithUnansweredQuestions: function () {
        let sectionsWithUnanswered = [];

        for (const sectionId in this.questions) {
            if (Object.prototype.hasOwnProperty.call(this.questions, sectionId)) {
                const unansweredInSection = this.questions[sectionId];

                for (const questionId of unansweredInSection) {
                    if (!questionId.endsWith("_optional")) {
                        sectionsWithUnanswered.push(sectionId);
                        break;
                    }
                }
            }
        }

        return sectionsWithUnanswered;
    },

    clear: function () {
        this.questions = {};
    }
};
