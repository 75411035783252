import React, { useCallback, useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { getTranslatedTextIfNeeded } from "../../../../../shared/InternationalizationTextUtils";

function TextQuestionComponent({ question, answer, onAnswer, selectedTopic, isLiteSurvey }) {
    const [isMounted, setIsMounted] = useState(false);
    const [value, setValue] = useState(answer.userAnswer || "");

    const handleInputChange = (event) => {
        let inputValue = event.target.value;
        if (isLiteSurvey && isMounted) {
            inputValue = question.text.toLowerCase()?.includes("email") ? event.target.value : inputValue.replace(/[^A-Za-z\s]/g, "");
            answer.userAnswer = inputValue;
            onAnswer(selectedTopic, question, answer);
        }

        setValue(inputValue.trimStart());
    };

    useEffect(() => {
        if (!isLiteSurvey && isMounted) {
            const timer = setTimeout(() => {
                if (value !== answer.userAnswer) {
                    answer.userAnswer = value;
                    onAnswer(selectedTopic, question, answer);
                }
            }, 400);
            return () => clearTimeout(timer);
        }
        setIsMounted(true);
    }, [value]);

    const inputId = `masked-${answer.sequenceId}`;
    if (answer.inputMask && answer.inputMask.mask) {
        return (
            <div key={inputId}>
                <p className="masked-input-label">{getTranslatedTextIfNeeded(answer.text)}</p>
                <IMaskInput
                    id={inputId}
                    mask={answer.inputMask.mask}
                    lazy={false}
                    value={value}
                    type="text"
                    className="masked"
                    placeholderChar="_"
                    onAccept={(value) => handleInputChange({ target: { value } })}
                />
            </div>
        );
    } else {
        return (
            <input
                key={inputId}
                type="text"
                placeholder={getTranslatedTextIfNeeded(answer.text)}
                value={value}
                onChange={(event) => handleInputChange(event)}
            />
        );
    }
}

export default TextQuestionComponent;
