import React from "react";
import Hint from "@components/hint/HintComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import "./CheckBoxComponent.scss";
import { getFeedbackWithQuestion } from "@shared/Feedback";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";

function CheckBox({ selectedTopic, question, onAnswer, showOverlay, className, readonly, isDynamicGroup }) {
    let feedbackItems = [];

    const answers = question.answers.map((a, i) => {
        feedbackItems = getFeedbackWithQuestion(question, a, i, feedbackItems);
        const answersField = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "lastAnswersSelected" : "lastAnswerIds";
        const answerIdOrText = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "text" : "id";
        const isChecked = question[answersField]?.includes(a[answerIdOrText]);
        const selectedClassName = isChecked ? "checkbox-container checkbox-selected" : "checkbox-container";
        return (
            <label className={selectedClassName} key={i}>
                {getTranslatedTextIfNeeded(a.text)}
                <input
                    type="checkbox"
                    checked={isChecked}
                    name="checkbox"
                    onChange={() => {
                        onAnswer(selectedTopic, question, a);
                        getFeedbackWithQuestion(question, a, i, feedbackItems);
                    }}
                />
                <span className="checkmark" />
            </label>
        );
    });

    return (
        <div>
            <div className={`survey-question ${className}`}>
                {readonly ? <div className="answer-overlay" /> : null}
                {showOverlay ? <div className="survey-overlay" /> : null}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <div className="content">{answers}</div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
}

export default CheckBox;
