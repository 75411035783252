import React, { useEffect } from "react";
import close from "@resources/images/close.svg";
import "./ModalPopupComponent.scss";
import OverlayComponent from "../overlay/OverlayComponent";
import classNames from "classnames";

function ModalPopupComponent({ className, title, onDialogClose, children }) {
    useEffect(() => {
        window.addEventListener("click", handleOutsideClick);

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        const modal = document.querySelector(".modal-dialog");
        if (modal && !modal.contains(event.target)) {
            onDialogClose();
        }
    };

    return (
        <OverlayComponent>
            <div className={classNames("modal-dialog", className)}>
                <div className="header">
                    <div className="title">{title}</div>
                    <div className="close">
                        <img src={close} alt="close" onClick={onDialogClose} />
                    </div>
                </div>
                <div className="content">{children}</div>
            </div>
        </OverlayComponent>
    );
}

export default ModalPopupComponent;
