import React from "react";
import { AuthContext } from "./AuthContext";
import { SurveyQuestionsContext } from "./SurveyQuestionsStore";

export const AuthAndQuestionContext = React.createContext(null);
const ProvideAuthAndQuestionContext = (props) => {
    return (
        <AuthContext.Consumer>
            {(authContext) => (
                <SurveyQuestionsContext.Consumer>
                    {(questionContext) => (
                        <AuthAndQuestionContext.Provider value={{ authContext, questionContext }}>
                            {props.children}
                        </AuthAndQuestionContext.Provider>
                    )}
                </SurveyQuestionsContext.Consumer>
            )}
        </AuthContext.Consumer>
    );
};
export default ProvideAuthAndQuestionContext;
