import React from "react";
import { useHistory } from "react-router-dom";

import "./RegistrationConfirmationPopupComponent.scss";
import emailConfirm from "@resources/images/email_confirm.svg";
import ModalPopupComponent from "../modalPopup/ModalPopupComponent";

function RegistrationConfirmationPopupComponent({ navigateTo, onDialogClose, title, email }) {
    const history = useHistory();

    return (
        <ModalPopupComponent onDialogClose={onDialogClose}>
            <div className="email-image">
                <img src={emailConfirm} alt="" />
            </div>
            <h1>{title}</h1>
            <div>
                <p>
                    We have sent an email to <span className="link">{email}</span> to confirm the validity of your email address. After
                    receiving the email follow the link to complete your registration.
                </p>
                <button onClick={() => history.push(`/${navigateTo}`)}>Continue</button>
            </div>
        </ModalPopupComponent>
    );
}

export default RegistrationConfirmationPopupComponent;
