import AppService from "@service/AppService/AppService";

export const getTranslatedTextIfNeeded = (obj = "") => {
    if (typeof obj !== "string") {
        obj = obj.toString();
    }

    const lang = AppService.lang;
    let translatedValue;

    try {
        translatedValue = JSON.parse(obj);
    } catch (e) {
        translatedValue = obj;
    }

    if (typeof translatedValue !== "string") {
        if (lang && lang === "es") {
            return translatedValue.ESP ? translatedValue.ESP : obj;
        } else {
            return translatedValue.EU ? translatedValue.EU : obj;
        }
    } else {
        return translatedValue;
    }
};
