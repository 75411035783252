import React, { useContext } from "react";
import QuestionsList from "@components/questionsList/QuestionsListComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import "./GroupComponent.scss";
import QuestionElements from "../questionsList/QuestionElements/QuestionElements";
import { AuthContext } from "../../AuthContext";
import AppService from "../../service/AppService/AppService";

function Group({ selectedTopic, question, onAnswer, showOverlay, showUnansweredWarning, updateProgress }) {
    const { user } = useContext(AuthContext);
    const isEdOrIpUser = ["EGG_DONOR", "PARENT"].includes(user.role);
    return (
        <div className="question-container">
            {showOverlay ? <div className="survey-overlay" /> : null}
            <div className="survey-question group-section">
                <div className="question-title">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="content">
                    {isEdOrIpUser ? (
                        <div
                            className={`group-question-container survey-questions-container survey-questions-container-${AppService.accountType}`}
                        >
                            <QuestionElements
                                questions={question.questions || []}
                                selectedTopic={selectedTopic}
                                onAnswer={onAnswer}
                                showUnansweredWarning={showUnansweredWarning}
                                updateProgress={() => {}}
                                isDynamicGroup={false}
                                dynamicQuestion={null}
                                instanceId={null}
                            />
                        </div>
                    ) : (
                        <QuestionsList
                            unansweredWarning={showUnansweredWarning}
                            questions={question.questions}
                            selectedTopic={selectedTopic}
                            onAnswer={(selectedTopic, question, a) => {
                                onAnswer(selectedTopic, question, a);
                            }}
                            isGroup={true}
                            updateProgress={updateProgress}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Group;
