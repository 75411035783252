import React from "react";
import "./FeedbackComponent.scss";

function FeedbackComponent({ feedback }) {
    return (
        <div className="question-container">
            <div className="survey-question">
                <div className="survey-review">
                    <h1>{feedback.title}</h1>
                    <p>{feedback.text}</p>
                </div>
            </div>
        </div>
    );
}

export default FeedbackComponent;
