import React from "react";

import "./ApplicationProgressComponent.scss";
import Crown from "@resources/images/crown.svg";
import Add from "@resources/images/edit-pic.svg";
import AddGold from "@resources/images/edit-pic_gold.svg";
import AddPink from "@resources/images/edit-pic_pink.svg";
import Timeline from "@components/timeline/TimelineComponent";

import AppService from "@service/AppService/AppService";
import { Redirect } from "react-router-dom";
import SurveyNavigation from "@components/surveyNavigation/SurveyNavigationComponent";
import DropDownComponent from "../dropDowns/dropDown/DropDownComponent";
import { t } from "i18next";
import { AuthContext } from "../../AuthContext";

class ApplicationProgressComponent extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            redirectToProfile: false,
            image: this.context?.user?.avatarURL,
            questions: [],
            selectedTopic: { questions: [] },
            accountType: this.props.accountType
        };
        this.navigateToSurvey = this.navigateToSurvey.bind(this);
    }

    componentWillMount() {
        this.setState({ image: this.context.user?.avatarURL });
    }

    navigateToSurvey() {
        this.setState({ redirectToSurvey: true });
    }

    getSurveyNavigation(list, selected) {
        if (list.length > 0) {
            return (
                <SurveyNavigation
                    questions={list}
                    selectedTopic={selected}
                    onClick={(selectedTopic) => {
                        AppService.selectedTopic = selectedTopic;
                        this.navigateToSurvey();
                    }}
                />
            );
        }
        return null;
    }

    render() {
        if (this.state.redirectToProfile) {
            return <Redirect to="/profile" push={true} />;
        }

        const survey = { id: 1, title: "Application", description: "" };

        const questions = {
            surrogate: [survey],
            egg_donor: [survey],
            parent: null
        };

        if (this.context.user?.portalStatus) {
            questions.parent = [survey, { id: 2, title: "Matching", description: "" }];
        } else {
            questions.parent = [survey];
        }

        let addImage = Add;

        if (this.context.user?.role === "PARENT") {
            addImage = AddGold;
        }

        if (this.context.user?.role === "SURROGATE") {
            addImage = AddPink;
        }

        const timeLineCssClass = AppService.isAppStarted ? "selected" : "";
        const questionsList = questions[this.context.user?.role.toLowerCase()] ?? [];

        if (this.state.redirectToSurvey) {
            return <Redirect to="/survey" push={true} />;
        }

        let items = [{ text: "English" }, { text: "Espanol" }];
        let selectedLanguage = AppService.lang === "en" ? { text: "English" } : { text: "Espanol" };

        const changeLanguage = (lang) => {
            if (lang === "English") {
                AppService.changeLanguageHandler("en", this.props.history);
            }
            if (lang === "Espanol") {
                AppService.changeLanguageHandler("es", this.props.history);
            }
        };

        let accountType = this.state.accountType;
        if (!accountType) {
            accountType = AppService.accountType;
        }

        return (
            <div className="app-progress-left-column">
                {accountType && accountType === "surrogate" ? (
                    <div className="survey-language-selector">
                        {AppService.lang !== "en" && AppService.showLanguageToggle && (
                            <DropDownComponent
                                label={"Language"}
                                isLanguageDropdown={true}
                                isSurveyFinished={true}
                                items={items}
                                selectedItem={selectedLanguage}
                                placeholder={t("Select")}
                                onChange={(lang) => changeLanguage(lang.text)}
                            />
                        )}
                    </div>
                ) : (
                    accountType && (
                        <div className="profile-info" onClick={() => this.setState({ redirectToProfile: true })}>
                            <div>
                                <img src={Crown} alt="" />
                            </div>
                            <div className="profile-image">
                                <img src={addImage} className="add-image" alt="" />
                                {this.context.user?.avatarURL ? (
                                    <img className="user-avatar" src={this.context.user.avatarURL} alt="" />
                                ) : (
                                    <span>{this.context.getShortName()}</span>
                                )}
                            </div>
                            <div className="profile-name">
                                {this.context.user?.firstName} {this.context.user?.lastName}
                            </div>
                        </div>
                    )
                )}
                <Timeline
                    title="Journey"
                    className={timeLineCssClass}
                    questions={questionsList}
                    disabled={true}
                    selectedTopic={questionsList[0]}
                />
                {accountType === "egg_donor" && (
                    <div className="app-survey-timeline information-wrapper">
                        <div className="app-survey-timeline-header">Information</div>
                        <ul>
                            <li
                                className="long-timeline-container"
                                onClick={() =>
                                    window.open("https://www.conceiveabilities.com/egg-donors/egg-donor-requirements/", "_blank")
                                }
                            >
                                Egg Donor requirements & FAQ
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

export default ApplicationProgressComponent;
