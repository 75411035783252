import React from "react";
import Hint from "@components/hint/HintComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import "./RadioComponent.scss";
import classNames from "classnames";
import { getFeedbackForRadio } from "@shared/Feedback";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";

function Radio({ selectedTopic, question, onAnswer, showOverlay, className, readonly, isDynamicGroup }) {
    let feedbackItems = [];

    const answers = question.answers.map((a, i) => {
        const answersField = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "lastAnswersSelected" : "lastAnswerIds";
        const answerIdOrText = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "text" : "id";
        const isChecked = question[answersField]?.includes(a[answerIdOrText]);
        feedbackItems = getFeedbackForRadio(question, a, feedbackItems);
        return (
            <label className={classNames("radio-container", isChecked && "radio-selected")} key={i}>
                {getTranslatedTextIfNeeded(a.text)}
                <input
                    type="radio"
                    name="radio"
                    checked={isChecked}
                    onChange={() => {
                        getFeedbackForRadio(question, a, feedbackItems);
                        onAnswer(selectedTopic, question, a);
                    }}
                />
                <span className="checkmark" />
            </label>
        );
    });

    return (
        <div>
            <div className={`survey-question ${className}`}>
                {readonly ? <div className="answer-overlay" /> : null}
                {showOverlay ? <div className="survey-overlay" /> : null}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <div className="content">{answers}</div>
                </div>
                <Hint hit={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
}

export default Radio;
