import React, { useState } from "react";
import AppService from "@service/AppService/AppService";
import Hint from "@components/hint/HintComponent";
import PhoneInput from "react-phone-input-2";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedback } from "@shared/Feedback";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames";
import { surveyQuestionMethods } from "../../../../shared/Questions/Questions";

function PhoneComponent({ selectedTopic, question, onAnswer, showOverlay, readonly, className, isDynamicGroup }) {
    const [wasFull, setWasFull] = useState(false);
    const userAnswer = AppService.surveyId && !isDynamicGroup ? "value" : "userAnswer";

    const setValue = (value, answerId) => {
        const answerIndex = question.answers.findIndex((a) => a.id === answerId);
        question.answers[answerIndex][userAnswer] = value;
        AppService.updateProgress = true;
    };

    const handlePhoneChange = (phone, country, answer) => {
        const formatLength = country.format.split("").filter((char) => char === ".").length;
        const isMaxInputLength = phone.length > 11;

        if (phone.length === 1) {
            setValue("", answer.id);
            onAnswer(selectedTopic, question, answer);
            return;
        }
        if (isMaxInputLength) {
            getFeedback(answer);
            setValue(phone, answer.id);
            onAnswer(selectedTopic, question, answer);
            return;
        }
        if (phone && phone.length > formatLength) {
            setWasFull(true);
            return;
        }

        if (phone && phone.length !== formatLength) {
            setWasFull(false);
        } else if (!wasFull) {
            getFeedback(answer);
            setValue(phone, answer.id);
            onAnswer(selectedTopic, question, answer);
            setWasFull(true);
        }
    };

    const buildPhoneInput = (answer, index) => {
        const isEdOrIp = surveyQuestionMethods.isEdOrIpAccountType();
        const regions = isEdOrIp ? ["america", "europe", "asia", "oceania", "africa"] : ["america"];
        const onlyCountries = isEdOrIp ? [] : ["us"];
        return (
            <div className="phone-container" key={index}>
                <PhoneInput
                    country={"us"}
                    value={answer?.userAnswer || "+1"}
                    onChange={(phone, country) => handlePhoneChange(phone, country, answer)}
                    countryCodeEditable={isEdOrIp}
                    disableDropdown={!isEdOrIp}
                    regions={regions}
                    onlyCountries={onlyCountries}
                />
            </div>
        );
    };

    const feedbackItems = [];
    const answers = question.answers.map((a, i) => {
        const feedback = getFeedback(a);
        if (feedback) {
            feedback.forEach((f) => feedbackItems.push(f));
        }
        return buildPhoneInput(a, i);
    });

    return (
        <div>
            <div className={classNames("survey-question", className)}>
                {readonly && <div className="answer-overlay" />}
                {showOverlay && <div className="survey-overlay" />}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <div className="content">{answers}</div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
}

export default PhoneComponent;
