import React, { useContext } from "react";
import "./SurveyProfile.scss";
import EggDonorListComponent from "../../../components/EggDonorListComponent/EggDonorListComponent";
import { AuthContext } from "../../../AuthContext";
import { getDonorsList } from "../../../requests/EggDonorListRequests/EggDonorListRequests";

function SurveyProfile() {
    const { user } = useContext(AuthContext);

    const oneLoadCount = 12;
    const loadItems = (number, body, count = oneLoadCount) => getDonorsList(number, user?.id, count, body);

    return <EggDonorListComponent loadItems={loadItems} oneLoadCount={oneLoadCount} />;
}

export default SurveyProfile;
