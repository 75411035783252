import React from "react";
import "./CareTeamComponent.scss";

class CareTeamComponent extends React.Component {
    render() {
        return <div className="care-team" />;
    }
}

export default CareTeamComponent;
