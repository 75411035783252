import AppService from "../../service/AppService/AppService";

export async function saveSurveyLocation(subSurveyIndex = 0, sectionIndex = 0, questionIndex = 0) {
    return await AppService.getAxios().post(
        `/api/user-index/save?subSurveyIndex=${subSurveyIndex}&sectionIndex=${sectionIndex}&questionIndex=${questionIndex}`
    );
}

export async function getDynamicGroupById(questionId) {
    return AppService.getAxios().get(`/api/new-user-answer/dynamic/${questionId}`);
}

export async function getSurveyLocation() {
    return await AppService.getAxios().get(`/api/user-index/current`);
}
export async function resetSurveyLocation() {
    return await AppService.getAxios().get(`/api/user-index/finished`);
}
export async function getSurveyDataForUser() {
    return await AppService.getAxios().get("/api/new-survey/get/survey-data");
}
export async function getUserSection(surveyId, subSeq, sectionSeq) {
    return await AppService.getAxios().get(`/api/new-survey/get/survey/${surveyId}/sub/${subSeq}/section/${sectionSeq}`);
}
export async function getHiddenQuestions() {
    return await AppService.getAxios().put("/api/new-survey/get/hidden");
}
export async function saveUserAnswer(body) {
    return await AppService.getAxios().post("/api/new-survey/answer/save", body);
}
export async function saveAnswerBySfdc(body) {
    return await AppService.getAxios().post("/api/new-user-answer/save", body);
}
export async function removeUserAnswer(surveyId, questionId, answerId) {
    return await AppService.getAxios().delete(`/api/new-survey/answer/${surveyId}/question/${questionId}/answer/${answerId}`);
}
export async function removeUserAnswerBySfdc(surveyId, questionId, salesForceId) {
    const params = salesForceId ? `?sfdcFieldId=${salesForceId}` : "";
    return await AppService.getAxios().delete(`/api/new-user-answer/${surveyId}/question/${questionId}/remove${params}`);
}
export async function loadHiddenQuestions(body) {
    return await AppService.getAxios().put(`/api/new-survey/get/hidden`, body);
}
export async function removeHiddenAnswer(questionId) {
    return await AppService.getAxios().delete(`/api/user/answer/${AppService.surveyId}/hidden/${questionId}/remove`);
}
export async function removeHiddenAnswerBySfdc(questionId) {
    return await AppService.getAxios().delete(`/api/new-user-answer/${AppService.surveyId}/question/${questionId}/remove/hidden`);
}
export async function saveSinglePhoto(body) {
    return await AppService.getAxios().post("/api/new-user-answer/save/photo", body);
}
export async function removeSinglePhoto(surveyId, questionId) {
    return await AppService.getAxios().delete(`/api/new-user-answer/${surveyId}/question/${questionId}/remove/photo`);
}
export async function saveMultiplePhoto(body) {
    return await AppService.getAxios().post(`/api/new-user-answer/save/gallery`, body);
}
export async function removeGalleryMultiplePhoto(surveyId, questionId, filename) {
    return await AppService.getAxios().delete(
        `/api/new-user-answer/${surveyId}/question/${questionId}/remove/gallery-item?filename=${filename}`
    );
}

export async function finishSurvey(surveyId, isLastSubSurvey, subIndex, sectionIndex) {
    return await AppService.getAxios().get(
        `/api/user/survey/finish/${surveyId}?last=${isLastSubSurvey}&subIndex=${subIndex}&sectionIndex=${sectionIndex}`
    );
}
