import React, { useEffect, useState } from "react";
import { animateScroll as scroll, Link } from "react-scroll";
import { unansweredQuestions } from "@shared/AnswersTracker/AnswersTracker";
import "./UnansweredNavigationComponent.scss";
import { useTranslation } from "react-i18next";
import { surveyQuestionMethods } from "../../../shared/Questions/Questions";

function UnansweredNavigationComponent({ sectionId, accountType, isLoading }) {
    const { t } = useTranslation();
    const [countOfUnansweredQuestions, setCountOfUnansweredQuestions] = useState(0);

    useEffect(() => {
        surveyQuestionMethods.scrollSurveyToBottom();
    }, []);

    useEffect(() => {
        setCountOfUnansweredQuestions(unansweredQuestions.numberOfUnansweredQuestions(sectionId));
    }, [unansweredQuestions.questions, unansweredQuestions?.questions[sectionId], isLoading]);

    const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(sectionId);
    const pluralEnding = countOfUnansweredQuestions > 1 ? "s" : "";

    if (!countOfUnansweredQuestions) {
        return null;
    }

    return (
        <Link
            className={`scroll-link scroll-link-${accountType}`}
            containerId="survey-container"
            to={firstUnansweredQuestion}
            smooth="easeInOutQuart"
        >
            {`${t("You must answer")} ${countOfUnansweredQuestions} ${t("question")}${pluralEnding}`}
        </Link>
    );
}

export default UnansweredNavigationComponent;
