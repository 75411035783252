import React from "react";
import "./ResetPasswordComponent.scss";

import ConfirmationPopup from "@components/registrationConfirmationPopup/RegistrationConfirmationPopupComponent";
import AccountSecurityLayout from "@components/accountSecurityLayout/AccountSecurityLayoutComponent";
import Flyout from "@components/flyout/FlyoutComponent";
import AppService from "@service/AppService/AppService";

class ResetPasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isFormValid: false,
            showConfirmationPopup: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.doReset = this.doReset.bind(this);
    }

    doReset = () => {
        const { email } = this.state;
        if (this.validateForm()) {
            this.setState({ showProgress: true });
            AppService.getAxios()
                .post("/auth/password/forgot", {
                    email: email
                })
                .then((response) => {
                    this.setState({
                        showProgress: false,
                        showError: "",
                        showConfirmationPopup: true
                    });
                })
                .catch(() => {
                    this.setState({
                        showProgress: false,
                        showError: "Email address not found",
                        showConfirmationPopup: false
                    });
                });
        }
    };

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    validateForm = () => {
        return this.isEmailValid();
    };

    isEmailValid = () => {
        const { email } = this.state;
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            email
        );
    };

    resetPassword = () => {
        const { email } = this.state;
        if (this.validateForm()) {
            this.props.onSubmit({
                email
            });
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.doReset();
    };
    render() {
        const { showConfirmationPopup, email } = this.state;
        const isFormValid = this.validateForm();
        const isEmailValid = this.state.email.length > 0 && !this.isEmailValid();
        const emailFieldClassName = isEmailValid ? "field-error" : "";

        return (
            <AccountSecurityLayout>
                {this.state.showProgress ? <div className={`animated-gradient`}></div> : null}
                {this.state.showError ? <Flyout type="error" message={this.state.showError} onClose={() => {}}></Flyout> : null}
                <h1>Reset password</h1>
                <p className="security-form-description">
                    Enter the email associated with your account and we’ll send an email with password reset link
                </p>
                <form onSubmit={this.handleFormSubmit} className="form">
                    <div className="form-item">
                        <label htmlFor="lblEmail">Email</label>
                        <input
                            type="text"
                            id="lblEmail"
                            name="email"
                            className={emailFieldClassName}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        {isEmailValid ? <label className="form-error">Email address not found</label> : ""}
                    </div>

                    <div className="login-link">
                        <a className="link" href="/app/login">
                            Wait, I remember my password
                        </a>
                    </div>

                    {isFormValid ? (
                        <input type="submit" value="Reset password" className="button" />
                    ) : (
                        <input type="submit" value="Reset password" className="button disabled" />
                    )}
                </form>
                {showConfirmationPopup && (
                    <ConfirmationPopup
                        title="Reset password link sent"
                        navigateTo="app/login"
                        email={email}
                        onDialogClose={() =>
                            this.setState({
                                showConfirmationPopup: false
                            })
                        }
                    />
                )}
            </AccountSecurityLayout>
        );
    }
}

export default ResetPasswordComponent;
