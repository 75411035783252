import AppService from "../AppService/AppService";
import { unansweredQuestions } from "../../shared/AnswersTracker/AnswersTracker";

export const DynamicQuestionService = {
    isOneInstanceHasAnswers(question, questionContext, selectedTopicId) {
        let allHiddenQuestionsForThisQuestion = {};
        if (question.optional) {
            unansweredQuestions.addQuestion(selectedTopicId, question.sequenceId, true);
        }

        const createdInstances = questionContext?.dynamicQuestions.find((q) => q.id === question.id)?.createdInstances;
        let isInstanceFilled = false;

        for (let i = 0; i < createdInstances?.length; i++) {
            let instance = createdInstances[i];
            const hiddenQuestions = this.getHiddenDynamicQuestions(createdInstances, question);
            allHiddenQuestionsForThisQuestion[instance.instanceId] = hiddenQuestions[instance.instanceId];

            let allResponsesFilled = true;

            for (let j = 0; j < instance.groupWithAnswers.length; j++) {
                let currentQuestion = instance.groupWithAnswers[j];

                if (hiddenQuestions[instance.instanceId]?.includes(currentQuestion.sequenceId)) {
                    continue;
                }
                if (currentQuestion.answers.length === 0) {
                    continue;
                }

                if (this.isQuestionHaveTextAnswer(currentQuestion.type)) {
                    if (!currentQuestion?.answers[0].userAnswer || currentQuestion?.answers[0].userAnswer === "") {
                        allResponsesFilled = false;
                    }
                } else {
                    if (!currentQuestion.lastAnswersSelected || currentQuestion.lastAnswersSelected.length === 0) {
                        allResponsesFilled = false;
                    }
                }
            }

            if (allResponsesFilled) {
                isInstanceFilled = true;
            }
        }
        AppService.hiddenDynamicQuestions[question.id] = allHiddenQuestionsForThisQuestion;
        return isInstanceFilled;
    },

    getHiddenDynamicQuestions(dynamicQuestions, mainQuestion) {
        const hiddenQuestionsPerInstance = {};

        dynamicQuestions.forEach((instance) => {
            let hiddenQuestionsForThisInstance = [];

            instance.groupWithAnswers.forEach((question) => {
                if (this.shouldHideQuestion(question, instance, mainQuestion)) {
                    hiddenQuestionsForThisInstance.push(question.sequenceId);
                }
            });

            hiddenQuestionsPerInstance[instance.instanceId] = hiddenQuestionsForThisInstance;
        });

        return hiddenQuestionsPerInstance;
    },

    shouldHideQuestion(question, instance, mainQuestion) {
        const allQuestions = instance.groupWithAnswers;
        if (!question.visibility || question.visibility.length === 0) {
            return false;
        }

        for (let condition of question.visibility) {
            const sourceQuestion = allQuestions.find((q) => q.sequenceId === condition.sourceQuestion);
            if (
                AppService?.hiddenDynamicQuestions[mainQuestion?.id] &&
                AppService?.hiddenDynamicQuestions[mainQuestion?.id][instance?.instanceId]?.includes(condition.sourceQuestion)
            ) {
                return true;
            }
            if (!sourceQuestion) {
                continue;
            }

            const sourceAnswer = sourceQuestion.answers.find((ans) => ans.sequenceId === condition.sequenceId);

            if (this.isQuestionHaveTextAnswer(sourceQuestion.type)) {
                if (!sourceAnswer.userAnswer || sourceAnswer.userAnswer.trim() === "") {
                    return true;
                }
            } else if (!sourceAnswer || !sourceQuestion.lastAnswersSelected.includes(sourceAnswer.text)) {
                return true;
            }
        }

        return false;
    },

    isQuestionHaveTextAnswer(type) {
        return type === "TEXT" || type === "NUMBER" || type === "DATE" || type === "PHONE" || type === "ADDRESS";
    }
};
