import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    getDonorAddresses,
    getDonorCompensation,
    getDonorCyclesInfo,
    getDonorFeeInfo,
    getDonorPriorInfo,
    getPdfForAdmin,
    getPdfForClinics,
    getPdfForParent,
    updateDonorSummary
} from "../../../../requests/AdminAPIRequests/adminAPIRequests";
import { showErrorNotification, showSuccessNotification } from "../../../../helper/Notification/NotificationHelper";
import classNames from "classnames";
import SpinnerLoader from "../../../../components/SpinnerLoader/SpinnerLoader";
import "./AdminSummaryEditor.scss";
import { States } from "../../../../helper/Constants";
import MultiSelectDropDownComponent from "../../../../components/multiSelectDropDown/MultiSelectDropDownComponent";
import { cloneDeep } from "lodash";
import QuestionnaireComponent from "./QuestionnaireComponent/QuestionnaireComponent";
import pdfIcon from "../../../../resources/images/print.svg";
import AppService from "../../../../service/AppService/AppService";
import CompensationEditor from "./CompensationEditor/CompensationEditor";

function AdminSummaryEditor({ basicInfo, setBasicInfo }) {
    const { integrationId } = useParams();
    const id = AppService.edId;

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [address, setAddress] = useState(null);
    const [priorInfo, setPriorInfo] = useState(null);
    const [cyclesInfo, setCyclesInfo] = useState({});
    const [feeInfo, setFeeInfo] = useState(null);
    const [compensation, setCompensation] = useState({});
    const [updatedAddress, setUpdatedAddress] = useState([]);
    const [updatedPriorInfo, setUpdatedPriorInfo] = useState([]);
    const [updatedBasicInfo, setUpdatedBasicInfo] = useState({});
    const [updatedFeeInfo, setUpdatedFeeInfo] = useState({});
    const [updatedCompensation, setUpdatedCompensation] = useState({});
    const [isCyclesInfoUpdated, setIsCyclesInfoUpdated] = useState(false);

    const [isLoading, setIsLoading] = useState({
        admin: false,
        clinics: false,
        parent: false
    });

    useEffect(() => {
        getDonorData();
    }, []);

    useEffect(() => {
        setUpdatedBasicInfo({ ...basicInfo });
    }, [basicInfo]);

    const getDonorData = async () => {
        const [addressesResp, priorInfoResp, feeInfoResp, compensationResp, cyclesResp] = await Promise.all([
            getDonorAddresses(id),
            getDonorPriorInfo(id),
            getDonorFeeInfo(id),
            getDonorCompensation(id),
            getDonorCyclesInfo(id)
        ]);

        const { data: addressesData } = addressesResp;
        const { data: priorInfoData } = priorInfoResp;
        const { data: feeInfoData } = feeInfoResp;
        const { data: compensationData } = compensationResp;
        const { data: cyclesData } = cyclesResp;

        setAddress(addressesData);
        setUpdatedAddress(cloneDeep(addressesData));

        setPriorInfo(priorInfoData);
        setUpdatedPriorInfo(cloneDeep(priorInfoData));

        setFeeInfo(feeInfoData);
        setUpdatedFeeInfo(cloneDeep(feeInfoData));

        setCompensation(compensationData || {});
        setUpdatedCompensation(compensationData || {});

        setCyclesInfo({
            ...cyclesData,
            userValue: cyclesData.userValue ? JSON.parse(cyclesData.userValue) : null
        });
    };

    const handleChangeBasicDonorInfo = ({ target }) => {
        const uiKey = target.name;
        const value = target.value;
        setUpdatedBasicInfo((prevInfo) => ({ ...prevInfo, [uiKey]: value }));
    };

    const handleInfoChange = (info, setInfo, sfdcKey, value) => {
        const index = info.findIndex((item) => item.sfdcKey === sfdcKey);
        if (index !== -1) {
            const newInfo = [...info];
            newInfo[index] = { ...newInfo[index], userValue: value };
            setInfo(newInfo);
        }
    };

    const handleChangeCity = useCallback(
        ({ target }) => {
            handleInfoChange(updatedAddress, setUpdatedAddress, "ao_donor_location__c", target.value);
        },
        [updatedAddress, handleInfoChange]
    );

    const handleChangeState = useCallback(
        (selectedStates) => {
            const value = selectedStates.length > 0 ? selectedStates[0].name : "";
            handleInfoChange(updatedAddress, setUpdatedAddress, "ao_donor_state__c", value);
        },
        [updatedAddress, handleInfoChange]
    );

    const handleChangePriorInfo = useCallback(
        ({ target }) => {
            handleInfoChange(updatedPriorInfo, setUpdatedPriorInfo, "ao_donorcycles_notes__c", target.value);
        },
        [updatedPriorInfo, handleInfoChange]
    );

    const handleChangePriorDonations = useCallback(
        (selectedPrior) => {
            const value = selectedPrior.length > 0 ? selectedPrior[0].text : "";
            handleInfoChange(updatedPriorInfo, setUpdatedPriorInfo, "ao_donorcycles__c", value);
        },
        [updatedPriorInfo, handleInfoChange]
    );

    const transformKeyForBackend = (key) => {
        const keyMap = {
            firstName: "FIRST_NAME",
            lastName: "LAST_NAME",
            email: "EMAIL"
        };
        return keyMap[key] || key;
    };

    const onSaveChanges = async () => {
        const fieldsToUpdate = Object.entries(updatedBasicInfo).reduce((acc, [key, value]) => {
            if (updatedBasicInfo[key] !== basicInfo[key]) {
                acc.push({ key: transformKeyForBackend(key), value });
            }
            return acc;
        }, []);

        const body = {
            basicFields: fieldsToUpdate,
            addressFields: getAdminChangesFromArray(address, updatedAddress),
            priorFields: getAdminChangesFromArray(priorInfo, updatedPriorInfo),
            allCycles: getAllCyclesChanges(),
            fee: Object.keys(feeInfo).some((key) => feeInfo[key] !== updatedFeeInfo[key]) ? updatedFeeInfo : null,
            compensation: getAdminChanges(compensation.compensation, updatedCompensation.compensation),
            lockFee: getAdminChanges(compensation.lockFee, updatedCompensation.lockFee),
            compensationDate: getAdminChanges(compensation.compensationDate, updatedCompensation.compensationDate)
        };

        try {
            await updateDonorSummary(id, body);
            showSuccessNotification(`Donor info was successfully updated`);
            setIsSubmitted(true);
            setTimeout(() => setIsSubmitted(false), 2000);
            setBasicInfo({ ...updatedBasicInfo });
            setAddress(cloneDeep(updatedAddress));
            setPriorInfo(cloneDeep(updatedPriorInfo));
            setIsCyclesInfoUpdated(false);
            setFeeInfo(cloneDeep(updatedFeeInfo));
            setCompensation(updatedCompensation);
        } catch (e) {
            if (e?.response?.data) {
                showErrorNotification("Failed to save", e.response.data.message);
            }
        }
    };

    const getAllCyclesChanges = () => {
        if (cyclesInfo?.userValue?.every((dataObject) => Object.values(dataObject).every((value) => !value || value?.length === 0))) {
            return { ...cyclesInfo, userValue: "" };
        }
        return cyclesInfo.userValue ? { ...cyclesInfo, userValue: JSON.stringify(cyclesInfo.userValue) } : null;
    };

    const hasChanges = () => {
        return (
            (basicInfo && Object.keys(basicInfo).some((key) => basicInfo[key] !== updatedBasicInfo[key])) ||
            (feeInfo && Object.keys(feeInfo).some((key) => feeInfo[key] !== updatedFeeInfo[key])) ||
            (compensation && Object.keys(compensation).some((key) => compensation[key] !== updatedCompensation[key])) ||
            (address && getAdminChangesFromArray(address, updatedAddress).length > 0) ||
            (priorInfo && getAdminChangesFromArray(priorInfo, updatedPriorInfo).length > 0) ||
            isCyclesInfoUpdated
        );
    };

    const getAdminChanges = (value, updatedValue) => {
        if (value !== updatedValue) return updatedValue;
        return null;
    };

    const getAdminChangesFromArray = (initialArray, updatedArray) => {
        let changes = [];

        if (initialArray.length !== updatedArray.length) {
            return updatedArray;
        }

        for (let i = 0; i < initialArray.length; i++) {
            const initialItem = initialArray[i];
            const updatedItem = updatedArray[i];

            if (initialItem.userValue !== updatedItem.userValue) {
                changes.push(updatedItem);
            }
        }

        return changes;
    };

    const pdfFetchFunctions = {
        admin: getPdfForAdmin,
        clinics: getPdfForClinics,
        parent: getPdfForParent
    };

    const getPdfInfo = async (userType) => {
        const fetchPdf = pdfFetchFunctions[userType];
        if (fetchPdf) {
            try {
                setIsLoading((prevState) => ({ ...prevState, [userType]: true }));
                const pdfUrl = await fetchPdf(id);
                openUrl(pdfUrl.data);
            } catch (e) {
                showErrorNotification("PDF build failed", e.response.data.message);
                setIsLoading((prevState) => ({ ...prevState, [userType]: false }));
            } finally {
                setIsLoading((prevState) => ({ ...prevState, [userType]: false }));
            }
        }
    };

    const openUrl = (url) => {
        const isSafari = AppService.isSafari();
        const target = isSafari ? "_self" : "_blank";
        const windowReference = window.open(url, target);

        if (isSafari && windowReference) {
            windowReference.location = url;
        }
    };

    const renderPdfContainer = (userType, text) => (
        <div className="pdf-container" onClick={() => getPdfInfo(userType)}>
            {isLoading[userType] ? (
                <SpinnerLoader />
            ) : (
                <>
                    <img src={pdfIcon} alt="" />
                    <p className="pdf-text">{text}</p>
                </>
            )}
        </div>
    );

    if (!basicInfo) return null;
    const currentBasicInfo = updatedBasicInfo || basicInfo;

    return (
        <div className="summary-container">
            <div className="basic-info-header">
                <h3 className="donor-id">{integrationId}</h3>
                <div className="sticky-save-button-container">
                    <button
                        type="submit"
                        className={classNames("save-changes", { disabled: !hasChanges(), shake: hasChanges() })}
                        disabled={!hasChanges()}
                        onClick={onSaveChanges}
                    >
                        <svg
                            className={`checkmark ${isSubmitted ? "show" : ""}`}
                            width="19"
                            height="14"
                            viewBox="0 0 19 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.4999 11.1996L2.2999 6.99961L0.899902 8.39961L6.4999 13.9996L18.4999 1.99961L17.0999 0.599609L6.4999 11.1996Z"
                                fill="#816F4B"
                            />
                        </svg>
                        <span className="button-text">{isSubmitted ? "Saved" : "Save"}</span>
                    </button>
                </div>
            </div>
            <div className="main-info-container">
                <div className="main-info">
                    <div className="edit-box">
                        <p>First Name</p>
                        <input
                            type="text"
                            name="firstName"
                            value={currentBasicInfo?.firstName || ""}
                            onChange={handleChangeBasicDonorInfo}
                        />
                    </div>
                    <div className="edit-box">
                        <p>Last Name</p>
                        <input type="text" name="lastName" value={currentBasicInfo?.lastName || ""} onChange={handleChangeBasicDonorInfo} />
                    </div>
                    <div className="edit-box">
                        <p>Email</p>
                        <input type="text" name="email" value={currentBasicInfo?.email || ""} onChange={handleChangeBasicDonorInfo} />
                    </div>
                </div>
                <h4 className="block-title">These fields are for administrative use only.</h4>
                <div className="main-info">
                    {updatedAddress ? (
                        updatedAddress.map((item) => (
                            <div className="edit-box" key={item.sfdcKey}>
                                <p>{item.label}</p>
                                {item.sfdcKey === "ao_donor_state__c" ? (
                                    <MultiSelectDropDownComponent
                                        placeholder={""}
                                        displayValue="name"
                                        onChange={handleChangeState}
                                        selected={item.userValue ? [item.userValue] : null}
                                        showArrow={true}
                                        items={States}
                                        selectionLimit={1}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        data-testid="city"
                                        name="city"
                                        value={item?.userValue || ""}
                                        onChange={handleChangeCity}
                                    />
                                )}
                            </div>
                        ))
                    ) : (
                        <SpinnerLoader />
                    )}
                </div>
                <h4 className="block-title">Prior Donations</h4>
                <div className="main-info column">
                    {updatedPriorInfo ? (
                        updatedPriorInfo.map((item) => (
                            <div className="edit-box" key={item.sfdcKey}>
                                <p>{item.label}</p>
                                {item.sfdcKey === "ao_donorcycles__c" ? (
                                    <MultiSelectDropDownComponent
                                        placeholder={""}
                                        onChange={handleChangePriorDonations}
                                        selected={item.userValue ? [item.userValue] : null}
                                        showArrow={true}
                                        items={[
                                            { text: "0" },
                                            { text: "1" },
                                            { text: "2" },
                                            { text: "3" },
                                            { text: "4" },
                                            { text: "5" },
                                            { text: "6" }
                                        ]}
                                        selectionLimit={1}
                                    />
                                ) : (
                                    <textarea
                                        data-testid="priorInfo"
                                        name="priorInfo"
                                        value={item?.userValue || ""}
                                        onChange={handleChangePriorInfo}
                                    />
                                )}
                            </div>
                        ))
                    ) : (
                        <SpinnerLoader />
                    )}
                </div>
                <h4 className="block-title">Previous Cycles</h4>
                {cyclesInfo ? (
                    <QuestionnaireComponent
                        cyclesInfo={cyclesInfo}
                        setCyclesInfo={setCyclesInfo}
                        setIsCyclesInfoUpdated={setIsCyclesInfoUpdated}
                    />
                ) : (
                    <SpinnerLoader />
                )}
                <h4 className="block-title">Egg Donor Compensation</h4>
                <CompensationEditor
                    feeInfo={updatedFeeInfo}
                    setFeeInfo={setUpdatedFeeInfo}
                    compensationValue={updatedCompensation}
                    setCompensation={setUpdatedCompensation}
                />
                <h4 className="block-title">Print views</h4>
                <div className="main-info pdf-space">
                    {renderPdfContainer("admin", "For admin")}
                    {renderPdfContainer("clinics", "For clinics")}
                    {renderPdfContainer("parent", "For parents")}
                </div>
            </div>
        </div>
    );
}

export default AdminSummaryEditor;
