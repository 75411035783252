import React from "react";

import AppService from "@service/AppService/AppService";
import { SalesforceFieldService } from "@service/salesforceField/SalesforceFieldService";
import { createBrowserHistory } from "history";
import { getSurveyLocation, resetSurveyLocation, saveSurveyLocation } from "./requests/surveyAPIRequests/surveyAPIRequests";
import { unansweredQuestions } from "./shared/AnswersTracker/AnswersTracker";
import { AuthContext } from "./AuthContext";
import { UserSurveyStatus } from "./helper/Constants";
import { QuestionListService } from "./service/QuestionListService/QuestionListService";
import { surveyQuestionMethods } from "./shared/Questions/Questions";

export const SurveyQuestionsContext = React.createContext(null);

class SurveyQuestionsStore extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            questions: null,
            allSubSurveySections: null,
            subSurveys: null,
            dynamicQuestions: [],
            currentSubSurvey: AppService.accountType === "surrogate" && !this.context?.isSurveyFinished() ? 1 : 0,
            finishedSurveys: AppService.accountType === "surrogate" && !this.context?.isSurveyFinished() ? [0] : [],
            showSurveys: true,
            lastSectionPassed: 0,
            indexesActive: false,
            isSurveyLoaded: false,
            accountType: AppService.accountType,
            stopScrolling: false
        };
    }

    componentDidMount() {
        let history = createBrowserHistory();
        this.waitForUserContext()
            .then(() => {
                const fetchData = () => {
                    if (this.context.user?.role === "PARENT" || this.context.user?.role === "EGG_DONOR") {
                        this.setState({
                            questions: [{}]
                        });
                        this.fetchDynamicGroups();
                    } else {
                        this.fetchStandardData(history);
                    }
                };

                if (!this.context.isSurveyFinished()) {
                    this.setSurveyLocation().then(fetchData);
                } else {
                    fetchData();
                }
            })
            .catch((error) => {
                console.error("Error waiting for user context:", error);
            });
    }

    waitForUserContext = () => {
        return new Promise((resolve) => {
            const checkContext = () => {
                if (this.context.user?.role) {
                    resolve();
                } else {
                    setTimeout(checkContext, 100);
                }
            };
            checkContext();
        });
    };

    fetchStandardData = (history) => {
        const currentSubSurvey = AppService.accountType === "surrogate" && !this.context?.isSurveyFinished() ? 1 : 0;
        SalesforceFieldService.retrieveSpecialSfdcFieldNames()
            .then(() => AppService.getQuestions(history, currentSubSurvey))
            .then((val) => {
                if (val) {
                    this.setState({
                        currentSubSurvey: currentSubSurvey,
                        finishedSurveys: AppService.accountType === "surrogate" && !this.context?.isSurveyFinished() ? [0] : [],
                        questions: val.updatedSubSurveysSections[currentSubSurvey],
                        allSubSurveySections: val.updatedSubSurveysSections,
                        subSurveys: val.subSurveys,
                        showSurveys: [UserSurveyStatus.COMPLETED, UserSurveyStatus.REJECTED, UserSurveyStatus.INITIAL_REJECTED].includes(
                            val.userSurveyStatus
                        )
                    });
                }
            })
            .then(() => {
                if (this.context.user?.role !== "PARENT" && this.context.user?.role !== "EGG_DONOR" && this.context.user.role !== "ADMIN") {
                    this.fetchDynamicGroups();
                }
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            AppService.accountType === "surrogate" &&
            this.state.currentSubSurvey === 0 &&
            AppService.accountType !== prevState.accountType
        ) {
            this.setState({
                currentSubSurvey: 1,
                finishedSurveys: [0],
                accountType: AppService.accountType
            });
        }
    }
    fetchDynamicGroups = async () => {
        const response = await QuestionListService.getDynamicGroups(this.context.user?.id);
        response.data.forEach((group) => {
            group && this.setDynamicQuestions({ ...group, createdInstances: JSON.parse(group.createdInstances) });
        });
    };

    setSurveyLocation = async () => {
        const response = await getSurveyLocation();
        const location = response.data;
        if (location.indexesActive) {
            const { subSurveyIndex, sectionIndex, questionIndex } = location;
            this.setState({
                currentSubSurvey: subSurveyIndex,
                lastSectionPassed: sectionIndex,
                questionIndex: questionIndex,
                indexesActive: location.indexesActive
            });
            location.subSurveyIndex > 0 && this.setFinishedSurveysByIndex(subSurveyIndex);
        } else {
            await this.updateSurveyLocation(this.state.currentSubSurvey, this.state.lastSectionPassed);
        }
    };

    setFinishedSurveysByIndex(index) {
        const finishedSurveys = [];
        for (let i = 0; i < index; i++) {
            finishedSurveys.push(i);
        }
        this.setState({ finishedSurveys });
    }

    saveQuestionOrResetLocation = async (params) => {
        if (this.context.isSurveyFinished()) {
            await this.resetSurveyOnFinish();
            return;
        }

        let firstUnansweredQuestionIndex;

        if (surveyQuestionMethods.isEdOrIpAccountType() && params) {
            const { subSurveyIndex, sectionIndex, selectedTopic } = params;
            firstUnansweredQuestionIndex = this.getFirstUnansweredQuestionIndex(selectedTopic);
            return await this.updateSurveyLocation(subSurveyIndex, sectionIndex, firstUnansweredQuestionIndex);
        }

        const { questions, lastSectionPassed, currentSubSurvey } = this.state;
        firstUnansweredQuestionIndex = this.getFirstUnansweredQuestionIndex(questions[lastSectionPassed]);
        return await this.updateSurveyLocation(currentSubSurvey, lastSectionPassed, firstUnansweredQuestionIndex);
    };

    updateSurveyLocation = async (subSurveyIndex = 0, sectionIndex = 0, questionIndex = 0) => {
        if (this.context.isSurveyFinished()) {
            return;
        }
        this.setState({
            lastSectionPassed: sectionIndex,
            questionIndex: questionIndex,
            currentSubSurvey: subSurveyIndex
        });
        this.setFinishedSurveysByIndex(subSurveyIndex);
        return await saveSurveyLocation(subSurveyIndex, sectionIndex, questionIndex);
    };

    getFirstUnansweredQuestionIndex = (topic) => {
        if (topic) {
            const firstUnansweredQuestionId = unansweredQuestions.getFirstUnansweredQuestion(topic.id || topic.sequenceId, true);
            return topic.questions?.findIndex((question) => this.isQuestionUnanswered(question, firstUnansweredQuestionId));
        }
    };

    isQuestionUnanswered = (question, firstUnansweredQuestionId) => {
        if (question.sequenceId === firstUnansweredQuestionId) {
            return true;
        }
        if (question?.type === "GROUP") {
            return question.questions.some((gq) => gq.sequenceId === firstUnansweredQuestionId);
        }
        return false;
    };

    changeSubSurvey = async (index) => {
        AppService.sectionChanged = true;
        if (this.context.isSurveyFinished()) {
            this.setState({
                questions: this.state.subSurveys[index].sections,
                currentSubSurvey: index
            });
            return;
        }
        await this.setState({
            questions: this.state.allSubSurveySections[index],
            currentSubSurvey: index
        });
        AppService.parsedQuestions = this.state.allSubSurveySections[index];
    };

    addCurrentSurveyToFinished = (index) => {
        const newFinishedSurveys = [...this.state.finishedSurveys];
        newFinishedSurveys.push(index);
        this.setState({ finishedSurveys: newFinishedSurveys });
        this.updateSurveyLocation(index + 1, 0);
    };

    toggleShowSurveys = () => {
        this.setState({ showSurveys: !this.state.showSurveys });
    };

    resetSurveyOnFinish = async () => {
        const savedUser = await resetSurveyLocation();
        this.context.setPortalStatus(savedUser.data.portalStatus);
    };

    resetFinishedSurveys = () => {
        this.setState({ finishedSurveys: [] });
    };

    resetSurveyLocation = () => {
        this.setState({ currentSubSurvey: 0, lastSectionPassed: 0 });
    };

    isSurveyLoad = (boolean) => {
        this.setState({ isSurveyLoaded: boolean });
    };

    setSurveyScroll = (boolean) => {
        this.setState({ stopScrolling: boolean });
    };

    setDynamicQuestions = (dynamicQuestion) => {
        this.setState((prevState) => {
            const { dynamicQuestions } = prevState;

            const existingQuestion = dynamicQuestions.find((question) => question.id === dynamicQuestion.id);

            if (existingQuestion) {
                return {
                    dynamicQuestions: dynamicQuestions.map((question) => (question.id === dynamicQuestion.id ? dynamicQuestion : question))
                };
            } else {
                return {
                    dynamicQuestions: [...dynamicQuestions, dynamicQuestion]
                };
            }
        });
    };

    updateQuestions = (questions) => {
        this.setState({ questions: questions });
    };

    render() {
        return (
            <SurveyQuestionsContext.Provider
                value={{
                    ...this.state,
                    changeSubSurvey: this.changeSubSurvey,
                    addCurrentSurveyToFinished: this.addCurrentSurveyToFinished,
                    toggleShowSurveys: this.toggleShowSurveys,
                    resetFinishedSurveys: this.resetFinishedSurveys,
                    updateSurveyLocation: this.updateSurveyLocation,
                    setSurveyLocation: this.setSurveyLocation,
                    saveQuestionOrResetLocation: this.saveQuestionOrResetLocation,
                    isSurveyLoad: this.isSurveyLoad,
                    setSurveyScroll: this.setSurveyScroll,
                    setDynamicQuestions: this.setDynamicQuestions,
                    resetSurveyLocation: this.resetSurveyLocation,
                    setFinishedSurveysByIndex: this.setFinishedSurveysByIndex,
                    updateQuestions: this.updateQuestions,
                    changeTopic: this.changeTopic
                }}
            >
                {this.props.children}
            </SurveyQuestionsContext.Provider>
        );
    }
}

export default SurveyQuestionsStore;
