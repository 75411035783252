import React, { useContext, useEffect, useRef, useState } from "react";
import "./PhotoUpload.scss";
import plus from "@resources/images/upload-icon.svg";
import removePhoto from "@resources/images/white-cancel.svg";
import ImageCrop from "./imageCrop/imageCrop";
import Hint from "@components/hint/HintComponent";
import AppService from "@service/AppService/AppService";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";
import uploadIcon from "@resources/images/photo-upload.svg";
import ProgressBar from "@components/ProgressBar/ProgressBar";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import { generateFilename } from "./PhotoUtils";
import { AuthContext } from "../../AuthContext";

function PhotoUpload({ multiple, className, readonly, onAnswer, selectedTopic, question }) {
    const authContext = useContext(AuthContext);
    const [previewFiles, setPreviewFiles] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [imageEditor, setImageEditor] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState(question.answers[0]);
    const [showLoader, setShowLoader] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [allFilesLoad, setAllFilesLoad] = useState(null);
    const [progressBar, setProgressBar] = useState(0);
    const ref = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (question.answers === undefined || question.answers.length === 0) {
            return;
        }
        if (question.answers[0].userAnswer) {
            if (multiple) {
                const allLoadedPhoto = JSON.parse(question.answers[0].userAnswer);
                const newPreviewFiles = [...previewFiles];
                for (const key in allLoadedPhoto) {
                    newPreviewFiles.unshift({
                        name: key,
                        src: allLoadedPhoto[key]
                    });
                }
                setPreviewFiles(newPreviewFiles);
            } else {
                setPreviewFiles([
                    {
                        src: question.answers[0].userAnswer
                    }
                ]);
            }
        }
    }, []);

    const onFileChange = (event, answer) => {
        event.preventDefault();
        const files = [...event.target.files];
        event.target.value = "";
        prepareAndUploadFiles(files, answer);
    };
    const onDropHandler = (event, answer) => {
        event.preventDefault();
        const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
        const files = [...event.dataTransfer.files].filter((file) => allowedTypes.includes(file.type));
        if (files.length !== event.dataTransfer.files.length && multiple) {
            surveyQuestionMethods.showNotificationWithError(t("few-invalid-types-error"));
        }
        prepareAndUploadFiles(files, answer);
    };

    const prepareAndUploadFiles = (files, answer) => {
        setCurrentAnswer(answer);
        const loadedPhotoCount = previewFiles.length;
        const allowedSize = 15 * 1024 * 1024;
        const allowedPhotoCount = 20;
        if (files.filter((file) => file.size > allowedSize).length > 0) {
            surveyQuestionMethods.showNotificationWithError(multiple ? t("big-file-multi") : t("big-file-single"));
            return;
        }
        if (!files.some((file) => ["image/png", "image/jpg", "image/jpeg"].includes(file.type))) {
            surveyQuestionMethods.showNotificationWithError(t("invalid-type-error"));
            return;
        }
        if (files.length > 0 && loadedPhotoCount + files.length <= allowedPhotoCount) {
            multiple ? uploadMultipleFiles(files) : uploadPreviewFiles(files);
        } else {
            surveyQuestionMethods.showNotificationWithError(t("files-count-limit"));
        }
    };
    const dragStartHandler = (event) => {
        event.preventDefault();
    };
    const dragLeaveHandler = (event) => {
        event.preventDefault();
    };
    const uploadPreviewFiles = (files) => {
        if (files.length === 0) {
            return;
        }
        if (multiple) {
            const allPreviewFiles = [...previewFiles];
            for (const name in files) {
                const previewFile = {
                    src: files[name],
                    name: name
                };
                if (!previewFiles.some((f) => f.name === name)) {
                    allPreviewFiles.push(previewFile);
                }
                setPreviewFiles(allPreviewFiles);
            }
        } else {
            const preparedFileName = files[0].name.replace(/ /g, "_");
            setPreviewFiles([
                {
                    src: URL.createObjectURL(files[0]),
                    name: preparedFileName
                }
            ]);
            setFileList(files);
        }
    };

    const removePreviewPhoto = async (name) => {
        const removedPreviewFiles = previewFiles.filter((file) => file.name !== name);
        await AppService.removeMultiplePhoto(currentAnswer.id, question.id, name);
        setPreviewFiles(removedPreviewFiles);
        if (removedPreviewFiles.length === 0) {
            question.answers[0].userAnswer = "";
            onAnswer(selectedTopic, question, currentAnswer);
        }
    };

    const onCancel = () => {
        setPreviewFiles([]);
        setFileList([]);
        removeSingleUploadAnswer(currentAnswer.id, question.id);
    };
    const onSaveImage = () => {
        uploadPhotoByAccountType(AppService.accountType);
    };

    const uploadPhotoByAccountType = (accountType) => {
        switch (accountType) {
            case "egg_donor":
            case "admin": {
                let imageURL;
                if (imageEditor !== null) {
                    imageURL = imageEditor.getImageScaledToCanvas().toDataURL();
                    const newPreviewFile = {
                        ...previewFiles[0],
                        src: imageURL
                    };
                    setPreviewFiles([newPreviewFile]);
                }
                if (fileList[0] != null && !multiple) {
                    uploadSingleFile(imageURL);
                }
                break;
            }
            case "surrogate":
            case "parent": {
                if (fileList[0] != null && !multiple) {
                    uploadSingleFile();
                }
                break;
            }
        }
    };

    const uploadSingleFile = (imageURL) => {
        setShowLoader(true);
        let filename = generateFilename(fileList[0].name.split(".").pop());
        if (imageURL) {
            const croppedFile = getCroppedFile(fileList[0], imageURL, filename);
            uploadSingle(fileList[0], filename, true);
            uploadSingle(croppedFile, filename, false, () => createSingleUploadAnswer(filename, currentAnswer.id, question.id));
        } else {
            uploadSingle(fileList[0], filename, false, () => createSingleUploadAnswer(filename, currentAnswer.id, question.id));
        }
    };

    const getCroppedFile = (originalFile, croppedFile, filename) => {
        const blob = AppService.convertBase64ToBlob(croppedFile);
        return new File([blob], filename, { type: originalFile.type });
    };

    const createSingleUploadAnswer = (filename, answerId, questionId) => {
        const res = AppService.uploadSinglePhoto(filename, answerId, questionId, question.index);
        Promise.resolve(res)
            .then((result) => {
                if (result) {
                    setSelectedValue(result.data, currentAnswer.id);
                    onAnswer(selectedTopic, question, currentAnswer);
                    setShowLoader(false);
                }
            })
            .catch(() => setShowLoader(false));
    };

    const removeSingleUploadAnswer = (answerId, questionId) => {
        const res = AppService.removeSinglePhoto(answerId, questionId);
        Promise.resolve(res)
            .then((result) => {
                if (result) {
                    setSelectedValue(result.data, currentAnswer.id);
                    onAnswer(selectedTopic, question, currentAnswer);
                }
            })
            .catch(() => {});
    };

    const uploadMultipleFiles = (files) => {
        const fileObjects = files.map((f) => {
            return { originalName: f.name, filename: generateFilename(f.name.split(".").pop()) };
        });
        const filenames = fileObjects.map((f) => f.filename);
        setAllFilesLoad(files.length);
        let uploadedFiles = [];
        setShowLoader(true);
        const userId = AppService.accountType === "admin" ? AppService.edId : authContext.user.id;
        AppService.getGalleryUploadURLs(filenames, userId).then((resp) => {
            const items = resp.data;
            files.forEach((f) => {
                const generatedName = fileObjects.find((fO) => fO.originalName === f.name).filename;
                const item = items.find((i) => i.filename === generatedName);
                uploadGalleryItem(f, item.filename, item.uploadURL, uploadedFiles, files.length);
            });
        });
        setProgressBar(0);
        setProgressValue(0);
    };

    const createGalleryAnswer = (filenames, answer, questionId) => {
        const res = AppService.uploadMultiplePhoto(filenames, answer.id, questionId, question.index);
        Promise.resolve(res)
            .then((result) => {
                if (result) {
                    setTimeout(() => {
                        setShowLoader(false);
                        setSelectedValue(JSON.stringify(result.data), answer.id);
                        uploadPreviewFiles(result.data);
                        onAnswer(selectedTopic, question, answer);
                    }, 3000);
                }
            })
            .catch(() => setShowLoader(false));
    };

    const uploadSingle = (file, filename, original, onComplete) => {
        const userId = AppService.accountType === "admin" ? AppService.edId : authContext.user.id;
        AppService.getSingleUploadURL(filename, original, userId).then((resp) => {
            fetch(resp.data.uploadURL, {
                method: "PUT",
                body: file
            }).then((uploadResponse) => {
                if (uploadResponse.status === 200) {
                    onComplete && onComplete();
                    setShowLoader(false);
                }
            });
        });
    };

    const uploadGalleryItem = (file, filename, url, uploadedFiles, count) => {
        fetch(url, {
            method: "PUT",
            body: file
        }).then((resp) => {
            if (resp.status === 200) {
                uploadedFiles.push(filename);
                if (count && uploadedFiles.length === count) {
                    createGalleryAnswer(uploadedFiles, currentAnswer, question.id);
                }
                const progressBarValue = 345 / count;
                setProgressBar((prev) => prev + progressBarValue);
                setProgressValue((prev) => prev + 1);
            }
        });
    };

    const setSelectedValue = (answer, answerId) => {
        const answerIndex = question.answers.findIndex((a) => a.id === answerId);
        question.answers[answerIndex].userAnswer = answer;
    };

    const buildPreviewPhoto = () => {
        if (multiple) {
            return renderMultiplePreview();
        } else {
            return (
                <ImageCrop
                    imageSrc={previewFiles[0].src}
                    onCancel={onCancel}
                    setImageEditor={setImageEditor}
                    onSaveImage={onSaveImage}
                    showLoader={showLoader}
                    setShowLoader={setShowLoader}
                />
            );
        }
    };
    const renderMultiplePreview = () => {
        return (
            <div className="preview-container">
                {previewFiles.map((file, i) => (
                    <div key={i} className="preview-wrapper">
                        <img src={file.src} className="preview-photo" />
                        <img src={removePhoto} className="remove-photo" onClick={() => removePreviewPhoto(file.name)} />
                    </div>
                ))}
            </div>
        );
    };
    const answers = question.answers.map((a, i) => {
        return (
            <div className="photo-upload" ref={ref} key={i}>
                <div
                    className="upload-container"
                    onDragStart={dragStartHandler}
                    onDragLeave={dragLeaveHandler}
                    onDragOver={dragStartHandler}
                    onDrop={(e) => onDropHandler(e, a)}
                >
                    {showLoader ? (
                        <div className="loading-spinner">
                            {multiple ? (
                                <>
                                    {!!allFilesLoad && (
                                        <div className="progress-content">
                                            <div className="progress-info">
                                                <img src={uploadIcon} alt="upload" />
                                                <div className="text">
                                                    {progressValue !== allFilesLoad ? (
                                                        <>
                                                            Upload: {progressValue} of {allFilesLoad} files
                                                        </>
                                                    ) : (
                                                        <>Finishing gallery uploading...</>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <ProgressBar width={345} progress={progressBar} />
                                </>
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    ) : (
                        <label className="upload-label">
                            <input
                                type="file"
                                className="upload-input"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => onFileChange(e, a)}
                                multiple={multiple}
                                alt={"upload"}
                            />
                            <div className="label-text">
                                <img src={plus} alt="add" />
                                <div className="title">
                                    <span className="blue-text">{t("Upload photo")}</span>, {t("or just drag and drop here")}
                                </div>
                                <div className="size-text">{t("File Max Size")}</div>
                                <div className="size-text types-text">{t("File Formats")}</div>
                                {multiple && <div className="size-text loaded-count">{previewFiles.length}/20</div>}
                            </div>
                        </label>
                    )}
                </div>
                {previewFiles.length > 0 && buildPreviewPhoto()}
            </div>
        );
    });

    return (
        <div className={classNames("survey-question", className)}>
            {readonly ? <div className="answer-overlay" /> : null}
            <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
            <div className="question-answers upload-answer">
                <div className="content">{answers}</div>
            </div>
            <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
        </div>
    );
}

export default PhotoUpload;
