import React, { useState, useEffect, useContext } from "react";
import AppService from "@service/AppService/AppService";
import { unansweredQuestions } from "@shared/AnswersTracker/AnswersTracker";
import { animateScroll as scroll } from "react-scroll";
import UnansweredNavigationComponent from "@components/surveyQuestions/unasnweredNavigation/UnansweredNavigationComponent";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { AuthContext } from "../../AuthContext";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";
import { fillExtoleData, fillFriendBuyData, fillGtmAnalyticsData, surveyQuestionMethods } from "../../shared/Questions/Questions";
import { QuestionListService } from "../../service/QuestionListService/QuestionListService";
import SurveyFinishedFeedback from "./SurveyFinishedFeedback/SurveyFinishedFeedback";
import SectionButton from "./SectionButton/SectionButton";
import QuestionElements from "./QuestionElements/QuestionElements";
import FlyoutComponent from "../flyout/FlyoutComponent";
import { resetSurveyLocation } from "../../requests/surveyAPIRequests/surveyAPIRequests";
import { UserSurveyStatus } from "../../helper/Constants";

function QuestionsList({
    unansweredWarning,
    location,
    onComplete,
    isGroup,
    isDynamicGroup,
    requestError,
    onChangeSection,
    updateProgress,
    requestStarted,
    questions,
    dynamicQuestion,
    instanceId,
    onAnswer,
    selectedTopic,
    isLastTopic
}) {
    const questionContext = useContext(SurveyQuestionsContext);
    const authContext = useContext(AuthContext);
    const [showUnansweredWarning, setShowUnansweredWarning] = useState(!!unansweredWarning);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (
            location.state?.from === "LITE" &&
            questionContext?.subSurveys?.length > 0 &&
            questionContext?.subSurveys[questionContext.currentSubSurvey].show === "As a demo"
        ) {
            onFinishButtonClick();
        }
    }, [
        location.state?.from,
        questionContext?.subSurveys?.length,
        questionContext?.subSurveys && questionContext?.subSurveys[questionContext?.currentSubSurvey]?.show
    ]);

    useEffect(() => {
        setShowUnansweredWarning(unansweredWarning);
    }, [unansweredWarning]);

    const onNextButtonClick = (selectedTopic) => {
        const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(selectedTopic.sequenceId || selectedTopic.id);
        if (firstUnansweredQuestion) {
            surveyQuestionMethods.scrollSurveyToBottom();
            setShowUnansweredWarning(true);
            return;
        }
        if (AppService.isAppDisqualifiedBySectionType()) {
            finishSurvey();
            return;
        }
        if (!firstUnansweredQuestion || (AppService.isAppDisqualified && AppService.disqType === "section")) {
            AppService.surveyScore = AppService.calculateSurveyScore(questionContext.lastSectionPassed);
            const { userSurveyId, userSurveyStatus, surveyScore, disqualificationReasons } = AppService;
            QuestionListService.sendSurveyStatus(userSurveyId, userSurveyStatus, surveyScore, disqualificationReasons).catch((e) => {
                if (e.response.status !== 500) {
                    requestError("An error occurred while try to save the survey");
                }
            });
            setShowUnansweredWarning(false);
            onChangeSection(selectedTopic);
        }
    };
    const finishSurvey = () => {
        AppService.userSurveyStatus =
            AppService.isSurveyDisqualified() || AppService.isAppDisqualifiedBySectionType()
                ? UserSurveyStatus.REJECTED
                : UserSurveyStatus.COMPLETED;
        AppService.surveyScore = AppService.calculateSurveyScore(questionContext.lastSectionPassed);
        fillGtmAnalyticsData(AppService.accountType, AppService.userSurveyStatus);

        if (AppService.accountType === "surrogate") {
            fillExtoleData(authContext);
            if (AppService.userSurveyStatus === UserSurveyStatus.COMPLETED) {
                fillFriendBuyData(authContext);
            }
        }
        const { userSurveyId, userSurveyStatus, surveyScore, disqualificationReasons } = AppService;
        authContext.setUserSurveyStatus(userSurveyStatus);
        QuestionListService.sendSurveyStatus(userSurveyId, userSurveyStatus, surveyScore, disqualificationReasons, true)
            .then((resp) => {
                resetSurveyLocation().then((savedUser) => {
                    authContext.setUser(savedUser.data);
                });
                questionContext.updateQuestions(questionContext.subSurveys[questionContext.currentSubSurvey].sections);
                AppService.isAppMuted = resp.data.muted;
                onComplete();
            })
            .catch((e) => {
                if (e.response.status !== 500) {
                    requestError(i18n.t("basic_error"));
                } else {
                    onComplete();
                }
            });
        requestStarted();
    };
    const onFinishButtonClick = (selectedTopic) => {
        setShowUnansweredWarning(false);
        const firstUnansweredQuestion = selectedTopic ? unansweredQuestions.getFirstUnansweredQuestion(selectedTopic.id) : undefined;
        const isSurveyDisq = AppService.isSurveyDisqualified() || AppService.isAppDisqualifiedBySectionType();

        const currentSubSurveySections = questionContext.subSurveys[questionContext.currentSubSurvey].sections;
        const isLastSubSurvey = !questionContext.subSurveys[questionContext.currentSubSurvey + 1];
        const isLastSection =
            currentSubSurveySections[currentSubSurveySections.length - 1]?.sequenceId === selectedTopic.id ||
            currentSubSurveySections[currentSubSurveySections.length - 1]?.sequenceId === selectedTopic.sequenceId;

        const unansweredQuestionsLength = selectedTopic.id
            ? unansweredQuestions.questions[selectedTopic.id]?.size
            : unansweredQuestions.questions[selectedTopic.sequenceId]?.size;

        if (
            isLastSection &&
            isLastSubSurvey &&
            unansweredQuestions.isAllQuestionsAnsweredExceptSection(selectedTopic.id || selectedTopic.sequenceId) &&
            unansweredQuestionsLength <= 2
        ) {
            questionContext.addCurrentSurveyToFinished(questionContext.currentSubSurvey);
            finishSurvey();
            return;
        }

        if (unansweredQuestions.isAllQuestionsAnswered() && !firstUnansweredQuestion) {
            if (AppService.isAppDisqualifiedBySectionType()) {
                finishSurvey();
                return;
            }
            if (questionContext.allSubSurveySections.length === 1) {
                questionContext.addCurrentSurveyToFinished(questionContext.currentSubSurvey);
                finishSurvey();
            }
            if (questionContext.allSubSurveySections.length > 1 && !questionContext.subSurveys[questionContext.currentSubSurvey + 1]) {
                questionContext.addCurrentSurveyToFinished(questionContext.currentSubSurvey);
                finishSurvey();
            }
            if (
                (questionContext.subSurveys[questionContext.currentSubSurvey + 1]?.show === "If not disqualified" && !isSurveyDisq) ||
                questionContext.subSurveys[questionContext.currentSubSurvey + 1]?.show === "Always"
            ) {
                questionContext.addCurrentSurveyToFinished(questionContext.currentSubSurvey);
                questionContext.changeSubSurvey(questionContext.currentSubSurvey + 1);
                scroll.scrollToTop({
                    duration: 0,
                    delay: 0,
                    smooth: "none",
                    containerId: "survey-container"
                });
            }
            if (isSurveyDisq && questionContext.subSurveys[questionContext.currentSubSurvey + 1]?.show === "If not disqualified") {
                finishSurvey();
            }
        } else {
            showUnansweredSectionsListError();
            surveyQuestionMethods.scrollSurveyToBottom();
            if (AppService.userSurveyStatus !== UserSurveyStatus.REJECTED) {
                setShowUnansweredWarning(true);
            }
        }
    };

    const showUnansweredSectionsListError = () => {
        const unansweredSectionsIds = unansweredQuestions.getSectionsWithUnansweredQuestions();
        const questionContextMap = questionContext.questions.reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
        }, {});
        const errorMessage = [];
        unansweredSectionsIds.forEach((sectionId) => {
            const section = questionContextMap[sectionId];
            if (section && section.title) {
                errorMessage.push(section.title);
            }
        });

        if (errorMessage.length > 0) {
            setErrorMessage("You have unanswered questions in " + errorMessage.join(", "));
        } else {
            setErrorMessage("");
        }
    };
    AppService.isAppDisqualified = false;

    let type = "";

    if (authContext.user?.role === "PARENT") {
        type = "parent";
    }

    if (authContext.user?.role === "EGG_DONOR") {
        type = "egg_donor";
    }

    if (authContext.user?.role === "SURROGATE") {
        type = "surrogate";
    }

    const groupClass = isGroup ? "group-question-container" : "";

    const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(selectedTopic.sequenceId || selectedTopic.id);

    return (
        <div className={`${groupClass} survey-questions-container survey-questions-container-${type}`}>
            {errorMessage && <FlyoutComponent message={errorMessage} type="error" onClose={() => setErrorMessage(null)} />}
            <QuestionElements
                questions={questions}
                selectedTopic={selectedTopic}
                onAnswer={onAnswer}
                showUnansweredWarning={showUnansweredWarning}
                updateProgress={updateProgress}
                isDynamicGroup={isDynamicGroup}
                dynamicQuestion={dynamicQuestion}
                instanceId={instanceId}
            />
            <SurveyFinishedFeedback isLastTopic={isLastTopic} isGroup={isGroup} />
            {showUnansweredWarning && firstUnansweredQuestion && !isGroup && (
                <UnansweredNavigationComponent
                    sectionId={selectedTopic.sequenceId || selectedTopic.id}
                    accountType={AppService.accountType}
                />
            )}
            <SectionButton
                isGroup={isGroup}
                isLastTopic={isLastTopic}
                selectedTopic={selectedTopic}
                onNextButtonClick={onNextButtonClick}
                onFinishButtonClick={onFinishButtonClick}
            />
        </div>
    );
}

export default withRouter(QuestionsList);
