import React from "react";
import "./EggDonorMark.scss";
import classNames from "classnames";

function EggDonorMark({ reserved, newDonor }) {
    const className = classNames("mark-container", {
        reserved: reserved,
        newDonor: newDonor && !reserved
    });

    const markText = reserved ? "Reserved" : newDonor ? "New" : "";

    return (
        <div className={className}>
            <span>{markText}</span>
        </div>
    );
}

export default EggDonorMark;
