import AppService from "../AppService/AppService";

const PhotoListService = {
    getGalleryAvailability: (donorId) => {
        return AppService.getAxios().get(`/api/get-profile/gallery/${donorId}`);
    },

    updateGalleryItemDescription: (userAnswerId, body) => {
        return AppService.getAxiosWithRawBody().put(
            `/api/user-with-answers/admin/answer/${userAnswerId}/update/gallery/description/`,
            body
        );
    },

    addGalleryItem: (userAnswerId, filenames) => {
        return AppService.getAxiosWithRawBody().put(`/api/user-with-answers/admin/answer/${userAnswerId}/update/gallery/add/`, filenames);
    },

    createGallery: (donorId, filenames) => {
        return AppService.getAxiosWithRawBody().post(`/api/user-with-answers/admin/${donorId}/gallery/create`, filenames);
    },

    swapGalleryPhoto: (donorId, filename) => {
        return AppService.getAxios().get(`/api/user-with-answers/admin/${donorId}/swap-photo/${filename}`);
    },

    deleteGalleryItem: (userAnswerId, name) => {
        return AppService.getAxios().delete(`/api/user-with-answers/admin/answer/${userAnswerId}/update/gallery/remove?filename=${name}`);
    }
};

export default PhotoListService;
