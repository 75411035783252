import React from "react";
import AppService from "@service/AppService/AppService";
import SurveyHeader from "@components/surveyHeader/SurveyHeaderComponent";
import Flyout from "@components/flyout/FlyoutComponent";
import { Redirect } from "react-router-dom";
import { Events, scrollSpy } from "react-scroll";
import QuestionsList from "@components/questionsList/QuestionsListComponent";
import "./SurveyQuestionsComponent.scss";
import { UserSurveyStatus } from "../../helper/Constants";

class SurveyQuestionsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenQuestions: []
        };
        this.hiddenQuestions = [];
        this.requestStarted = this.requestStarted.bind(this);
        this.requestError = this.requestError.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        this.onComplete = this.onComplete.bind(this);
    }

    requestStarted() {
        this.setState({
            showProgress: true,
            showError: false,
            redirectToStart: false
        });
    }

    requestError(err) {
        this.setState({
            showProgress: false,
            showError: err,
            redirectToStart: false
        });
    }

    onComplete() {
        AppService.userSurveyStatus = AppService.userSurveyStatus || UserSurveyStatus.COMPLETED;
        this.setState({
            showProgress: false,
            showError: false,
            redirectToStart: true
        });
    }

    updateProgress() {
        this.setState({ key: Math.random() });
    }

    componentDidMount() {
        Events.scrollEvent.register("begin", function (to, element) {
            // add code for debugging when needed
        });

        Events.scrollEvent.register("end", function (to, element) {
            // add code for debugging when needed
        });
        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
    }

    redirectStart = () => {
        this.setState({ redirectToStart: true });
    };

    getHiddenQuestionIds = (questions) => {
        return questions
            .filter((q) => q.visibility && q.visibility.length)
            .map((q) => {
                let hiddenQuestionId = "";
                q.visibility.forEach((v) => {
                    const sourceQuestion = questions.find((q) => q.sequenceId === v.sourceQuestion);
                    const visibilityTriggerAnswer = sourceQuestion.answers.find((a) => a.sequenceId === v.sequenceId);

                    const isVisibilityTriggerAnswered =
                        (sourceQuestion.type === "TEXT" &&
                            (visibilityTriggerAnswer.userAnswer === undefined || visibilityTriggerAnswer.userAnswer.trim() === "")) ||
                        !sourceQuestion.lastAnswerIds.includes(visibilityTriggerAnswer.id);

                    if (isVisibilityTriggerAnswered) {
                        hiddenQuestionId = q.sequenceId;
                    }
                });
                return hiddenQuestionId;
            });
    };

    render() {
        const { selectedTopic, onAnswer, isLastTopic } = this.props;

        if (this.state.redirectToStart) {
            return <Redirect to="/start" push={true} />;
        }
        return (
            <div className="survey-questions">
                {this.state.showProgress ? <div className={`animated-gradient-${AppService.accountType}`} /> : null}
                {this.state.showError ? (
                    <Flyout type="error" message={this.state.showError} onClose={() => this.setState({ showError: false })} />
                ) : null}
                <SurveyHeader
                    className="progress-survey-header"
                    selectedTopic={selectedTopic}
                    key={this.state.key}
                    backRedirect={this.redirectStart}
                />
                <QuestionsList
                    isLastTopic={isLastTopic}
                    isGroup={false}
                    onQuestionHidden={this.onQuestionHidden}
                    questions={selectedTopic?.questions}
                    selectedTopic={selectedTopic}
                    onAnswer={(selectedTopic, question, a) => {
                        onAnswer(selectedTopic, question, a);
                        this.forceUpdate();
                    }}
                    updateProgress={this.updateProgress}
                    onChangeSection={this.props.onChangeSection}
                    requestStarted={this.requestStarted}
                    requestError={this.requestError}
                    onComplete={this.onComplete}
                />
            </div>
        );
    }
}

export default SurveyQuestionsComponent;
