import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import "./FullProfileInformation.scss";
import AppService from "@service/AppService/AppService";
import SecureImageComponent from "@components/secureImage/SecureImageComponent";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";
import linkIcon from "@resources/images/new-tab-icon.svg";
import SpinnerLoader from "@components/SpinnerLoader/SpinnerLoader";
import Calendly from "@components/calendly/CalendlyComponent";
import Tabs from "./tabs/Tabs";
import InformationList from "./informationList/InformationList";
import PhotoList from "./photoList/PhotoList";
import { AuthContext } from "../../AuthContext";
import ModalPopupComponent from "../../components/modalPopup/ModalPopupComponent";
import AdminNotesComponent from "./AdminNotesComponent/AdminNotesComponent";
import {
    getActiveLiveDonorIds,
    getAdminNotes,
    getAdminShortDonorInfo,
    getDonorIdByIntegrationId,
    getEDShortData,
    getFavoriteDonorIds,
    getFullProfileSections,
    getPdfForParent,
    getReservedDonorIds
} from "../../requests/AdminAPIRequests/adminAPIRequests";
import ProfilesNavigationButtons from "./ProfilesNavigationButtons/ProfilesNavigationButtons";
import { UserSurveyStatus } from "../../helper/Constants";
import { getDonorsList, getFavoriteDonors, getReservedDonors } from "../../requests/EggDonorListRequests/EggDonorListRequests";

function FullProfileInformation() {
    const { user } = useContext(AuthContext);
    const { integrationId } = useParams();

    const [donor, setDonor] = useState(null);
    const [shortInfo, setShortInfo] = useState(null);
    const [isLiked, setLike] = useState(false);
    const [noProfile, setNoProfile] = useState(false);
    const [popups, setPopups] = useState({
        reserve: false,
        congratulations: false,
        schedule: false
    });
    const [showSpinner, setShowSpinner] = useState(false);
    const [showPDFLoading, setShowPDFLoading] = useState(false);
    const [showCalendlyPopUp, setShowCalendlyPopUp] = useState(false);
    const [adminNotes, setAdminNotes] = useState([]);
    const [id, setId] = useState(null);
    const [donorIdsList, setDonorIdsList] = useState([]);

    const history = useHistory();

    const isAdmin = user?.role === "ADMIN";
    const queryParams = new URLSearchParams(window.location.search);
    const fromPage = queryParams.get("from");

    useEffect(() => {
        (async () => getAllDonorIDs())();
    }, []);

    useEffect(() => {
        (async () => getDonorId())();
    }, [integrationId]);

    const prepareFilters = (filterParams) => {
        const listParams = {};
        const singleParams = {};
        if (filterParams) {
            for (let key of Object.keys(filterParams)) {
                if (Array.isArray(filterParams[key])) {
                    if (filterParams[key].length > 0) {
                        listParams[key] = filterParams[key];
                    }
                } else {
                    if (filterParams[key].length > 0) {
                        singleParams[key] = filterParams[key];
                    }
                }
            }
        }
        return { listParams, singleParams };
    };

    const getAllDonorIDs = async () => {
        const filters = JSON.parse(sessionStorage.getItem("filter-params"));
        const bodyFilters = prepareFilters(filters);
        const hasActiveFilters = Object.keys(bodyFilters.listParams).length > 0 || Object.keys(bodyFilters.singleParams).length > 0;

        const fetchDonorIds = async (fetchFunction) => {
            try {
                const resp = await fetchFunction();
                setDonorIdsList(resp.data.allIntegrationIds || resp.data);
            } catch (e) {
                console.error(e);
            }
        };

        const getFetchFunction = () => {
            if (filters && hasActiveFilters) {
                switch (fromPage) {
                    case "favorite":
                        return () => getFavoriteDonors(1, user?.id, 1, bodyFilters);
                    case "reserved":
                        return () => getReservedDonors(1, user?.id, 1, bodyFilters);
                    default:
                        return () => getDonorsList(1, user?.id, 1, bodyFilters);
                }
            } else {
                switch (fromPage) {
                    case "favorite":
                        return getFavoriteDonorIds;
                    case "reserved":
                        return getReservedDonorIds;
                    default:
                        return getActiveLiveDonorIds;
                }
            }
        };

        await fetchDonorIds(getFetchFunction());
    };

    const getDonorId = async () => {
        try {
            const resp = await getDonorIdByIntegrationId(integrationId);
            if (resp.data) {
                setId(resp.data);
                AppService.edId = resp.data;
            } else {
                setNoProfile(true);
            }
        } catch (e) {
            setNoProfile(true);
        }
    };

    const toggleReservePopup = (value) => {
        if (user?.portalStatus === "BASIC_ACCESS") {
            setPopups({
                ...popups,
                schedule: value
            });
        } else if (user?.portalStatus === "FULL_ACCESS") {
            setPopups({
                ...popups,
                reserve: value
            });
        }
    };

    const reserveDonor = () => {
        setShowSpinner(true);
        const body = {
            userId: user?.id,
            reservedUserId: id
        };
        AppService.getAxios()
            .post("/api/donor/reserve", body)
            .then(() => {
                setPopups({
                    reserve: false,
                    congratulations: true
                });
                setShowSpinner(false);
                setDonor({ ...donor, reserved: true });
            });
    };

    const likeDonor = async () => {
        const body = {
            userId: user?.id,
            favoriteUserId: id
        };
        setLike(!isLiked);
        if (!isLiked) {
            await AppService.getAxios().post("/api/user/favorite", body);
        } else {
            await AppService.getAxios().delete(`/api/user/favorite?userId=${body.userId}&favoriteUserId=${body.favoriteUserId}`);
        }
    };

    const toggleCongratulationsPopup = (value) => {
        setPopups({
            ...popups,
            congratulations: value
        });
    };

    const toggleSchedulePopup = (value) => {
        setPopups({
            ...popups,
            schedule: value
        });
    };

    const toggleCalendlyPopup = (value) => {
        value &&
            setPopups({
                ...popups,
                schedule: false
            });
        setShowCalendlyPopUp(value);
    };

    const addDisabledClass = (className) => className + (showSpinner ? " disabled" : "");

    const likeStyle = isLiked ? "favorite-donor-liked" : "favorite-donor";

    useEffect(() => {
        id && fetchData();
    }, [id]);

    const fetchData = async () => {
        try {
            const edInfoResp = await getEDShortData(id);
            if (edInfoResp.data === "") {
                setNoProfile(true);
            } else {
                const response = await getFullProfileSections();
                if (response.data) {
                    setDonor({ ...edInfoResp.data, questionAnswers: response.data.map((q) => ({ ...q, answers: [] })) });
                }
            }
        } catch (e) {
            setNoProfile(true);
            console.error(e.response.data.message);
        }

        if (isAdmin) {
            const shortInfo = await getAdminShortDonorInfo(id);
            if (shortInfo?.data) {
                setShortInfo(shortInfo.data);
            }
            await getAllAdminNotes();
        }

        const favoriteResp = await AppService.getAxios().get(`/api/user/favorite/${user?.id}`);
        const favoriteDonors = favoriteResp.data;
        const isLiked = favoriteDonors.some((item) => item.favoriteUserMultiKey.favoriteUserId === Number(id));
        setLike(isLiked);
    };

    const getDonorPDF = async () => {
        setShowPDFLoading(true);
        const windowReference = AppService.isSafari() && window.open();
        try {
            const resp = await getPdfForParent(id);
            AppService.isSafari() ? (windowReference.location = resp.data) : window.open(resp.data, "_blank");
        } catch (e) {
            setShowPDFLoading(false);
        } finally {
            setShowPDFLoading(false);
        }
    };
    const getAllAdminNotes = async () => {
        const response = await getAdminNotes(id);
        setAdminNotes(response.data);
    };

    const toDollarValue = (value) => {
        const number = typeof value === "number" ? value : parseFloat(value);

        if (isNaN(number)) {
            return "Invalid number";
        }

        return number
            .toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0
            })
            .replace(/,/g, " ");
    };

    const buildDonorInformation = () => {
        switch (user?.role) {
            case "ADMIN":
                return (
                    <Tabs className="desktop-version">
                        <div label="Donor information">
                            <InformationList className="full-version" donor={donor} setDonor={setDonor} isAdmin={true} />
                        </div>
                        <div label={`Comments(${adminNotes.length})`}>
                            <AdminNotesComponent adminNotes={adminNotes} setAdminNotes={setAdminNotes} donor={donor} />
                        </div>
                    </Tabs>
                );
            default:
                return <InformationList className="full-version" donor={donor} setDonor={setDonor} isAdmin={false} />;
        }
    };

    const buildActions = () => {
        switch (user?.role) {
            case "ADMIN":
                return null;
            default:
                return (
                    <>
                        <div className={likeStyle} onClick={likeDonor}>
                            {isLiked ? "" : "Favorite"}
                        </div>
                        {donor.reserved ? (
                            ""
                        ) : (
                            <div className="reserve-donor bg-parent" onClick={() => toggleReservePopup(true)}>
                                Reserve Donor
                            </div>
                        )}
                    </>
                );
        }
    };

    const redirectToEditPage = () => {
        history.push(`/donor-survey/${integrationId}/summary`);
    };

    const onBackClick = () => {
        if (history.location.pathname !== `/donor/${integrationId}`) {
            history.goBack();
        }
        switch (fromPage) {
            case "favorite":
                history.push("/favorite");
                break;
            case "reserved":
                history.push("/reserved");
                break;
            default:
                history.push("/donors");
        }
    };
    const openDonorSfdc = () => window.open(shortInfo.sfdcURL, "_blank");
    const openEmail = () => window.open(`mailto:${shortInfo.email}`, "_blank");
    const isDonorHaveActiveLive = donor?.status === UserSurveyStatus.ACTIVE_LIVE;

    return (
        <>
            {noProfile && <Redirect to={`/donors`} push={true} />}
            {donor ? (
                <>
                    <div className="full-profile">
                        {isDonorHaveActiveLive && <ProfilesNavigationButtons setDonor={setDonor} donorIdsList={donorIdsList} />}
                        <div className="profile-top">
                            <div className="back" onClick={onBackClick}>
                                <span>Back</span>
                            </div>
                            <div className="profile-menu-wrapper">
                                <div className="profile-image-wrapper">
                                    <SecureImageComponent className="profile-image" name={donor.photo} alt="Donors photo" />
                                </div>
                                <div className="donor-menu">
                                    <div className="donor-number">
                                        {integrationId}
                                        {isAdmin && shortInfo?.sfdcURL && (
                                            <div className="donor-sfdc" onClick={openDonorSfdc}>
                                                Open salesforce profile <img src={linkIcon} />
                                            </div>
                                        )}
                                        {isAdmin && (
                                            <div className="donor-sfdc" onClick={redirectToEditPage}>
                                                Edit Survey <img src={linkIcon} />
                                            </div>
                                        )}
                                    </div>
                                    {isAdmin && shortInfo && (
                                        <>
                                            <div className="donor-short-info">{`${shortInfo.firstName} ${shortInfo.lastName}`}</div>
                                            <div className="donor-email" onClick={openEmail}>
                                                {shortInfo.email}
                                            </div>
                                        </>
                                    )}
                                    <div className="actions">{buildActions()}</div>
                                    <div className="pdf-wrapper" onClick={() => getDonorPDF()}>
                                        {showPDFLoading ? (
                                            <SpinnerLoader destination="pdf-spinner" />
                                        ) : (
                                            <div className="print-pdf">
                                                <span>Print/View PDF</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {buildDonorInformation()}
                    </div>
                    <PhotoList className="full-version" donor={donor} setDonor={setDonor} isAdmin={isAdmin} />
                    <Tabs className="mobile-version">
                        <div label="Information">
                            <InformationList className="mobile-version" donor={donor} setDonor={setDonor} isAdmin={isAdmin} />
                        </div>
                        {isAdmin && (
                            <div label={`Comments(${adminNotes.length})`}>
                                <AdminNotesComponent adminNotes={adminNotes} setAdminNotes={setAdminNotes} donor={donor} />
                            </div>
                        )}
                        <div label="Photos">
                            <PhotoList className="mobile-version" donor={donor} setDonor={setDonor} isAdmin={isAdmin} />
                        </div>
                    </Tabs>
                </>
            ) : (
                <Spinner className="full-profile-loader" />
            )}
            {popups.reserve && (
                <ModalPopupComponent className="reserve-donor-popup" onDialogClose={() => toggleReservePopup(false)}>
                    <div className="title">Egg donor reservation</div>
                    <div className="information-block">You are about to make an Egg donor reservation. Please, make your choice.</div>
                    <div className="buttons-wrapper">
                        <span className={`${addDisabledClass("yes")} bg-parent`} onClick={reserveDonor}>
                            Yes, please
                        </span>
                        <span className={`${addDisabledClass("no")}`} onClick={() => toggleReservePopup(false)}>
                            Not yet
                        </span>
                        {showSpinner && <SpinnerLoader className="spinner" />}
                    </div>
                </ModalPopupComponent>
            )}
            {popups.congratulations && (
                <ModalPopupComponent className="congratulations-popup" onDialogClose={() => toggleCongratulationsPopup(false)}>
                    <div className="title">Congratulations!</div>
                    <div className="information-block">
                        You have reserved the egg donor. We will contact to confirm your reservation soon.
                    </div>
                </ModalPopupComponent>
            )}
            {popups.schedule && (
                <ModalPopupComponent className="schedule-popup" onDialogClose={() => toggleSchedulePopup(false)}>
                    <div className="title">Egg donor reservation</div>
                    <div className="information-block">
                        <p className="donor-reserve-info-main">
                            You need to upgrade your access status, if you want to reserve an egg donor.
                        </p>
                        <p className="donor-reserve-info-sub">Schedule a brief consultation with our team</p>
                        {AppService.isMobile() ? (
                            <a
                                id="calendly-link"
                                className="calendly-button"
                                target="_blank"
                                href="https://calendly.com/ed-reservation-request/egg-donor-conceiveabilities-consultation"
                            >
                                Schedule
                            </a>
                        ) : (
                            <a id="calendly-link" className="calendly-button" href="#" onClick={() => toggleCalendlyPopup(true)}>
                                Schedule
                            </a>
                        )}
                    </div>
                </ModalPopupComponent>
            )}
            <Calendly
                link="https://calendly.com/ed-reservation-request/egg-donor-conceiveabilities-consultation"
                showCalendly={showCalendlyPopUp}
                hideCalendly={() => toggleCalendlyPopup(false)}
            />
        </>
    );
}

export default FullProfileInformation;
