import React, { useEffect, useState } from "react";
import "./AdminSurveyAside.scss";
import { useHistory, useParams } from "react-router-dom";
import SecureImageComponent from "../../../components/secureImage/SecureImageComponent";
import TimelineComponent from "../../../components/timeline/TimelineComponent";
import { getTranslatedTextIfNeeded } from "../../../shared/InternationalizationTextUtils";
import { getDonorPhoto } from "../../../requests/AdminAPIRequests/adminAPIRequests";
import AppService from "../../../service/AppService/AppService";

function AdminSurveyAside({ selectedTopic, changeSurveyAndSection, basicInfo, surveyData, setSelectedTopic }) {
    const { integrationId } = useParams();
    const id = AppService.edId;
    const [donorPhoto, setDonorPhoto] = useState(null);
    const history = useHistory();

    useEffect(() => {
        (async () => loadPhoto())();
    }, []);

    const loadPhoto = async () => {
        const resp = await getDonorPhoto(id);
        if (resp.data) {
            setDonorPhoto(resp.data);
        }
    };

    const changeAdminSection = (section) => {
        const tabName = section.id;
        history.push(`/donor-survey/${integrationId}/${tabName}`);
        setSelectedTopic({ id: tabName });
    };

    return (
        <div className="admin-edit-menu survey-profile-aside">
            <div className="back-container" onClick={() => history.push(`/donor/${integrationId}`)}>
                <span className="back">Back</span>
            </div>
            <div className="menu-content">
                <div className="menu-photo">
                    <div className="profile-image-wrapper">
                        <SecureImageComponent className="profile-image" name={donorPhoto} alt="" />
                    </div>
                    {basicInfo && <p>{`${basicInfo.firstName} ${basicInfo.lastName}`}</p>}
                </div>
            </div>
            <TimelineComponent
                className="admin-editor-timeline admin-editor-timeline"
                title="ADMIN"
                questions={[
                    { title: "Egg donor summary", id: "summary" },
                    { title: "Admin changelog", id: "changelog" }
                ]}
                selectedTopic={selectedTopic}
                changeSurveyAndSection={changeAdminSection}
            />
            <div className="timeline-survey-header">SURVEY</div>
            {surveyData?.subSurveys?.map((item, index) => (
                <TimelineComponent
                    key={`${index}_time_line`}
                    disabled={false}
                    questions={item.sections}
                    selectedTopic={selectedTopic}
                    className="survey-process admin-editor-timeline"
                    title={getTranslatedTextIfNeeded(item.name)}
                    surveyIndex={index}
                    changeSurveyAndSection={changeSurveyAndSection}
                    showAllTimelines={true}
                />
            ))}
        </div>
    );
}

export default AdminSurveyAside;
