import React from "react";
import AppService from "../../service/AppService/AppService";

function LanguagePicker({ history }) {
    return (
        <div className="language-picker">
            <button
                className={`language-button english-button ${AppService.lang === "en" ? "active-language" : ""} `}
                onClick={() => AppService.changeLanguageHandler("en", history)}
            >
                English
            </button>
            <button
                className={`language-button espanol-button ${AppService.lang === "es" ? "active-language" : ""}`}
                onClick={() => AppService.changeLanguageHandler("es", history)}
            >
                Espanol
            </button>
        </div>
    );
}

export default LanguagePicker;
