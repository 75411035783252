import React from "react";

function DynamicGroupModalContent({ log }) {
    const { oldValue, newValue, target } = log;

    const parsedOldValue = oldValue && JSON.parse(oldValue);
    const parsedNewValue = newValue && JSON.parse(newValue);

    const compareGroups = (oldGroups, newGroups) => {
        return newGroups.map((newGroup, groupIndex) => {
            const oldGroup = oldGroups[groupIndex];
            return {
                instanceGroupName: newGroup.instanceGroupName,
                changes: newGroup.groupWithAnswers
                    .map((newQuestion, questionIndex) => {
                        const oldQuestion = oldGroup ? oldGroup.groupWithAnswers[questionIndex] : null;

                        const oldValue = getValueString(oldQuestion);
                        const newValue = getValueString(newQuestion);

                        if (oldValue === newValue) {
                            return null;
                        }

                        return {
                            key: newQuestion.text,
                            oldValue: oldValue,
                            newValue: newValue
                        };
                    })
                    .filter((change) => change !== null)
            };
        });
    };

    const getValueString = (question) => {
        if (question?.lastAnswersSelected.length > 0) {
            return question.lastAnswersSelected.join(", ");
        } else if (question?.answers.some((answer) => answer.userAnswer)) {
            return question.answers.map((answer) => answer.userAnswer).join(", ");
        } else {
            return "N/A";
        }
    };

    const groupDifferences = compareGroups(parsedOldValue, parsedNewValue);

    return (
        <div className="modalContainer">
            <h3 className="title">Changes in {target} Dynamic Groups</h3>
            {groupDifferences.map(
                (groupDiff, index) =>
                    groupDiff.changes.length > 0 && (
                        <div key={index} className="groupChangesContainer">
                            <h4 className="groupTitle">
                                {groupDiff.instanceGroupName} {index + 1}
                            </h4>
                            {groupDiff.changes.map((change, qIndex) => (
                                <p key={qIndex} className="changeDetail">
                                    <b>{change.key}:</b> From <span className="oldValue">{change.oldValue || "N/A"}</span> to{" "}
                                    <span className="newValue">{change.newValue || "N/A"}</span>
                                </p>
                            ))}
                        </div>
                    )
            )}
        </div>
    );
}

export default DynamicGroupModalContent;
