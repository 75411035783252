import React from "react";
import Layout from "@components/appLayout/AppLayoutComponent";
import AppProgress from "@components/applicationProgress/ApplicationProgressComponent";
import "./AccountComponent.scss";
import "./StartApplicationComponent.scss";
import AppService from "@service/AppService/AppService";
import CareTeam from "@components/careTeam/CareTeamComponent";
import { AuthContext } from "../../AuthContext";
import ProvideAuthAndQuestionContext from "../../ProvideCombinedAuthAndQuestionContext";
import StartApplication from "./StartApplicationComponent/StartApplicationComponent";
import { Redirect } from "react-router-dom";
import { UserSurveyStatus } from "../../helper/Constants";

class AccountComponent extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);

        this.state = {
            isUserJoined: AppService.isUserJoined,
            isAppStarted: AppService.isAppStarted,
            profile: null
        };

        this.onUserJoined = this.onUserJoined.bind(this);
        this.onAppStarted = this.onAppStarted.bind(this);

        if (!AppService.isMobile()) {
            AppService.isUserJoined = true;
        }
    }

    onUserJoined() {
        AppService.isUserJoined = true;
        this.setState({ isUserJoined: true });
    }

    onAppStarted() {
        AppService.isAppStarted = true;
        this.setState({ isAppStarted: true });
    }

    componentWillMount() {
        const { user } = this.context;
        this.setState({
            auth: user?.authorized,
            profile: user
        });
    }

    render() {
        if (AppService.isAppMuted) {
            return <Redirect to="/home" push={true} />;
        }

        switch (AppService.userSurveyStatus) {
            case UserSurveyStatus.COMPLETED:
            case UserSurveyStatus.ACTIVE_LIVE:
            case UserSurveyStatus.MATCHED:
                return <Redirect to="/accepted" push={true} />;
            case UserSurveyStatus.REJECTED:
            case UserSurveyStatus.INITIAL_REJECTED:
                return <Redirect to="/home" push={true} />;
        }
        const leftColumn =
            AppService.isMobile() && this.state.isUserJoined ? null : (
                <AppProgress context={this.context} accountType={AppService.accountType} />
            );
        const centerColumn = (
            <StartApplication
                isAppStarted={this.state.isAppStarted}
                isUserJoined={this.state.isUserJoined}
                onAppStarted={this.onAppStarted}
                onUserJoined={this.onUserJoined}
            />
        );
        const rightColumn = <CareTeam />;

        return (
            <Layout
                left={leftColumn}
                center={centerColumn}
                right={rightColumn}
                className={`survey-start right-column-${this.context.user?.role.toLowerCase()}`}
            />
        );
    }
}
const WrappedAccountComponent = (props) => {
    return (
        <ProvideAuthAndQuestionContext>
            <AccountComponent {...props} />
        </ProvideAuthAndQuestionContext>
    );
};

export default WrappedAccountComponent;
