import React, { useContext } from "react";
import i18n from "i18next";
import AppService from "../../../service/AppService/AppService";
import { AuthContext } from "../../../AuthContext";
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";

function SectionButton({ isGroup, isLastTopic, selectedTopic, onNextButtonClick, onFinishButtonClick, isLoading }) {
    const { isSurveyFinished } = useContext(AuthContext);
    const nextSectionButtonClassName = `bg-${AppService.accountType}`;

    if (isLoading && !isGroup && !isSurveyFinished()) {
        return (
            <div className="next-section-button" style={{ position: "relative" }}>
                <button className="disabled">
                    <SpinnerLoader /> <p style={{ visibility: "hidden" }}>...</p>
                </button>
            </div>
        );
    }

    if (selectedTopic?.id || selectedTopic?.sequenceId) {
        if (!isLastTopic && !isGroup && !isSurveyFinished()) {
            return (
                <div className="next-section-button">
                    <button className={nextSectionButtonClassName} onClick={() => onNextButtonClick(selectedTopic)}>
                        {i18n.t("Next")}
                    </button>
                </div>
            );
        }

        if (!isGroup && isLastTopic && !isSurveyFinished()) {
            return (
                <div className="next-section-button">
                    <button className={nextSectionButtonClassName} onClick={() => onFinishButtonClick(selectedTopic)}>
                        {i18n.t("Finish")}
                    </button>
                </div>
            );
        }
    }
    return null;
}

export default SectionButton;
