import React from "react";
import "./LiteCheckBoxComponent.scss";

class LiteCheckBoxComponent extends React.Component {
    render = () => {
        const isChecked = this.props.isChecked;
        const className = `checkbox checkbox-${this.props.accountType}`;
        // Required for UI tests
        const spanClassName = this.props.isChecked ? "checked-span" : "unchecked-span";
        return (
            <label className={className}>
                <input type="checkbox" checked={isChecked} disabled={true} />
                <span className={spanClassName}></span>
            </label>
        );
    };
}

export default LiteCheckBoxComponent;
