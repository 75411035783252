import React from "react";
import "./SurveyProgressBar.scss";

function SurveyProgressBar({ completed, total }) {
    const percentage = (completed / total) * 100;

    return (
        <div className="question-statuses">
            <div className="progress-bar">
                <div className="progress-bar__fill" style={{ width: `${percentage}%` }} />
            </div>
            <span className="progress-bar__label">{`${completed} of ${total}`}</span>
        </div>
    );
}

export default SurveyProgressBar;
