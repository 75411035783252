import React from "react";
import "./ProfileNavigationButtons.scss";
import nextProfileOval from "@resources/images/next-profile-oval.svg";
import nextProfileArrow from "@resources/images/next-profile-path.svg";
import prevProfileOval from "@resources/images/prev-profile-oval.svg";
import prevProfileArrow from "@resources/images/prev-profile-path.svg";
import emptyNextProfile from "@resources/images/RightSwipe.svg";
import emptyPrevProfile from "@resources/images/LeftSwipe.svg";
import { useHistory, useParams } from "react-router-dom";

function ProfilesNavigationButtons({ setDonor, donorIdsList }) {
    const { integrationId } = useParams();
    const history = useHistory();

    const currentIndex = donorIdsList.findIndex((id) => id === Number(integrationId));
    const isLastProfile = currentIndex === donorIdsList.length - 1;
    const isFirstProfile = currentIndex === 0;

    const navigateToProfile = (direction) => {
        const newIndex = currentIndex + (direction === "next" ? 1 : -1);
        const profileId = donorIdsList[newIndex];

        if (profileId !== undefined) {
            const profileElement = document.querySelector(direction === "next" ? ".next-profile" : ".prev-profile");
            if (profileElement) {
                profileElement.classList.add(direction === "next" ? "animate-swipe-right" : "animate-swipe-left");
                const queryParams = new URLSearchParams(window.location.search);
                const fromPage = queryParams.get("from");
                const params = fromPage ? `?from=${encodeURIComponent(fromPage)}` : "";

                setTimeout(() => {
                    setDonor(null);
                    history.push(`/donor/${profileId}${params}`);
                    profileElement.classList.remove("animate-swipe-right", "animate-swipe-left");
                }, 500);
            }
        }
    };

    return (
        <>
            {isFirstProfile ? (
                <div className="prev-profile">
                    <img src={emptyPrevProfile} alt="No previous profile" className="empty-oval" />
                </div>
            ) : (
                <div className="prev-profile" onClick={() => navigateToProfile("prev")}>
                    <img src={prevProfileOval} alt="" className="oval" />
                    <img src={prevProfileArrow} alt="prev profile arrow" className="arrow" />
                </div>
            )}

            {isLastProfile ? (
                <div className="next-profile">
                    <img src={emptyNextProfile} alt="No next profile" className="empty-oval" />
                </div>
            ) : (
                <div className="next-profile" onClick={() => navigateToProfile("next")}>
                    <img src={nextProfileOval} alt="" className="oval" />
                    <img src={nextProfileArrow} alt="next profile  arrow" className="arrow" />
                </div>
            )}
        </>
    );
}

export default ProfilesNavigationButtons;
