import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import AppService from "@service/AppService/AppService";
import { AuthContext } from "../../AuthContext";
import SurveyQuestionsStore from "../../SurveyQuestionsStore";

function PrivateRoute({ children, ...rest }) {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (authContext.user) {
            AppService.accountType = authContext.user?.role.toLowerCase();
            AppService.isAppMuted = authContext.user?.muted;
        }
    }, [authContext.user]);

    if (authContext.user?.role?.toLowerCase() === "admin") {
        const previousLocation = localStorage.getItem("previousLocation");
        console.log(previousLocation);
        if (previousLocation) {
            return <Redirect to={previousLocation} push={true} />;
        } else {
            return <Redirect to={"/donors"} push={true} />;
        }
    } else {
        return (
            <SurveyQuestionsStore>
                <Route
                    {...rest}
                    render={({ location }) =>
                        authContext.getToken() || authContext.user ? (
                            children
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }}
                            />
                        )
                    }
                />
            </SurveyQuestionsStore>
        );
    }
}

export default PrivateRoute;
