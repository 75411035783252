import React, { useContext, useEffect, useState } from "react";
import "./AdminSurveyMain.scss";
import AdminSummaryEditor from "./AdminSummaryEditor/AdminSummaryEditor";
import QuestionElements from "../../../components/questionsList/QuestionElements/QuestionElements";
import { SurveyQuestionsContext } from "../../../SurveyQuestionsStore";
import classNames from "classnames";
import { finishSurveyByAdmin } from "../../../requests/AdminSurveyAPIRequests/AdminSurveyAPIRequests";
import AppService from "../../../service/AppService/AppService";
import { showErrorNotification, showSuccessNotification } from "../../../helper/Notification/NotificationHelper";
import { DynamicQuestionService } from "../../../service/DynamicQuestionService/DynamicQuestionService";
import AdminLogs from "./AdminLogs/AdminLogs";

function AdminSurveyMain({ basicInfo, setBasicInfo, selectedTopic, onSaveAnswer }) {
    const questionContext = useContext(SurveyQuestionsContext);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [_, setUpdate] = useState(true);

    useEffect(() => {
        if (selectedTopic?.questions) {
            hideQuestionInsideDynamicGroup();
            setUpdate(!_);
        }
    }, [AppService.hiddenQuestions, selectedTopic?.questions, questionContext.dynamicQuestions]);

    const hideQuestionInsideDynamicGroup = () => {
        selectedTopic.questions.forEach((question) => {
            if (question.type === "DYNAMIC_GROUP") {
                DynamicQuestionService.isOneInstanceHasAnswers(question, questionContext, selectedTopic.id);
            }
        });
    };
    const onSaveChanges = async () => {
        setIsSubmitted(true);
        try {
            await finishSurveyByAdmin(AppService.edId, AppService.surveyId);
            showSuccessNotification(`Survey was successfully updated`);
        } catch (e) {
            if (e?.response?.data) {
                showErrorNotification("Failed to save", e.response.data.message);
            }
        }
        setTimeout(() => setIsSubmitted(false), 2000);
    };

    const renderTabs = () => {
        switch (selectedTopic?.id) {
            case "summary":
                return (
                    <div className="admin-main">
                        <div className="admin-survey-main">
                            <AdminSummaryEditor basicInfo={basicInfo} setBasicInfo={setBasicInfo} />
                        </div>
                    </div>
                );
            case "changelog":
                return (
                    <div className="admin-main">
                        <div className="admin-survey-main">
                            <AdminLogs />
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="admin-survey-main">
                        <div className="survey-questions">
                            <div className={`survey-questions-container survey-questions-container-egg_donor`}>
                                <QuestionElements
                                    questions={selectedTopic ? selectedTopic.questions : []}
                                    selectedTopic={selectedTopic}
                                    onAnswer={onSaveAnswer}
                                    updateProgress={() => {}}
                                    isDynamicGroup={false}
                                    dynamicQuestion={null}
                                    instanceId={null}
                                />
                            </div>
                        </div>
                        <div className="sticky-save-button-container">
                            <button type="submit" className={classNames("save-changes", { shake: !isSubmitted })} onClick={onSaveChanges}>
                                <svg
                                    className={`checkmark ${isSubmitted ? "show" : ""}`}
                                    width="19"
                                    height="14"
                                    viewBox="0 0 19 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.4999 11.1996L2.2999 6.99961L0.899902 8.39961L6.4999 13.9996L18.4999 1.99961L17.0999 0.599609L6.4999 11.1996Z"
                                        fill="#816F4B"
                                    />
                                </svg>
                                <span className="button-text">{isSubmitted ? "Validated" : "Validate DQ"}</span>
                            </button>
                        </div>
                    </div>
                );
        }
    };
    return renderTabs();
}

export default AdminSurveyMain;
