import React from "react";
import "./RegistrationFormTitleComponent.scss";
import i18n from "i18next";
class RegistrationFormTitleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isDropdownOpened: false };
        this.showHideDropDown = this.showHideDropDown.bind(this);
    }

    showHideDropDown() {
        this.setState((state) => ({
            isDropdownOpened: !state.isDropdownOpened
        }));
        if (!this.state.isDropdownOpened) {
            this.props.titleDropDownClosed();
        }
    }

    selectDescription() {
        switch (this.props.page) {
            case "surrogate":
                return (
                    <div className="description-wrapper description">
                        <p className="first-paragraph">{i18n.t("surrogate_registration_paragraph_1")}</p>
                        <p className="second-paragraph">
                            <label>{i18n.t("surrogate_registration_paragraph_2_header")}</label>
                            <a
                                href="https://www.conceiveabilities.com/surrogates/become-a-surrogate-mother/"
                                className="colors-text-surrogate"
                            >
                                {" "}
                                {i18n.t("surrogate_registration_paragraph_2_link")}{" "}
                            </a>
                            <label>{i18n.t("surrogate_registration_link_paragraph_footer")} </label>
                        </p>
                    </div>
                );
            case "parent":
                return (
                    <p className="description">
                        &quot;ConceiveAbilities was the only part of our 6 year ordeal of infertility that was virtually stressless. The
                        professionalism was refreshing.&quot; - K &amp; B, Intended Parents
                    </p>
                );
            case "egg_donor":
                return <p className="description">Create your profile and take the first step toward giving an incredible gift.</p>;
        }
        return null;
    }

    render() {
        const title = this.props.page === "surrogate" ? i18n.t("surrogate_registration_title") : this.props.title;
        return (
            <div>
                <h1 className="title">{title}</h1>
                {this.selectDescription()}
            </div>
        );
    }
}

export default RegistrationFormTitleComponent;
