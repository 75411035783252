import React from "react";
import "./ReservedBanner.scss";
import { useHistory } from "react-router-dom";
function ReservedBanner() {
    const history = useHistory();
    return (
        <div className="reserved-banner">
            <div className="title">Congratulations!</div>
            <div className="text">You have reserved the egg donor. We will contact to confirm your reservation soon. </div>
            <div className="back-home" onClick={() => history.push("/donors")}>
                Back home
            </div>
        </div>
    );
}

export default ReservedBanner;
