import { useHistory } from "react-router-dom";
import "./EmptyListComponent.scss";

function EmptyListComponent({ destination, filtersSelected }) {
    const history = useHistory();
    const getEmptyListContent = () => {
        switch (destination) {
            case "ALL":
                return (
                    <div className="empty-list-field">
                        <h2>{getMessageForAll()}</h2>
                    </div>
                );
            case "FAVORITE":
                return (
                    <div className="empty-list-field">
                        <h2>{getMessageForFavorite()}</h2>
                        <button onClick={() => history.push("/donors")}>Reserve Donor</button>
                    </div>
                );
            case "RESERVED":
                return (
                    <div className="empty-list-field">
                        <h2>{getMessageForReserved()}</h2>
                        <button onClick={() => history.push("/donors")}>Reserve Donor</button>
                    </div>
                );
            default:
                return <div>No Data</div>;
        }
    };

    const getMessageForAll = () => {
        return isFiltersActive()
            ? "There are no Egg Donors that match the selected filters"
            : "We don't have active Egg Donors right now. Please check later!";
    };

    const getMessageForFavorite = () => {
        return isFiltersActive()
            ? "You don’t have favorite Egg Donors matching the selected filters"
            : "Looks like you don't have any favorite Egg Donor right now!";
    };

    const getMessageForReserved = () => {
        return isFiltersActive() ? "You don’t have reserved Egg Donors matching the selected filters" : "No Egg Donors are reserved";
    };

    const isFiltersActive = () => {
        return Object.values(filtersSelected).some((value) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            if (typeof value === "string") {
                return value.trim().length > 0;
            }
            return false;
        });
    };

    return getEmptyListContent();
}

export default EmptyListComponent;
