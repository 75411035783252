import React, { useEffect, useState } from "react";

const SecureImageComponent = ({ className, name, alt, imageLoaded, imageIndex, setLoadingImage }) => {
    const imageClassName = className ? className : "";
    const [image, setImage] = useState("");

    useEffect(() => {
        setImage(name);
    }, [name]);

    const onLoadPhotoList = imageLoaded ? () => imageLoaded(imageIndex) : null;
    const onErrorLoad = setLoadingImage ? () => setLoadingImage(false) : null;
    return (
        <>
            <img className={imageClassName} src={image} alt={alt} onLoad={onLoadPhotoList} onError={onErrorLoad} />
        </>
    );
};
export default SecureImageComponent;
