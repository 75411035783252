import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getDonorIdByIntegrationId, getPdfForParent } from "../../requests/AdminAPIRequests/adminAPIRequests";
import { showErrorNotification } from "../../helper/Notification/NotificationHelper";
import logo from "@resources/images/conceiveAbilities-logo.svg";
import "./AdminPrintPdfRedirectComponent.scss";
import AppService from "../../service/AppService/AppService";

function AdminPrintPdfRedirectComponent() {
    const { integrationId } = useParams();
    const history = useHistory();
    const [isPdfLoading, setIsPdfLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        (async () => openPdfPage())();
    }, []);

    const openPdfPage = async () => {
        try {
            setIsPdfLoading(true);
            const edIdResp = await getDonorIdByIntegrationId(integrationId);
            const edId = edIdResp.data;
            const pdfLink = await getPdfForParent(edId);
            if (pdfLink?.data) {
                openUrl(pdfLink.data);
                setPdfUrl(pdfLink.data);
            }
        } catch (error) {
            setIsPdfLoading(false);
            showErrorNotification("PDF build failed", `${error?.response?.data?.message}`);
        } finally {
            setIsPdfLoading(false);
        }
    };

    const openUrl = (url) => {
        const isSafari = AppService.isSafari();
        const target = isSafari ? "_self" : "_blank";
        const windowReference = window.open(url, target);

        if (isSafari && windowReference) {
            windowReference.location = url;
        }
    };

    return (
        <div className="admin-pdf-redirect">
            <header className="left-column-header">
                <img src={logo} className="app-logo" alt="ConceiveAbilities" onClick={() => history.push("/donors")} />
            </header>
            {isPdfLoading && !pdfUrl ? (
                <div className="loading-pdf-message">
                    <h2>Loading PDF...</h2>
                    <p>Please wait, we are preparing your document. You will be redirected to the PDF viewer shortly.</p>
                </div>
            ) : (
                <div className="pdf-ready-message">
                    <h2>PDF Ready</h2>
                    <p>Your document is now ready to view.</p>
                    <button onClick={() => openUrl(pdfUrl)}>Download PDF</button>
                </div>
            )}
        </div>
    );
}

export default AdminPrintPdfRedirectComponent;
