import React, { useEffect, useState } from "react";
import "./SalesforceFieldsList.scss";
import QuestionToolTip from "../../../components/admin/questionToolTip/QuestionToolTip";
import { saveSalesforceField as saveSalesForceFieldAPI } from "../../../requests/AdminAPIRequests/adminAPIRequests";
function SalesforceFieldsList({ sectionName, fields }) {
    const [salesforceFieldsData, setSalesforceFieldsData] = useState([]);
    const [showToolTip, setShowToolTip] = useState(false);
    const [toolTipPosition, setToolTipPosition] = useState(null);
    const [toolTipTarget, setToolTipTarget] = useState(null);

    useEffect(() => {
        setSalesforceFieldsData(fields);
    }, []);

    const saveSalesforceField = (id, event) => {
        const newContent = event.target.value !== "" ? event.target.value : null;
        if (checkIfContentIsOther(id, newContent)) {
            saveSalesForceFieldAPI({ salesForceFieldId: id, content: newContent }).then((resp) => {
                let updatedField = salesforceFieldsData.find((sff) => sff.id === id);
                updatedField.questionDisplayContent = resp.data.questionDisplayContent;
            });
        }
    };

    const checkIfContentIsOther = (id, newContent) => {
        const currentField = salesforceFieldsData.find((sff) => sff.id === id);
        return currentField?.questionDisplayContent !== newContent;
    };

    const onQuestionHover = (id, event) => {
        setShowToolTip(true);
        const position = {
            top: event.pageY + 15,
            left: event.pageX + 5
        };
        const tooltipWidth = 375;

        // Calculate the size of the viewport
        const viewportWidth = window.innerWidth;

        // Check if the tooltip will go out of the viewport
        if (position.left + tooltipWidth > viewportWidth) {
            position.left = viewportWidth - tooltipWidth;
        }
        setToolTipPosition(position);
        setToolTipTarget(id);
    };

    const handleMouseLeave = () => {
        // Clear the tooltip content and position
        setShowToolTip(false);
        setToolTipPosition({ top: 0, left: 0 });
        setToolTipTarget(null);
    };

    return (
        <>
            <div className="salesforce-field-row">
                <h2 data-testid="section-title" className="salesforce-title">
                    List of fields for section: <span>{sectionName}</span>
                </h2>
            </div>
            <div className="salesforce-fields">
                {fields.map((field) => (
                    <div className="salesforce-field-row">
                        <div className="salesforce-field-body">
                            <div className="salesforce-field-info">
                                <div className="salesforce-info-value">
                                    <label htmlFor="sfdcKey">Salesforce Key</label>
                                    <input id="sfdcKey" value={field.fieldName} disabled />
                                </div>
                                <div className="salesforce-info-value">
                                    <label htmlFor="sfdcLabel">Salesforce Label</label>
                                    <input id="sfdcLabel" value={field.fieldLabel} disabled />
                                </div>
                                <div className="salesforce-info-value">
                                    <label htmlFor="sfdcQuestions">Related Questions</label>
                                    <div id="sfdcQuestions" className="salesforce-related-questions">
                                        {field.relatedQuestionsIds.length > 0
                                            ? field.relatedQuestionsIds.map((qId) => (
                                                  <>
                                                      <span onMouseEnter={(e) => onQuestionHover(qId, e)} onMouseLeave={handleMouseLeave}>
                                                          ID: {qId}
                                                      </span>
                                                  </>
                                              ))
                                            : "N/A"}
                                    </div>
                                </div>
                            </div>
                            <div className="salesforce-field-content">
                                <label htmlFor="questionDisplayContent">Question Display Content</label>
                                <input
                                    id="questionDisplayContent"
                                    defaultValue={field.questionDisplayContent}
                                    onBlur={(e) => saveSalesforceField(field.id, e)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {showToolTip && <QuestionToolTip id={toolTipTarget} position={toolTipPosition} />}
        </>
    );
}

export default SalesforceFieldsList;
