import React from "react";
import "./AnswerYesNoComponent.scss";
import Hint from "@components/hint/HintComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedbackWithQuestion } from "@shared/Feedback";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";

class AnswerYesNoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: null
        };
        this.feedbackItems = [];
    }

    render() {
        const { selectedTopic, question, onAnswer, showOverlay, readonly, isDynamicGroup } = this.props;
        const answersField = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "lastAnswersSelected" : "lastAnswerIds";
        const answerIdOrText = isDynamicGroup || surveyQuestionMethods.isEdOrIpAccountType() ? "text" : "id";
        this.feedbackItems = [];
        const answers = question.answers.map((a, i) => {
            this.feedbackItems = getFeedbackWithQuestion(question, a, i, this.feedbackItems);
            const className = question[answersField]?.includes(a[answerIdOrText]) ? "selected" : "";

            return (
                <button
                    key={`${i}_button_yes_no`}
                    className={className}
                    onClick={() => {
                        getFeedbackWithQuestion(question, a, i);
                        onAnswer(selectedTopic, question, a);
                    }}
                >
                    {getTranslatedTextIfNeeded(a.text)}
                </button>
            );
        });

        return (
            <div>
                <div className={`survey-question ${this.props.className}`}>
                    {readonly ? <div className="answer-overlay" /> : null}
                    {showOverlay ? <div className="survey-overlay" /> : null}
                    <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                    <div className="question-answers">{answers}</div>
                    <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
                </div>
                {this.feedbackItems}
            </div>
        );
    }
}

export default AnswerYesNoComponent;
