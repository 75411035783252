import AppService from "../../service/AppService/AppService";

export async function getFavoriteDonors(number, userId, count, body) {
    return await AppService.getAxios().post(`/api/user/favorite/my?page=${number}&userId=${userId}&count=${count}`, body);
}

export async function getReservedDonors(number, userId, count, body) {
    return await AppService.getAxios().post(`/api/donor/reserve/my-reserved-donors?page=${number}&userId=${userId}&count=${count}`, body);
}

export async function getDonorsList(number, userId, count, body) {
    return await AppService.getAxios().post(`/api/user-with-answers/search?page=${number}&userId=${userId}&count=${count}`, body);
}
