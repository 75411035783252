import React, { memo } from "react";
import AppService from "@service/AppService/AppService";
import Hint from "@components/hint/HintComponent";
import CalendarComponent from "@components/calendar/CalendarComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedback } from "@shared/Feedback";

class DatePickerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.setSelectedValue = this.setSelectedValue.bind(this);
        this.buildAnswerInput = this.buildAnswerInput.bind(this);
    }

    setSelectedValue(value, answerId) {
        const { question, isDynamicGroup } = this.props;
        const answerIndex = question.answers.findIndex((a) => a.id === answerId);
        const userAnswer = isDynamicGroup ? "userAnswer" : "value";
        question.answers[answerIndex][userAnswer] = value;
        AppService.updateProgress = true;
        this.setState({ render: true });
    }

    buildAnswerInput(answer, index) {
        const { selectedTopic, question, onAnswer } = this.props;
        return (
            <div className="date-picker-container" key={index}>
                <CalendarComponent
                    selectedRange={null}
                    answer={answer}
                    readOnly={false}
                    onChange={(value) => {
                        getFeedback(answer);
                        this.setSelectedValue(value, answer.id);
                        onAnswer(selectedTopic, question, answer);
                    }}
                />
            </div>
        );
    }

    render() {
        const { question, showOverlay } = this.props;
        const feedbackItems = [];
        const answers = question.answers.map((a, i) => {
            const feedback = getFeedback(a);
            if (feedback) {
                feedback.forEach((f) => feedbackItems.push(f));
            }
            return this.buildAnswerInput(a, i);
        });

        return (
            <div>
                <div className={`survey-question ${this.props.className}`}>
                    {this.props.readonly ? <div className="answer-overlay"></div> : null}
                    {showOverlay ? <div className="survey-overlay"></div> : null}
                    <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                    <div className="question-answers">
                        <div className="content">{answers}</div>
                    </div>
                    <Hint hint={getTranslatedTextIfNeeded(question.hint)}></Hint>
                </div>
                {feedbackItems}
            </div>
        );
    }
}

export default memo(DatePickerComponent);
