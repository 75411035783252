import React, { useEffect, useState } from "react";
import "./AdminSurveyEditor.scss";
import AppLayoutComponent from "../../components/appLayout/AppLayoutComponent";
import AdminSurveyAside from "./AdminSurveyAside/AdminSurveyAside";
import AdminSurveyMain from "./AdminSurveyMain/AdminSurveyMain";
import { getBasicDonorInfo, getDonorIdByIntegrationId } from "../../requests/AdminAPIRequests/adminAPIRequests";
import { useHistory, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import AppService from "../../service/AppService/AppService";
import { getAdminHiddenQuestions, getSectionQuestion, getSurveyData } from "../../requests/AdminSurveyAPIRequests/AdminSurveyAPIRequests";
import CareTeamComponent from "../../components/careTeam/CareTeamComponent";
import SurveyQuestionsStore from "../../SurveyQuestionsStore";

function AdminSurveyEditor() {
    const { integrationId, tab } = useParams();
    const history = useHistory();
    const isAdmin = AppService.accountType === "admin";

    const defaultSelectedTopic = tab ? (tab !== "editor" ? { id: tab } : null) : "summary";

    const [basicInfo, setBasicInfo] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(defaultSelectedTopic);
    const [surveyData, setSurveyData] = useState({});
    const [surveyLocation, setSurveyLocation] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        !isAdmin && history.push("/donors");
    }, [AppService.accountType]);

    useEffect(() => {
        (async () => await getDonorId())();
    }, []);

    useEffect(() => {
        id && (async () => await getBasicInfo())();
        id && (async () => await getSurveyNavigation())();
    }, [id]);

    useEffect(() => {
        if (tab === "editor" && surveyData?.subSurveys) {
            changeSurveyAndSection(null, 0, 0);
        }
    }, [surveyData]);

    useEffect(() => {
        if (surveyData?.surveyId) {
            AppService.surveyId = surveyData.surveyId;
        }
    }, [surveyData]);

    useEffect(() => {
        AppService.parsedQuestions[0] = selectedTopic;
        AppService.allQuestions = [selectedTopic];
    }, [selectedTopic]);

    const getDonorId = async () => {
        try {
            const resp = await getDonorIdByIntegrationId(integrationId);
            if (resp.data) {
                AppService.edId = resp.data;
                setId(resp.data);
            } else {
                history.push(`/donor/${integrationId}`);
            }
        } catch (e) {
            history.push(`/donor/${integrationId}`);
        }
    };

    const getBasicInfo = async () => {
        const response = await getBasicDonorInfo(id);
        if (response.data) {
            setBasicInfo(response.data);
        }
    };

    const getSurveyNavigation = async () => {
        const resp = await getSurveyData(id);
        setSurveyData(resp.data);
    };

    const changeSurveyAndSection = async (_, index, surveyIndex) => {
        setSelectedTopic(null);
        const subSequence = surveyData.subSurveys.find((sub) => sub.index === surveyIndex).id;
        const sectionSequence = surveyData.subSurveys[surveyIndex].sections.find((section) => section.index === index).id;
        const response = await getSectionQuestion(id, surveyData.surveyId, subSequence, sectionSequence);

        setSelectedTopic(response.data);
        setSurveyLocation({ sectionIndex: index, subSurveyIndex: surveyIndex });
        history.push(`/donor-survey/${integrationId}/editor`);
    };

    const onSaveAnswer = async (topic, question, answer) => {
        const originalSelectedTopic = cloneDeep(selectedTopic);

        try {
            surveyQuestionMethods.setLastAnswerId(question, answer);
            if (question.type === "DYNAMIC_GROUP" || question.type === "DATE") {
                await surveyQuestionMethods.setAnswer(topic, question, answer);
                return;
            }
            let updatedSelectedTopic = { ...selectedTopic };
            const questionIndex = updatedSelectedTopic.questions.findIndex((q) => q.id === question.id);

            if (questionIndex !== -1) {
                updatedSelectedTopic.questions[questionIndex] = { ...question };
            }

            setSelectedTopic(updatedSelectedTopic);
            if (surveyQuestionMethods.shouldSkipAddressQuestion(question, answer)) {
                return;
            }
            if (answer.hasVisibility && surveyQuestionMethods.isQuestionAnswered(question)) {
                const subSurvey = surveyData.subSurveys.find((sub) => sub.index === surveyLocation.subSurveyIndex);
                const body = {
                    answerSequence: answer.sequenceId,
                    groupSequence: question?.groupId,
                    sectionSequence: selectedTopic.id,
                    subSequence: subSurvey?.id,
                    surveyId: AppService.surveyId
                };

                const response = await getAdminHiddenQuestions(AppService.edId, body);
                surveyQuestionMethods.addHiddenQuestions(response.data, updatedSelectedTopic);
            }
            surveyQuestionMethods.filterQuestionsIfNeeded(question, updatedSelectedTopic);

            setSelectedTopic(cloneDeep(updatedSelectedTopic));

            if (questionIndex !== -1) {
                const updatedQuestion = await surveyQuestionMethods.setAnswer(topic, question, answer);
                updatedSelectedTopic.questions[questionIndex] = { ...updatedQuestion };
                setSelectedTopic(cloneDeep(updatedSelectedTopic));
            } else if (question.groupId) {
                await surveyQuestionMethods.setAnswer(topic, question, answer);
            } else {
                console.error("Question not found");
            }
        } catch (e) {
            console.log(e);
            surveyQuestionMethods.showNotificationWithError(e);
            setSelectedTopic(originalSelectedTopic);
        }
    };

    if (!surveyData || !id) return null;

    return (
        <AppLayoutComponent
            left={
                <AdminSurveyAside
                    changeSurveyAndSection={changeSurveyAndSection}
                    surveyData={surveyData}
                    basicInfo={basicInfo}
                    selectedTopic={selectedTopic}
                    setSelectedTopic={setSelectedTopic}
                />
            }
            center={
                <SurveyQuestionsStore>
                    <AdminSurveyMain
                        basicInfo={basicInfo}
                        onSaveAnswer={onSaveAnswer}
                        setBasicInfo={setBasicInfo}
                        selectedTopic={selectedTopic}
                    />
                </SurveyQuestionsStore>
            }
            right={<CareTeamComponent />}
            showRightColumn={false}
        />
    );
}

export default AdminSurveyEditor;
