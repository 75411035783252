import React from "react";

import Layout from "@components/appLayout/AppLayoutComponent";
import BackButton from "@components/backButton/BackButtonComponent";

import "./ProfileComponent.scss";
import ProfileForm from "./form/ProfileFormComponent";
import CareTeamComponent from "../../components/careTeam/CareTeamComponent";

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {}
        };
    }

    render() {
        return (
            <Layout
                className="profile-page"
                left={<BackButton />}
                center={<ProfileForm />}
                showRightColumn={true}
                right={<CareTeamComponent />}
            />
        );
    }
}

export default ProfileComponent;
