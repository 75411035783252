import React from "react";
import DropDownMultiComponent from "@components/dropDowns/MultiSelectComponent/MultiSelectComponent";
import Hint from "@components/hint/HintComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedbackWithQuestion } from "@shared/Feedback";

function MultiSelect({ selectedTopic, question, onAnswer, showOverlay, className, readonly, isDynamicGroup }) {
    const onChange = (answer) => {
        onAnswer(selectedTopic, question, answer);
    };

    let feedbackItems = [];

    if (question.answers) {
        question.answers.forEach((a, i) => {
            feedbackItems = getFeedbackWithQuestion(question, a, i, feedbackItems);
        });
    }

    return (
        <div>
            <div className={`survey-question ${className}`}>
                {readonly ? <div className="answer-overlay" /> : null}
                {showOverlay ? <div className="survey-overlay" /> : null}
                <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>
                <div className="question-answers">
                    <DropDownMultiComponent
                        answers={question.answers}
                        question={question}
                        isDynamicGroup={isDynamicGroup}
                        onChange={(answer, i) => {
                            getFeedbackWithQuestion(question, answer, i, feedbackItems);
                            onChange(answer);
                        }}
                    />
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
}

export default MultiSelect;
