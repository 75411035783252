import React from "react";
import classNames from "classnames";

function ProfileBannerComponent({ title, textContent, surveyType, navigateToSurvey }) {
    const infoBannerStyles = {
        egg_donor: "start-banner-green",
        surrogate: "start-banner-blue",
        parent: "start-banner-grey"
    };

    const onBannerClick = (event) => {
        const regex = new RegExp("[#]", "g");
        if (
            event.target.id !== "calendly-link" &&
            event.target.id !== "coordinator-link" &&
            !regex.test(event.target.href) &&
            event.target.className !== "embed-popup-button"
        ) {
            navigateToSurvey && navigateToSurvey();
        }
    };

    return (
        <div
            className={classNames("start-banner", {
                [infoBannerStyles[surveyType]]: surveyType
            })}
            onClick={onBannerClick}
        >
            <div className="bg" />
            <h1>{title}</h1>
            {textContent}
        </div>
    );
}

export default ProfileBannerComponent;
