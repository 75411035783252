import React from "react";
import "./ProgressBar.scss";

function ProgressBar({ width, progress }) {
    return (
        <div className="progress-container" style={{ width: `${width}px` }}>
            <div style={{ width: `${progress}px` }} className="progress" />
        </div>
    );
}

export default ProgressBar;
