import React, { useContext, useEffect, useState } from "react";
import AppProgress from "@components/applicationProgress/ApplicationProgressComponent";
import AppService from "@service/AppService/AppService";
import Layout from "@components/appLayout/AppLayoutComponent";
import Calendly from "@components/calendly/CalendlyComponent";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import "./SurveyFinishedPageComponent.scss";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../AuthContext";
import ProfileBannerComponent from "../../components/ProfileBannerComponent/ProfileBannerComponent";
import { UserSurveyStatus } from "../../helper/Constants";

function SurveyFinishedPageComponent({ status }) {
    const authContext = useContext(AuthContext);
    const [showCalendlyPopUp, setShowCalendlyPopUp] = useState(false);
    const [profileType, setProfileType] = useState("");
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        authContext.user && initData();
    }, [authContext.user?.portalStatus, authContext.user?.role]);
    useEffect(() => {
        if (AppService.isAppMuted === null) return;
        if (!authContext.isSurveyFinished()) {
            window.location.pathname = "/app/start";
            return;
        }
        const currentPath = window.location.pathname;

        const homePath = "/app/home";
        const acceptedPath = "/app/accepted";

        if (AppService.isAppMuted && currentPath !== homePath) {
            window.location.pathname = homePath;
            return;
        }
        if (authContext.userSurveyStatus && authContext.userSurveyStatus !== status && !AppService.isAppMuted) {
            if (
                currentPath === acceptedPath &&
                [UserSurveyStatus.INITIAL_REJECTED, UserSurveyStatus.REJECTED].includes(authContext.userSurveyStatus)
            ) {
                window.location.pathname = homePath;
            } else if (
                currentPath === homePath &&
                ![UserSurveyStatus.INITIAL_REJECTED, UserSurveyStatus.REJECTED].includes(authContext.userSurveyStatus)
            ) {
                window.location.pathname = acceptedPath;
            }
        }
    }, [authContext.userSurveyStatus, AppService.isAppMuted, status]);

    const initData = () => {
        setProfileType(authContext.user?.role.toLowerCase());
        if (authContext.user?.role === "SURROGATE") {
            AppService.changeLanguageHandler(AppService.cookies.get("i18next"), history);
        }
    };

    const navigateToSurvey = () => {
        if (AppService.isAppStarted || AppService.userSurveyStatus === UserSurveyStatus.COMPLETED) {
            history.push("/survey");
        }
    };

    const buildSurveyCompletedText = (surveyType) => {
        switch (surveyType) {
            case "surrogate":
                return buildSurveyCompletedTextForSurrogate();
            case "parent":
                return buildSurveyCompletedTextForParent();
            case "egg_donor":
                return buildSurveyCompletedTextForEggDonor();
        }
        return null;
    };

    const buildSurveyRejectedText = (surveyType) => {
        switch (surveyType) {
            case "surrogate":
                return buildSurveyRejectedTextForSurrogate();
            case "parent":
                return buildSurveyRejectedTextForParent();
            case "egg_donor":
                return buildSurveyRejectedTextForEggDonor();
        }
        return null;
    };

    const buildSurveyCompletedTextForSurrogate = () => {
        return (
            <p>
                {t("surrogate_banner_thanks")}
                <br />
                <br />
                {t("surrogate_banner_time_part1")}{" "}
                {AppService.isMobile() ? (
                    <a id="calendly-link" target="_blank" href="https://calendly.com/be-a-surrogate/lets-chat">
                        {t("time")}
                    </a>
                ) : (
                    <a id="calendly-link" href="#" onClick={() => showHideCalendlyPopUp(true)}>
                        {t("time")}
                    </a>
                )}{" "}
                {t("surrogate_banner_time_part2")}
                <br />
                <br />
                {AppService.isMobile() ? (
                    <a
                        id="calendly-link"
                        target="_blank"
                        className="calendly-link-button surrogate"
                        href="https://calendly.com/be-a-surrogate/lets-chat"
                    >
                        {t("Schedule")}
                    </a>
                ) : (
                    <button
                        id="calendly-link"
                        className="schedule-button surrogate bg-surrogate"
                        onClick={() => showHideCalendlyPopUp(true)}
                    >
                        {t("Schedule")}
                    </button>
                )}
                <br />
                <br />
                {t("surrogate_banner_footer")}
                <br />
                {/*<PopupButton id="ZqG2G7db" className="embed-popup-button" size={70} medium="snippet">*/}
                {/*    {t("Learn More")}*/}
                {/*</PopupButton>*/}
                <p className="embed-popup-button" onClick={openSurrogateYoutube}>
                    {t("Learn More")}
                </p>
            </p>
        );
    };

    const buildSurveyCompletedTextForParent = () => {
        if (authContext.user?.portalStatus) {
            return <p>Next step is reserving an egg donor.</p>;
        }
        return (
            <p>
                A team member will be in touch with you shortly. We look forward to speaking with you soon.
                <br />
                <br />
                <a
                    id="calendly-link"
                    className="schedule-button parent bg-parent"
                    target="_blank"
                    href="https://www.conceiveabilities.com/parents/"
                >
                    Learn About Our Programs
                </a>
            </p>
        );
    };

    const buildSurveyCompletedTextForEggDonor = () => {
        return <p>A team member will be in touch with you shortly. We look forward to speaking with you soon.</p>;
    };

    const buildSurveyRejectedTextForSurrogate = () => {
        return (
            <p>
                {t("surrogate_rejected_banner_part1")}
                <br />
                <br />
                {t("surrogate_rejected_banner_part2")}
                <br />
                {/*<PopupButton id="ZqG2G7db" className="embed-popup-button" size={70} medium="snippet">*/}
                {/*    {t("Learn More")}*/}
                {/*</PopupButton>*/}
                <p className="embed-popup-button" onClick={openSurrogateYoutube}>
                    {t("Learn More")}
                </p>
            </p>
        );
    };

    const buildSurveyRejectedTextForParent = () => {
        return (
            <p>
                Thank you for applying to be a parent, and please know that your information is safe with us. No changes are possible to
                this application, but we will be in touch soon about your qualifications.
            </p>
        );
    };

    const buildSurveyRejectedTextForEggDonor = () => {
        return (
            <p>
                Thank you for applying to be a egg donor, and please know that your information is safe with us. No changes are possible to
                this application, but we will be in touch soon about your qualifications.
            </p>
        );
    };

    const showHideCalendlyPopUp = (isShowHideCalendlyPopUp) => {
        setShowCalendlyPopUp(isShowHideCalendlyPopUp);
    };

    const openSurrogateYoutube = () => {
        window.open("https://www.youtube.com/watch?v=-zQMkXOWKhc&ab_channel=ConceiveAbilities", "_blank");
    };

    const getReserveButton = () => {
        return profileType === "parent" && authContext.user?.portalStatus ? (
            <NavLink className="schedule-button parent reserve bg-parent" to="/donors">
                Reserve donor
            </NavLink>
        ) : null;
    };

    const buildBannerTitle = () => {
        switch (profileType) {
            case "parent":
                return authContext.user.portalStatus ? "Reserve a donor!" : "Thank you for contacting ConceiveAbilities.";
            case "egg_donor":
                return "Thank you for contacting ConceiveAbilities.";
            case "surrogate":
                return t("surrogate_banner_title");
        }
    };

    const buildBannerDescription = () => {
        switch (profileType) {
            case "parent":
                return authContext.user.portalStatus
                    ? "Thank you for your application! Now, as you aware of process, you can continue in your journey."
                    : "";
            default:
                return "";
        }
    };

    const leftColumn = <AppProgress context={authContext} history={history} accountType={AppService.accountType} />;

    const textContent =
        authContext.userSurveyStatus && status === UserSurveyStatus.COMPLETED
            ? buildSurveyCompletedText(profileType)
            : buildSurveyRejectedText(profileType);

    const centerColumn = (
        <div className="start-application">
            <h1 />
            <ProfileBannerComponent
                surveyType={profileType}
                showHideCalendlyPopUp={showHideCalendlyPopUp}
                navigateToSurvey={navigateToSurvey}
                textContent={textContent}
                title={buildBannerTitle()}
            />
            <p className="banner-description">{buildBannerDescription()}</p>
            {getReserveButton()}
            <Calendly
                link="https://calendly.com/be-a-surrogate/lets-chat"
                showCalendly={showCalendlyPopUp}
                hideCalendly={() => showHideCalendlyPopUp(false)}
            />
        </div>
    );

    return (
        <Layout
            left={leftColumn}
            center={authContext.isSurveyFinished() && centerColumn}
            className={`survey-start right-column-${profileType}`}
        />
    );
}

export default SurveyFinishedPageComponent;
