export const ConditionDisqualificationService = {
    isSurveyDisqualifiedByCondition(questions) {
        let isDisqualified;
        questions.forEach((question) => {
            if ((question.type !== "GROUP" || question.type !== "DYNAMIC_GROUP") && question.conditions?.length > 0) {
                question.conditions.forEach((condition) => {
                    if (condition.isDisqualify) {
                        switch (question.type) {
                            case "TEXT":
                            case "NUMBER":
                            case "MULTI_UPLOAD":
                            case "UPLOAD":
                                if (
                                    condition.type === "ANSWER" &&
                                    question.answers.find((answer) => answer.userAnswer === condition.value)
                                ) {
                                    isDisqualified = true;
                                }
                                if (condition.type === "SUM") {
                                    let sum = 0;
                                    question.answers.forEach((answer) => {
                                        sum += parseInt(answer.userAnswer);
                                    });
                                    isDisqualified = Number(condition.value) === sum ? true : isDisqualified;
                                }
                                break;
                        }
                    }
                });
            }
        });
        return isDisqualified;
    }
};
